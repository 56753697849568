/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemFromJSONTyped,
    ClassifiedItemToJSON,
} from './ClassifiedItem';
import {
    SeoBlockType,
    SeoBlockTypeFromJSON,
    SeoBlockTypeFromJSONTyped,
    SeoBlockTypeToJSON,
} from './SeoBlockType';

/**
 * 
 * @export
 * @interface SeoBlock
 */
export interface SeoBlock {
    /**
     * 
     * @type {number}
     * @memberof SeoBlock
     */
    position?: number;
    /**
     * 
     * @type {SeoBlockType}
     * @memberof SeoBlock
     */
    type?: SeoBlockType;
    /**
     * 
     * @type {Array<ClassifiedItem>}
     * @memberof SeoBlock
     */
    items?: Array<ClassifiedItem> | null;
}

export function SeoBlockFromJSON(json: any): SeoBlock {
    return SeoBlockFromJSONTyped(json, false);
}

export function SeoBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': !exists(json, 'position') ? undefined : json['position'],
        'type': !exists(json, 'type') ? undefined : SeoBlockTypeFromJSON(json['type']),
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ClassifiedItemFromJSON)),
    };
}

export function SeoBlockToJSON(value?: SeoBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'type': SeoBlockTypeToJSON(value.type),
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ClassifiedItemToJSON)),
    };
}

