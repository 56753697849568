/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttachmentStatus {
    Processing = 'Processing',
    Active = 'Active',
    Errored = 'Errored',
    Deleted = 'Deleted',
    ColdStorage = 'ColdStorage'
}

export function AttachmentStatusFromJSON(json: any): AttachmentStatus {
    return AttachmentStatusFromJSONTyped(json, false);
}

export function AttachmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentStatus {
    return json as AttachmentStatus;
}

export function AttachmentStatusToJSON(value?: AttachmentStatus | null): any {
    return value as any;
}

