/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageField,
    SearchPageFieldFromJSON,
    SearchPageFieldFromJSONTyped,
    SearchPageFieldToJSON,
} from './SearchPageField';

/**
 * 
 * @export
 * @interface ClassifiedCreationFields
 */
export interface ClassifiedCreationFields {
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    categorySection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    generalInfoSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    ageAndMilageSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    technicalSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    equipmentSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    priceSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof ClassifiedCreationFields
     */
    descriptionSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {SearchPageField}
     * @memberof ClassifiedCreationFields
     */
    sellerInfoField?: SearchPageField;
}

export function ClassifiedCreationFieldsFromJSON(json: any): ClassifiedCreationFields {
    return ClassifiedCreationFieldsFromJSONTyped(json, false);
}

export function ClassifiedCreationFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedCreationFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categorySection': !exists(json, 'categorySection') ? undefined : (json['categorySection'] === null ? null : (json['categorySection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'generalInfoSection': !exists(json, 'generalInfoSection') ? undefined : (json['generalInfoSection'] === null ? null : (json['generalInfoSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'ageAndMilageSection': !exists(json, 'ageAndMilageSection') ? undefined : (json['ageAndMilageSection'] === null ? null : (json['ageAndMilageSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'technicalSection': !exists(json, 'technicalSection') ? undefined : (json['technicalSection'] === null ? null : (json['technicalSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'equipmentSection': !exists(json, 'equipmentSection') ? undefined : (json['equipmentSection'] === null ? null : (json['equipmentSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'priceSection': !exists(json, 'priceSection') ? undefined : (json['priceSection'] === null ? null : (json['priceSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'descriptionSection': !exists(json, 'descriptionSection') ? undefined : (json['descriptionSection'] === null ? null : (json['descriptionSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'sellerInfoField': !exists(json, 'sellerInfoField') ? undefined : SearchPageFieldFromJSON(json['sellerInfoField']),
    };
}

export function ClassifiedCreationFieldsToJSON(value?: ClassifiedCreationFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categorySection': value.categorySection === undefined ? undefined : (value.categorySection === null ? null : (value.categorySection as Array<any>).map(SearchPageFieldToJSON)),
        'generalInfoSection': value.generalInfoSection === undefined ? undefined : (value.generalInfoSection === null ? null : (value.generalInfoSection as Array<any>).map(SearchPageFieldToJSON)),
        'ageAndMilageSection': value.ageAndMilageSection === undefined ? undefined : (value.ageAndMilageSection === null ? null : (value.ageAndMilageSection as Array<any>).map(SearchPageFieldToJSON)),
        'technicalSection': value.technicalSection === undefined ? undefined : (value.technicalSection === null ? null : (value.technicalSection as Array<any>).map(SearchPageFieldToJSON)),
        'equipmentSection': value.equipmentSection === undefined ? undefined : (value.equipmentSection === null ? null : (value.equipmentSection as Array<any>).map(SearchPageFieldToJSON)),
        'priceSection': value.priceSection === undefined ? undefined : (value.priceSection === null ? null : (value.priceSection as Array<any>).map(SearchPageFieldToJSON)),
        'descriptionSection': value.descriptionSection === undefined ? undefined : (value.descriptionSection === null ? null : (value.descriptionSection as Array<any>).map(SearchPageFieldToJSON)),
        'sellerInfoField': SearchPageFieldToJSON(value.sellerInfoField),
    };
}

