/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormControlType {
    Text = 'Text',
    Number = 'Number',
    Checkbox = 'Checkbox',
    DropDown = 'DropDown',
    YearMonthPicker = 'YearMonthPicker',
    LicensePlate = 'LicensePlate',
    HorseData = 'HorseData',
    Group = 'Group',
    Hidden = 'Hidden',
    Autosuggest = 'Autosuggest',
    RadioButton = 'RadioButton',
    ToggleButton = 'ToggleButton',
    TextArea = 'TextArea',
    AutoSuggestToFrom = 'AutoSuggestToFrom'
}

export function FormControlTypeFromJSON(json: any): FormControlType {
    return FormControlTypeFromJSONTyped(json, false);
}

export function FormControlTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormControlType {
    return json as FormControlType;
}

export function FormControlTypeToJSON(value?: FormControlType | null): any {
    return value as any;
}

