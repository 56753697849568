import { UserProfileType } from '@/shared/lib-api';

export const GetMediumMobileImage = (
  folderShard: string,
  id: number,
  profileType: UserProfileType,
) => {
  return BuildImage(
    folderShard,
    id,
    576,
    768,
    profileType === UserProfileType.Private ? 'crop' : 'max',
    'both',
    70,
  );
};

export const GetFullscreenImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 1080, 1440, 'max', 'both', 80);
};

export const GetMediumImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 576, 768, 'crop', 'both', 70);
};

export const GetSmallImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 288, 410, 'crop', 'both', 70);
};

export const GetMediumDesktopImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 526, 768, 'crop', 'both', 70);
};

export const GetTinyImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 204, 272, 'crop', 'both', 60);
};

export const GetCroppedImage = (folderShard: string, id: number) => {
  return BuildImage(folderShard, id, 180, 180, 'crop', 'both', 60);
};

const BuildImage = (
  folderShard: string,
  id: number,
  height?: number,
  width?: number,
  mode?: 'crop' | 'max',
  scale?: 'both',
  quality?: number,
) => {
  return `https://billeder.weblook.dk/photos/attachments/${folderShard}/${id}.jpg?${
    height ? `&height=${height}` : ''
  }${width ? `&width=${width}` : ''}${mode ? `&mode=${mode}` : ''}${
    scale ? `&scale=${scale}` : ''
  }${quality ? `&quality=${quality}` : ''}`;
};
