/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacetType,
    FacetTypeFromJSON,
    FacetTypeFromJSONTyped,
    FacetTypeToJSON,
} from './FacetType';
import {
    FieldUsage,
    FieldUsageFromJSON,
    FieldUsageFromJSONTyped,
    FieldUsageToJSON,
} from './FieldUsage';
import {
    FormControlType,
    FormControlTypeFromJSON,
    FormControlTypeFromJSONTyped,
    FormControlTypeToJSON,
} from './FormControlType';
import {
    SRPFieldAttributeSortOrder,
    SRPFieldAttributeSortOrderFromJSON,
    SRPFieldAttributeSortOrderFromJSONTyped,
    SRPFieldAttributeSortOrderToJSON,
} from './SRPFieldAttributeSortOrder';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';

/**
 * 
 * @export
 * @interface SearchPageField
 */
export interface SearchPageField {
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    formName: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    formNameSecondary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    subHeader: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageField
     */
    disableAutoFilteringValues: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    placeholder: string;
    /**
     * 
     * @type {FacetType}
     * @memberof SearchPageField
     */
    facetType: FacetType;
    /**
     * 
     * @type {FormControlType}
     * @memberof SearchPageField
     */
    formControlType: FormControlType;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageField
     */
    attributes: Array<SearchPageAttribute>;
    /**
     * 
     * @type {SRPFieldAttributeSortOrder}
     * @memberof SearchPageField
     */
    attributeSortOrder: SRPFieldAttributeSortOrder;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageField
     */
    displayHeadline: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageField
     */
    displayHalfWidth: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchPageField
     */
    sortOrder: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    categoryName: string;
    /**
     * 
     * @type {FieldUsage}
     * @memberof SearchPageField
     */
    fieldUsage: FieldUsage;
    /**
     * 
     * @type {string}
     * @memberof SearchPageField
     */
    postfix?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageField
     */
    required?: boolean;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageField
     */
    groupedFields?: Array<SearchPageField> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageField
     */
    autoFillDataFromExtended?: boolean;
}

export function SearchPageFieldFromJSON(json: any): SearchPageField {
    return SearchPageFieldFromJSONTyped(json, false);
}

export function SearchPageFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPageField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formName': json['formName'],
        'formNameSecondary': !exists(json, 'formNameSecondary') ? undefined : json['formNameSecondary'],
        'subHeader': json['subHeader'],
        'disableAutoFilteringValues': json['disableAutoFilteringValues'],
        'placeholder': json['placeholder'],
        'facetType': FacetTypeFromJSON(json['facetType']),
        'formControlType': FormControlTypeFromJSON(json['formControlType']),
        'attributes': ((json['attributes'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'attributeSortOrder': SRPFieldAttributeSortOrderFromJSON(json['attributeSortOrder']),
        'displayHeadline': json['displayHeadline'],
        'displayHalfWidth': json['displayHalfWidth'],
        'sortOrder': json['sortOrder'],
        'categoryName': json['categoryName'],
        'fieldUsage': FieldUsageFromJSON(json['fieldUsage']),
        'postfix': !exists(json, 'postfix') ? undefined : json['postfix'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'groupedFields': !exists(json, 'groupedFields') ? undefined : (json['groupedFields'] === null ? null : (json['groupedFields'] as Array<any>).map(SearchPageFieldFromJSON)),
        'autoFillDataFromExtended': !exists(json, 'autoFillDataFromExtended') ? undefined : json['autoFillDataFromExtended'],
    };
}

export function SearchPageFieldToJSON(value?: SearchPageField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formName': value.formName,
        'formNameSecondary': value.formNameSecondary,
        'subHeader': value.subHeader,
        'disableAutoFilteringValues': value.disableAutoFilteringValues,
        'placeholder': value.placeholder,
        'facetType': FacetTypeToJSON(value.facetType),
        'formControlType': FormControlTypeToJSON(value.formControlType),
        'attributes': ((value.attributes as Array<any>).map(SearchPageAttributeToJSON)),
        'attributeSortOrder': SRPFieldAttributeSortOrderToJSON(value.attributeSortOrder),
        'displayHeadline': value.displayHeadline,
        'displayHalfWidth': value.displayHalfWidth,
        'sortOrder': value.sortOrder,
        'categoryName': value.categoryName,
        'fieldUsage': FieldUsageToJSON(value.fieldUsage),
        'postfix': value.postfix,
        'required': value.required,
        'groupedFields': value.groupedFields === undefined ? undefined : (value.groupedFields === null ? null : (value.groupedFields as Array<any>).map(SearchPageFieldToJSON)),
        'autoFillDataFromExtended': value.autoFillDataFromExtended,
    };
}

