import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ClassifiedCreationAttachment,
  ClassifiedCreationFields,
  ClassifiedCreationOptions,
  SearchPageAttribute,
} from '@/shared/lib-api';
import {
  initialMakeModelVariantOption,
  initialSearchAttribute,
} from './SRPSlice';
import { GetSearchPageField } from '@/shared/util/helpers/general-helper';

export type SYIStep = 'Images' | 'Fields';

export interface SYIState {
  carFields?: ClassifiedCreationFields;
  carOptions?: Partial<ClassifiedCreationOptions>;
  carImages: ClassifiedCreationAttachment[];
  licenseplate: string;
  step: SYIStep;
  extendedAutoFillDataLoading: boolean;
}

export const initialCarOptions: Partial<ClassifiedCreationOptions> = {
  makeModelVariantOptions: [initialMakeModelVariantOption],
  category: initialSearchAttribute,
  displayBud: [],
  priceDoesntIncludeRegTax: [],
  hideAddress: [],
  equipment: [],
  fuelTypes: initialSearchAttribute,
  trailerHitch: initialSearchAttribute,
  regDate: undefined,
  classifiedId: undefined,
};

export const initialState: SYIState = {
  carFields: undefined,
  carOptions: initialCarOptions,
  carImages: [],
  step: 'Images',
  licenseplate: '',
  extendedAutoFillDataLoading: false,
};

export const SYISlice = createSlice({
  name: 'SYISlice',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<SYIStep>) => {
      state.step = action.payload;
    },
    setImages: (
      state,
      action: PayloadAction<ClassifiedCreationAttachment[]>,
    ) => {
      state.carImages = action.payload;
    },
    addImages: (
      state,
      action: PayloadAction<ClassifiedCreationAttachment[]>,
    ) => {
      state.carImages = state.carImages.concat(action.payload);
    },
    removeImage: (
      state,
      action: PayloadAction<ClassifiedCreationAttachment>,
    ) => {
      state.carImages = state.carImages.filter(
        p => p.attachmentId !== action.payload.attachmentId,
      );
      if (state.carImages.length === 0) {
        state.licenseplate = '';
      }
    },
    updateImage: (
      state,
      action: PayloadAction<{
        newAttachment: ClassifiedCreationAttachment | undefined;
        oldAttachmentId: number;
      }>,
    ) => {
      let index = state.carImages.findIndex(
        p => p.attachmentId === action.payload.oldAttachmentId,
      );
      state.carImages.splice(index, 1, action.payload.newAttachment);
    },
    setLicenseplate: (state, action: PayloadAction<string | undefined>) => {
      state.licenseplate = action.payload;
    },
    setFields: (state, action: PayloadAction<ClassifiedCreationFields>) => {
      state.carFields = action.payload;
    },
    setOptions: (state, action: PayloadAction<ClassifiedCreationOptions>) => {
      state.carImages = action.payload.images;
      state.carOptions = action.payload;
    },
    setAutofillData: (
      state,
      action: PayloadAction<ClassifiedCreationOptions>,
    ) => {
      state.carOptions = {
        ...action.payload,
        classifiedId: state.carOptions.classifiedId,
        regDate:
          action.payload.regDate.getFullYear() !== 1
            ? action.payload.regDate
            : state.carOptions.regDate,
        lastInspection:
          action.payload.lastInspection ?? state.carOptions.lastInspection,
        milage:
          action.payload.milage !== 0
            ? action.payload.milage
            : state.carOptions.milage,
        trailerHitch:
          action.payload.trailerHitch ?? state.carOptions.trailerHitch,
        carTypes: action.payload.carTypes ?? state.carOptions.carTypes,
      };
    },
    setExtendedAutofillDataLoading: (state, action: PayloadAction<boolean>) => {
      state.extendedAutoFillDataLoading = action.payload;
    },
    setExtendedAutofillData: (
      state,
      action: PayloadAction<ClassifiedCreationOptions>,
    ) => {
      state.carOptions = {
        ...state.carOptions,
        batteryCapacity: action.payload.batteryCapacity,
        distance: action.payload.distance,
        gearBoxTypes: action.payload.gearBoxTypes,
      };
      state.extendedAutoFillDataLoading = false;
    },
    setOptionsClassifiedId: (state, action: PayloadAction<number>) => {
      state.carOptions = { ...state.carOptions, classifiedId: action.payload };
    },
    setSyiModels: (state, action: PayloadAction<SearchPageAttribute[]>) => {
      let modelField = GetSearchPageField(
        state.carFields?.generalInfoSection,
        'model',
      );

      modelField.attributes = action.payload;
    },
    resetFlow: state => {
      state.carOptions = initialCarOptions;
      state.carImages = [];
      state.step = 'Images';
      state.licenseplate = '';
    },
  },
});

export const {
  setStep,
  addImages,
  removeImage,
  resetFlow,
  setSyiModels,
  setLicenseplate,
  updateImage,
  setImages,
  setFields,
  setOptions,
  setOptionsClassifiedId,
  setAutofillData,
  setExtendedAutofillData,
  setExtendedAutofillDataLoading,
} = SYISlice.actions;

export default SYISlice.reducer;
