/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttachmentDto,
    AttachmentDtoFromJSON,
    AttachmentDtoFromJSONTyped,
    AttachmentDtoToJSON,
} from './AttachmentDto';

/**
 * 
 * @export
 * @interface CmsSrpBottomBlockDto
 */
export interface CmsSrpBottomBlockDto {
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CmsSrpBottomBlockDto
     */
    makeId?: number;
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    headline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    linkText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CmsSrpBottomBlockDto
     */
    linkTo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CmsSrpBottomBlockDto
     */
    logoId?: number | null;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof CmsSrpBottomBlockDto
     */
    logo?: AttachmentDto;
}

export function CmsSrpBottomBlockDtoFromJSON(json: any): CmsSrpBottomBlockDto {
    return CmsSrpBottomBlockDtoFromJSONTyped(json, false);
}

export function CmsSrpBottomBlockDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CmsSrpBottomBlockDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'makeId': !exists(json, 'makeId') ? undefined : json['makeId'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'linkText': !exists(json, 'linkText') ? undefined : json['linkText'],
        'linkTo': !exists(json, 'linkTo') ? undefined : json['linkTo'],
        'logoId': !exists(json, 'logoId') ? undefined : json['logoId'],
        'logo': !exists(json, 'logo') ? undefined : AttachmentDtoFromJSON(json['logo']),
    };
}

export function CmsSrpBottomBlockDtoToJSON(value?: CmsSrpBottomBlockDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'makeId': value.makeId,
        'make': value.make,
        'headline': value.headline,
        'text': value.text,
        'linkText': value.linkText,
        'linkTo': value.linkTo,
        'logoId': value.logoId,
        'logo': AttachmentDtoToJSON(value.logo),
    };
}

