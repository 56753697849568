/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageFields,
    SearchPageFieldsFromJSON,
    SearchPageFieldsFromJSONTyped,
    SearchPageFieldsToJSON,
} from './SearchPageFields';
import {
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsFromJSONTyped,
    SearchPageOptionsToJSON,
} from './SearchPageOptions';
import {
    SeoPageOptions,
    SeoPageOptionsFromJSON,
    SeoPageOptionsFromJSONTyped,
    SeoPageOptionsToJSON,
} from './SeoPageOptions';

/**
 * 
 * @export
 * @interface SeoSearchInitResponse
 */
export interface SeoSearchInitResponse {
    /**
     * 
     * @type {SearchPageFields}
     * @memberof SeoSearchInitResponse
     */
    fields?: SearchPageFields;
    /**
     * 
     * @type {SearchPageOptions}
     * @memberof SeoSearchInitResponse
     */
    searchPageOptions?: SearchPageOptions;
    /**
     * 
     * @type {SeoPageOptions}
     * @memberof SeoSearchInitResponse
     */
    searchResult?: SeoPageOptions;
}

export function SeoSearchInitResponseFromJSON(json: any): SeoSearchInitResponse {
    return SeoSearchInitResponseFromJSONTyped(json, false);
}

export function SeoSearchInitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoSearchInitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : SearchPageFieldsFromJSON(json['fields']),
        'searchPageOptions': !exists(json, 'searchPageOptions') ? undefined : SearchPageOptionsFromJSON(json['searchPageOptions']),
        'searchResult': !exists(json, 'searchResult') ? undefined : SeoPageOptionsFromJSON(json['searchResult']),
    };
}

export function SeoSearchInitResponseToJSON(value?: SeoSearchInitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': SearchPageFieldsToJSON(value.fields),
        'searchPageOptions': SearchPageOptionsToJSON(value.searchPageOptions),
        'searchResult': SeoPageOptionsToJSON(value.searchResult),
    };
}

