import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import { createWrapper } from 'next-redux-wrapper';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './reducers/AuthSlice';
import SRPReducer from './reducers/SRPSlice';
import syiReducer from './reducers/SYISlice';

const reducers = combineReducers({
  auth: authReducer,
  srpReducer: SRPReducer,
  syiReducer: syiReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export function makeStore() {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return configureStore({
      reducer: persistedReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }),
    });
  } else {
    const store = configureStore({
      reducer: persistedReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }),
    });
    (store as any).__persistor = persistStore(store); // Nasty hack
    return store;
  }
}

export type AppStore = ReturnType<typeof makeStore>;

export type AppState = ReturnType<AppStore['getState']>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

const wrapper = createWrapper<AppStore>(makeStore, {
  debug: false,
  serializeState: state => JSON.stringify(state),
  deserializeState: state => JSON.parse(state),
});

export default wrapper;
