/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AllNotificationSettingsDto,
    AllNotificationSettingsDtoFromJSON,
    AllNotificationSettingsDtoFromJSONTyped,
    AllNotificationSettingsDtoToJSON,
} from './AllNotificationSettingsDto';
import {
    DealerAdditionalDataDto,
    DealerAdditionalDataDtoFromJSON,
    DealerAdditionalDataDtoFromJSONTyped,
    DealerAdditionalDataDtoToJSON,
} from './DealerAdditionalDataDto';
import {
    FenerumSubscriptionUserProfileDto,
    FenerumSubscriptionUserProfileDtoFromJSON,
    FenerumSubscriptionUserProfileDtoFromJSONTyped,
    FenerumSubscriptionUserProfileDtoToJSON,
} from './FenerumSubscriptionUserProfileDto';
import {
    LeadsAttachedToCompaniesDto,
    LeadsAttachedToCompaniesDtoFromJSON,
    LeadsAttachedToCompaniesDtoFromJSONTyped,
    LeadsAttachedToCompaniesDtoToJSON,
} from './LeadsAttachedToCompaniesDto';
import {
    UserProfileType,
    UserProfileTypeFromJSON,
    UserProfileTypeFromJSONTyped,
    UserProfileTypeToJSON,
} from './UserProfileType';

/**
 * 
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    screenName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    ratingAverage?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    ratingCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    postMasterImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    profilePictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    ownershipUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    longitude?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    streetNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    countryArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {UserProfileType}
     * @memberof ProfileDto
     */
    profileType?: UserProfileType;
    /**
     * 
     * @type {number}
     * @memberof ProfileDto
     */
    classifiedCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    facebookPageBoxShown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    facebookPageUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    getNewsLetter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    receivePromotion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    getOffers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    showAddress?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    protectStreetNumber?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ProfileDto
     */
    lastEditedDate?: Date | null;
    /**
     * 
     * @type {DealerAdditionalDataDto}
     * @memberof ProfileDto
     */
    dealerData?: DealerAdditionalDataDto;
    /**
     * 
     * @type {Array<LeadsAttachedToCompaniesDto>}
     * @memberof ProfileDto
     */
    profileLeads?: Array<LeadsAttachedToCompaniesDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    financingOptedOut?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDto
     */
    notifyOnChangesToLikes?: boolean;
    /**
     * 
     * @type {Array<FenerumSubscriptionUserProfileDto>}
     * @memberof ProfileDto
     */
    fenerumSubscriptionUserProfiles?: Array<FenerumSubscriptionUserProfileDto> | null;
    /**
     * 
     * @type {AllNotificationSettingsDto}
     * @memberof ProfileDto
     */
    internalNotificationSetting?: AllNotificationSettingsDto;
}

export function ProfileDtoFromJSON(json: any): ProfileDto {
    return ProfileDtoFromJSONTyped(json, false);
}

export function ProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'screenName': !exists(json, 'screenName') ? undefined : json['screenName'],
        'ratingAverage': !exists(json, 'ratingAverage') ? undefined : json['ratingAverage'],
        'ratingCount': !exists(json, 'ratingCount') ? undefined : json['ratingCount'],
        'postMasterImageUrl': !exists(json, 'postMasterImageUrl') ? undefined : json['postMasterImageUrl'],
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'ownershipUrl': !exists(json, 'ownershipUrl') ? undefined : json['ownershipUrl'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'countryArea': !exists(json, 'countryArea') ? undefined : json['countryArea'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'profileType': !exists(json, 'profileType') ? undefined : UserProfileTypeFromJSON(json['profileType']),
        'classifiedCount': !exists(json, 'classifiedCount') ? undefined : json['classifiedCount'],
        'facebookPageBoxShown': !exists(json, 'facebookPageBoxShown') ? undefined : json['facebookPageBoxShown'],
        'facebookPageUrl': !exists(json, 'facebookPageUrl') ? undefined : json['facebookPageUrl'],
        'getNewsLetter': !exists(json, 'getNewsLetter') ? undefined : json['getNewsLetter'],
        'receivePromotion': !exists(json, 'receivePromotion') ? undefined : json['receivePromotion'],
        'getOffers': !exists(json, 'getOffers') ? undefined : json['getOffers'],
        'showAddress': !exists(json, 'showAddress') ? undefined : json['showAddress'],
        'protectStreetNumber': !exists(json, 'protectStreetNumber') ? undefined : json['protectStreetNumber'],
        'lastEditedDate': !exists(json, 'lastEditedDate') ? undefined : (json['lastEditedDate'] === null ? null : new Date(json['lastEditedDate'])),
        'dealerData': !exists(json, 'dealerData') ? undefined : DealerAdditionalDataDtoFromJSON(json['dealerData']),
        'profileLeads': !exists(json, 'profileLeads') ? undefined : (json['profileLeads'] === null ? null : (json['profileLeads'] as Array<any>).map(LeadsAttachedToCompaniesDtoFromJSON)),
        'financingOptedOut': !exists(json, 'financingOptedOut') ? undefined : json['financingOptedOut'],
        'notifyOnChangesToLikes': !exists(json, 'notifyOnChangesToLikes') ? undefined : json['notifyOnChangesToLikes'],
        'fenerumSubscriptionUserProfiles': !exists(json, 'fenerumSubscriptionUserProfiles') ? undefined : (json['fenerumSubscriptionUserProfiles'] === null ? null : (json['fenerumSubscriptionUserProfiles'] as Array<any>).map(FenerumSubscriptionUserProfileDtoFromJSON)),
        'internalNotificationSetting': !exists(json, 'internalNotificationSetting') ? undefined : AllNotificationSettingsDtoFromJSON(json['internalNotificationSetting']),
    };
}

export function ProfileDtoToJSON(value?: ProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'screenName': value.screenName,
        'ratingAverage': value.ratingAverage,
        'ratingCount': value.ratingCount,
        'postMasterImageUrl': value.postMasterImageUrl,
        'profilePictureUrl': value.profilePictureUrl,
        'imageUrl': value.imageUrl,
        'ownershipUrl': value.ownershipUrl,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
        'zipCode': value.zipCode,
        'city': value.city,
        'country': value.country,
        'countryCode': value.countryCode,
        'countryArea': value.countryArea,
        'website': value.website,
        'phoneNumber': value.phoneNumber,
        'profileType': UserProfileTypeToJSON(value.profileType),
        'classifiedCount': value.classifiedCount,
        'facebookPageBoxShown': value.facebookPageBoxShown,
        'facebookPageUrl': value.facebookPageUrl,
        'getNewsLetter': value.getNewsLetter,
        'receivePromotion': value.receivePromotion,
        'getOffers': value.getOffers,
        'showAddress': value.showAddress,
        'protectStreetNumber': value.protectStreetNumber,
        'lastEditedDate': value.lastEditedDate === undefined ? undefined : (value.lastEditedDate === null ? null : value.lastEditedDate.toISOString()),
        'dealerData': DealerAdditionalDataDtoToJSON(value.dealerData),
        'profileLeads': value.profileLeads === undefined ? undefined : (value.profileLeads === null ? null : (value.profileLeads as Array<any>).map(LeadsAttachedToCompaniesDtoToJSON)),
        'financingOptedOut': value.financingOptedOut,
        'notifyOnChangesToLikes': value.notifyOnChangesToLikes,
        'fenerumSubscriptionUserProfiles': value.fenerumSubscriptionUserProfiles === undefined ? undefined : (value.fenerumSubscriptionUserProfiles === null ? null : (value.fenerumSubscriptionUserProfiles as Array<any>).map(FenerumSubscriptionUserProfileDtoToJSON)),
        'internalNotificationSetting': AllNotificationSettingsDtoToJSON(value.internalNotificationSetting),
    };
}

