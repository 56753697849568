import { Colors } from '@/shared/util/colors';
import i18n from '@/shared/util/i18n/i18n';
import { theme } from '@/web/util/theme';
import BottomNaigationContainer from '@/web/views/components/bottom-navigation/bottom-navigation-container';
import { Box, ThemeProvider, useMediaQuery } from '@mui/material';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { I18nextProvider } from 'react-i18next';
import '@/web/util/styles/globals.css';
import wrapper from '../shared/store/store';
import { ToastContainer } from 'react-toastify';
import { LoginModalMode } from '@/shared/util/helpers/auth-helper';
import type { NextPage } from 'next';
import { SiteSettings, getSiteSettings } from '@/web/sitesettings';
import { StructuredDataOrganization } from '@/web/views/components/default-components/structured-data-organization';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GetTitleBase } from '@/web/util/helpers/seo-srp-helpers/title-meta-helpers';
import { GetDescriptionBase } from '@/web/util/helpers/seo-srp-helpers/description-meta-helpers';
import { getMobileDetect } from '@/shared/util/helpers/next-helpers/node-server-helper';
import { useClassificationLimit } from '@/shared/hooks/common/useClassificationLimit';
import ClassificationLimitWrapper from '@/web/views/components/containers/classification-limit-wrapper';
import Script from 'next/script';
import { PageLoginTracking } from '@/web/views/components/default-components/page-login-tracking';
import {
  Experiment,
  GrowthBook,
  GrowthBookProvider,
  Result,
} from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { TrackExperimentViewed, getClientId } from '@/shared/util/gtm';
import { logout } from '@/shared/store/reducers/AuthSlice';
import { Provider } from 'react-redux';

const LoginContext = createContext<
  | {
      close: () => void;
      open: () => void;
      setMode: React.Dispatch<React.SetStateAction<LoginModalMode>>;
      mode: LoginModalMode;
      isOpen: boolean;
    }
  | undefined
>(undefined);

const SiteSettingContext = createContext<SiteSettings>(undefined);
interface ScreenSize {
  isMobile: boolean;
  isIos: boolean;
  isAndroid: boolean;
}
const ScreenSizeContext = createContext<ScreenSize>(undefined);

export const useScreenSizeContext = () => {
  const context = useContext(ScreenSizeContext);
  return context;
};

export const useSiteSettingContext = () => {
  const context = useContext(SiteSettingContext);
  return context;
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  return context;
};

MyApp.getInitialProps = async appContext => {
  // Get country from ip
  let ip: string | null = null;
  try {
    const forwarded = appContext.ctx.req.headers['x-forwarded-for'];
    ip = forwarded
      ? forwarded.split(/, /)[0]
      : appContext.ctx.req.connection.remoteAddress;
  } catch {}
  // END: Get country from ip
  return {
    pageProps: {
      host: appContext.ctx.req?.headers['host'] ?? '',
      userAgent: appContext.ctx.req?.headers['user-agent'] ?? '',
      ip: ip,
    },
  };
};

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: any;
};

// GrowthBook
const onExperimentViewed = (
  experiment: Experiment<any>,
  result: Result<any>,
) => {
  const experimentId = experiment.key;
  const variationId = result.key;

  TrackExperimentViewed(experimentId, variationId);
};

const gb = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV === 'development',
  trackingCallback: onExperimentViewed,
  attributes: {
    id: getClientId(),
  },
});

function updateGrowthBookURL() {
  gb.setURL(window.location.href);
}
// END GrowthBook

export interface SeoInfo {
  seoSrp: boolean;
  ssgSrp: boolean;
}

function MyApp({ Component, ...rest }: AppPropsWithLayout) {
  const { pageProps } = rest;
  const { store } = wrapper.useWrappedStore(rest);
  const { loginToken, expirationDate } = store.getState().auth;
  const dispatch = store.dispatch;

  const router = useRouter();

  const news = useMemo(() => {
    return pageProps?.news ?? false;
  }, [pageProps]);
  const aiSeo = useMemo(() => {
    return pageProps?.aiSeo ?? false;
  }, [pageProps]);
  const [isLimited] = useClassificationLimit(75);
  const [mode, setMode] = useState<LoginModalMode>(LoginModalMode.initial);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (loginToken && !expirationDate) {
      dispatch(logout());
    }
  }, [dispatch, expirationDate, loginToken]);

  useEffect(() => {
    // Load features from the GrowthBook API and keep them up-to-date
    gb.loadFeatures({ autoRefresh: true });
    const clientId = getClientId();
    gb.setAttributes({ id: clientId });

    // Subscribe to route change events and update GrowthBook
    router.events.on('routeChangeComplete', updateGrowthBookURL);
    return () => router.events.off('routeChangeComplete', updateGrowthBookURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const setCurrentMode = useCallback((currentMode: LoginModalMode) => {
    setMode(currentMode);
  }, []);

  const loginModalProviderValue = useMemo(
    () => ({
      close: closeModal,
      open: openModal,
      setMode: setCurrentMode,
      mode: mode,
      isOpen: isOpen,
    }),
    [closeModal, openModal, setCurrentMode, mode, isOpen],
  );
  const mobile: ScreenSize = useMemo(() => {
    var result = getMobileDetect(news ? '' : pageProps.userAgent, isMobile);
    return {
      isMobile: result.isMobile(),
      isAndroid: result.isAndroid(),
      isIos: result.isIos(),
    };
  }, [pageProps.userAgent, isMobile, news]);
  const getLayout = Component.getLayout ?? (page => page);

  const siteSettings = useMemo(() => {
    return getSiteSettings(news ? '' : pageProps.host ?? '');
  }, [pageProps.host, news]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Head>
          <title key="title">{GetTitleBase()}</title>
          <meta
            name="description"
            content={GetDescriptionBase()}
            key="meta-description"
          />
          <meta name="theme-color" content="#2C8BA9" />
          <meta name="msapplication-navbutton-color" content="#2C8BA9" />
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="#2C8BA9"
          />
          <meta property="og:type" content="website" key="og-type" />
          <meta property="fb:app_id" content="269684716804782" />
          {siteSettings.name === 'BilEkspressen' && (
            <meta name="robots" content="noindex" />
          )}
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />

          {StructuredDataOrganization()}
        </Head>
        {siteSettings.name !== 'BilEkspressen' && (
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KQ4FBX3S');
      `}
          </Script>
        )}
        {siteSettings.name === 'BilEkspressen' && (
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TMHGXP73');
      `}
          </Script>
        )}

        <I18nextProvider i18n={i18n}>
          <SiteSettingContext.Provider value={siteSettings}>
            <ScreenSizeContext.Provider value={mobile}>
              <LoginContext.Provider value={loginModalProviderValue}>
                <GoogleOAuthProvider
                  clientId={process.env.NEXT_PUBLIC_GOOGLE_DESKTOP_LOGIN ?? ''}>
                  <ClassificationLimitWrapper
                    classification={100}></ClassificationLimitWrapper>
                  <ToastContainer position="top-center" />
                  <Box
                    height={'100%'}
                    minHeight={'100vh'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'space-between'}>
                    <PageLoginTracking ip={pageProps.ip}>
                      <Box
                        paddingBottom={news || aiSeo ? 0 : 3}
                        flexGrow={1}
                        bgcolor={Colors.Background.SubBackgroundColor}>
                        <GrowthBookProvider growthbook={gb}>
                          {getLayout(<Component {...pageProps} />)}
                        </GrowthBookProvider>
                      </Box>
                    </PageLoginTracking>
                    <BottomNaigationContainer />
                  </Box>
                </GoogleOAuthProvider>
              </LoginContext.Provider>
            </ScreenSizeContext.Provider>
          </SiteSettingContext.Provider>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default MyApp;
