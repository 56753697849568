/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckEmailRequest,
    CheckEmailRequestFromJSON,
    CheckEmailRequestToJSON,
    CheckEmailResponse,
    CheckEmailResponseFromJSON,
    CheckEmailResponseToJSON,
    CreateUserFromEmailRequest,
    CreateUserFromEmailRequestFromJSON,
    CreateUserFromEmailRequestToJSON,
    CreateUserFromSocialRequest,
    CreateUserFromSocialRequestFromJSON,
    CreateUserFromSocialRequestToJSON,
    DriverInformationData,
    DriverInformationDataFromJSON,
    DriverInformationDataToJSON,
    FinishUserRequest,
    FinishUserRequestFromJSON,
    FinishUserRequestToJSON,
    LoginToken,
    LoginTokenFromJSON,
    LoginTokenToJSON,
    NotificationTermsInformationData,
    NotificationTermsInformationDataFromJSON,
    NotificationTermsInformationDataToJSON,
    PasswordResetRequest,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    ProfileInformationData,
    ProfileInformationDataFromJSON,
    ProfileInformationDataToJSON,
    ProfileInformationSYIData,
    ProfileInformationSYIDataFromJSON,
    ProfileInformationSYIDataToJSON,
    RefreshTokenRequest,
    RefreshTokenRequestFromJSON,
    RefreshTokenRequestToJSON,
    SetTermsFromSocialRequest,
    SetTermsFromSocialRequestFromJSON,
    SetTermsFromSocialRequestToJSON,
    SignInRequest,
    SignInRequestFromJSON,
    SignInRequestToJSON,
    TermsInformationData,
    TermsInformationDataFromJSON,
    TermsInformationDataToJSON,
    UserItem,
    UserItemFromJSON,
    UserItemToJSON,
    VerifyPasswordResetRequest,
    VerifyPasswordResetRequestFromJSON,
    VerifyPasswordResetRequestToJSON,
} from '../models';

export interface ApiAuthCheckemailPostRequest {
    checkEmailRequest?: CheckEmailRequest;
}

export interface ApiAuthCreateuserPostRequest {
    createUserFromEmailRequest?: CreateUserFromEmailRequest;
}

export interface ApiAuthCreateuserfromsocialPostRequest {
    createUserFromSocialRequest?: CreateUserFromSocialRequest;
}

export interface ApiAuthFinishuserPostRequest {
    finishUserRequest?: FinishUserRequest;
}

export interface ApiAuthRefreshtokenPostRequest {
    refreshTokenRequest: RefreshTokenRequest;
}

export interface ApiAuthRequestpasswordresetPostRequest {
    passwordResetRequest?: PasswordResetRequest;
}

export interface ApiAuthSetpermissionsfromsocialPostRequest {
    setTermsFromSocialRequest?: SetTermsFromSocialRequest;
}

export interface ApiAuthSigninemailPostRequest {
    signInRequest?: SignInRequest;
}

export interface ApiAuthUpdatedriverinformationdataPostRequest {
    driverInformationData?: DriverInformationData;
}

export interface ApiAuthUpdateemailsignupsPostRequest {
    termsInformationData?: TermsInformationData;
}

export interface ApiAuthUpdatenotificationsPostRequest {
    notificationTermsInformationData?: NotificationTermsInformationData;
}

export interface ApiAuthUpdateprofileinformationdataPostRequest {
    profileInformationData?: ProfileInformationData;
}

export interface ApiAuthUpdateprofileinformationsyidataPostRequest {
    profileInformationSYIData?: ProfileInformationSYIData;
}

export interface ApiAuthVerifypasswordresetPostRequest {
    verifyPasswordResetRequest?: VerifyPasswordResetRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthCheckemailPostRaw(requestParameters: ApiAuthCheckemailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckEmailResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/checkemail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckEmailRequestToJSON(requestParameters.checkEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckEmailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthCheckemailPost(requestParameters: ApiAuthCheckemailPostRequest = {}, initOverrides?: RequestInit): Promise<CheckEmailResponse> {
        const response = await this.apiAuthCheckemailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthCreateuserPostRaw(requestParameters: ApiAuthCreateuserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/createuser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserFromEmailRequestToJSON(requestParameters.createUserFromEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthCreateuserPost(requestParameters: ApiAuthCreateuserPostRequest = {}, initOverrides?: RequestInit): Promise<LoginToken> {
        const response = await this.apiAuthCreateuserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthCreateuserfromsocialPostRaw(requestParameters: ApiAuthCreateuserfromsocialPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/createuserfromsocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserFromSocialRequestToJSON(requestParameters.createUserFromSocialRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthCreateuserfromsocialPost(requestParameters: ApiAuthCreateuserfromsocialPostRequest = {}, initOverrides?: RequestInit): Promise<LoginToken> {
        const response = await this.apiAuthCreateuserfromsocialPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthDeleteuserDeleteRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/deleteuser`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthDeleteuserDelete(initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthDeleteuserDeleteRaw(initOverrides);
    }

    /**
     */
    async apiAuthFinishuserPostRaw(requestParameters: ApiAuthFinishuserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/finishuser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinishUserRequestToJSON(requestParameters.finishUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthFinishuserPost(requestParameters: ApiAuthFinishuserPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthFinishuserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthGetuserPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/getuser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthGetuserPost(initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthGetuserPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthIsauthorizeHeadRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/isauthorize`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthIsauthorizeHead(initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthIsauthorizeHeadRaw(initOverrides);
    }

    /**
     */
    async apiAuthRefreshtokenPostRaw(requestParameters: ApiAuthRefreshtokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters.refreshTokenRequest === null || requestParameters.refreshTokenRequest === undefined) {
            throw new runtime.RequiredError('refreshTokenRequest','Required parameter requestParameters.refreshTokenRequest was null or undefined when calling apiAuthRefreshtokenPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/refreshtoken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenRequestToJSON(requestParameters.refreshTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthRefreshtokenPost(requestParameters: ApiAuthRefreshtokenPostRequest, initOverrides?: RequestInit): Promise<LoginToken> {
        const response = await this.apiAuthRefreshtokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthRequestpasswordresetPostRaw(requestParameters: ApiAuthRequestpasswordresetPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/requestpasswordreset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters.passwordResetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthRequestpasswordresetPost(requestParameters: ApiAuthRequestpasswordresetPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthRequestpasswordresetPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAuthSetpermissionsfromsocialPostRaw(requestParameters: ApiAuthSetpermissionsfromsocialPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/setpermissionsfromsocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetTermsFromSocialRequestToJSON(requestParameters.setTermsFromSocialRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthSetpermissionsfromsocialPost(requestParameters: ApiAuthSetpermissionsfromsocialPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthSetpermissionsfromsocialPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthSigninemailPostRaw(requestParameters: ApiAuthSigninemailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/signinemail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestToJSON(requestParameters.signInRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthSigninemailPost(requestParameters: ApiAuthSigninemailPostRequest = {}, initOverrides?: RequestInit): Promise<LoginToken> {
        const response = await this.apiAuthSigninemailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthUpdatedriverinformationdataPostRaw(requestParameters: ApiAuthUpdatedriverinformationdataPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/updatedriverinformationdata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverInformationDataToJSON(requestParameters.driverInformationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthUpdatedriverinformationdataPost(requestParameters: ApiAuthUpdatedriverinformationdataPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthUpdatedriverinformationdataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthUpdateemailsignupsPostRaw(requestParameters: ApiAuthUpdateemailsignupsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/updateemailsignups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TermsInformationDataToJSON(requestParameters.termsInformationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthUpdateemailsignupsPost(requestParameters: ApiAuthUpdateemailsignupsPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthUpdateemailsignupsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthUpdatenotificationsPostRaw(requestParameters: ApiAuthUpdatenotificationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/updatenotifications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationTermsInformationDataToJSON(requestParameters.notificationTermsInformationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthUpdatenotificationsPost(requestParameters: ApiAuthUpdatenotificationsPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthUpdatenotificationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthUpdateprofileinformationdataPostRaw(requestParameters: ApiAuthUpdateprofileinformationdataPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/updateprofileinformationdata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileInformationDataToJSON(requestParameters.profileInformationData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthUpdateprofileinformationdataPost(requestParameters: ApiAuthUpdateprofileinformationdataPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthUpdateprofileinformationdataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthUpdateprofileinformationsyidataPostRaw(requestParameters: ApiAuthUpdateprofileinformationsyidataPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/updateprofileinformationsyidata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileInformationSYIDataToJSON(requestParameters.profileInformationSYIData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserItemFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthUpdateprofileinformationsyidataPost(requestParameters: ApiAuthUpdateprofileinformationsyidataPostRequest = {}, initOverrides?: RequestInit): Promise<UserItem> {
        const response = await this.apiAuthUpdateprofileinformationsyidataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAuthVerifypasswordresetPostRaw(requestParameters: ApiAuthVerifypasswordresetPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/auth/verifypasswordreset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyPasswordResetRequestToJSON(requestParameters.verifyPasswordResetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAuthVerifypasswordresetPost(requestParameters: ApiAuthVerifypasswordresetPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiAuthVerifypasswordresetPostRaw(requestParameters, initOverrides);
    }

}
