export function thousandNumberSeperator(x: number | string | null | undefined) {
  if (typeof x === 'number') {
    if (x === 0) {
      return '0';
    }
  }

  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return '';
}

export function capitalize(x: string) {
  if (x) {
    return x.charAt(0).toUpperCase() + x.substring(1);
  }
  return '';
}

export function capitalizer(x: string) {
  if (x) {
    return x.charAt(0).toUpperCase() + x.substring(1);
  }
  return undefined;
}

export const isCoalesceSupported = (): boolean => {
  try {
    new Function('null ?? false;');
    return true;
  } catch (e) {
    return false;
  }
};

export enum LoadingState {
  None,
  Loading,
  Success,
  Error,
  BadRequest,
  NotFound,
}
