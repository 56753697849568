/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontPageRecommendationsRequest
 */
export interface FrontPageRecommendationsRequest {
    /**
     * 
     * @type {string}
     * @memberof FrontPageRecommendationsRequest
     */
    tTyUserId?: string | null;
}

export function FrontPageRecommendationsRequestFromJSON(json: any): FrontPageRecommendationsRequest {
    return FrontPageRecommendationsRequestFromJSONTyped(json, false);
}

export function FrontPageRecommendationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontPageRecommendationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tTyUserId': !exists(json, 'tTyUserId') ? undefined : json['tTyUserId'],
    };
}

export function FrontPageRecommendationsRequestToJSON(value?: FrontPageRecommendationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tTyUserId': value.tTyUserId,
    };
}

