/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttachmentFileType {
    Picture = 'Picture',
    Video = 'Video',
    Gif = 'Gif',
    Png = 'Png'
}

export function AttachmentFileTypeFromJSON(json: any): AttachmentFileType {
    return AttachmentFileTypeFromJSONTyped(json, false);
}

export function AttachmentFileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentFileType {
    return json as AttachmentFileType;
}

export function AttachmentFileTypeToJSON(value?: AttachmentFileType | null): any {
    return value as any;
}

