import { createTheme } from '@mui/material/styles';
import { Colors } from '../../shared/util/colors';
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    yellow: true;
    lightBlue: true;
    buttonBlue: true;
    fdmYellow: true;
    tertiary: true;
  }
}
declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    white: string;
    tableHead: string;
    subBackgroundColor: string;
    footer: string;
    footerDark: string;
    cms: string;
    cmsPageLink: string;
  }

  interface PaletteOptions {
    link: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    muted: PaletteColorOptions;
    transparent: PaletteColorOptions;
    yellow: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
    buttonBlue: PaletteColorOptions;
    fdmYellow: PaletteColorOptions;
    tertiary: PaletteColorOptions;
  }
}
declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    captionSmall: true;
    captionLarge: true;
    clickAble: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.Primary,
    },
    secondary: {
      main: Colors.Secondary,
    },
    error: {
      main: Colors.Error,
    },
    fdmYellow: {
      main: '#0171FF',
      contrastText: '#FFF',
    },
    warning: {
      main: Colors.Warning,
    },
    info: {
      main: Colors.Info,
    },
    success: {
      main: Colors.Success,
    },
    link: {
      main: Colors.LinkColor,
    },
    tertiary: {
      main: '#ECF4F6',
      contrastText: '#085768',
    },
    background: {
      default: Colors.Background.Default,
      subBackgroundColor: Colors.Background.SubBackgroundColor,
      tableHead: Colors.Background.TableHead,
      footer: Colors.Background.Footer,
      footerDark: Colors.Background.FooterDark,
      cms: Colors.Background.Cms,
      cmsPageLink: Colors.Background.CmsPageLink,
      white: Colors.White,
    },
    white: {
      main: Colors.White,
    },
    black: {
      main: Colors.Black,
    },
    muted: {
      main: Colors.Muted,
    },
    transparent: {
      main: '#FFFFFF00',
    },
    lightBlue: {
      main: Colors.LightBlue,
    },
    yellow: {
      main: Colors.Yellow,
    },
    buttonBlue: {
      main: Colors.DarkBlue,
      contrastText: Colors.White,
    },
  },
  typography: {
    fontFamily: "'Mukta', sans-serif",
    fontSize: 14,
    allVariants: {
      fontFamily: "'Mukta', sans-serif",
    },
    h1: {
      fontSize: '32px',
      lineHeight: '37px',
      fontWeight: 700,
      paddingTop: '45px',
      '@media (max-width:900px)': {
        fontSize: '18px',
        lineHeight: '30px',
        paddingTop: '24px',
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      '@media (max-width:900px)': {
        fontSize: '22px',
        lineHeight: '21px',
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: '36px',
    },
    h4: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '1.235',
      color: Colors.DefaultFontColor,
    },
    h5: {
      fontWeight: 700,
      fontSize: 22,
    },
    h6: {
      fontWeight: 700,
      fontSize: 18,
    },
    body1: {
      fontSize: 14,
      color: Colors.DefaultFontColor,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 16,
    },
    caption: { fontSize: 13, color: Colors.Muted },
  },
  components: {
    MuiDivider: {
      defaultProps: {},
      styleOverrides: {
        wrapper: {
          fontFamily: "'Mukta', sans-serif",
          color: '#8C8C8C',
          fontSize: '12px',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'clickAble' },
          style: {
            fontFamily: "'Mukta', sans-serif",
            fontSize: 15,
            cursor: 'pointer',
            color: '#1066B6',
          },
        },
        {
          props: { variant: 'captionSmall' },
          style: {
            fontFamily: "'Mukta', sans-serif",
            fontSize: 10,
            color: Colors.Muted,
          },
        },
        {
          props: { variant: 'captionLarge' },
          style: {
            fontFamily: "'Mukta', sans-serif",
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'uppercase',
            color: Colors.DarkMuted,
          },
        },
        {
          props: { variant: 'h7' },
          style: {
            fontFamily: "'Mukta', sans-serif",
            fontSize: 16,
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'h8' },
          style: {
            fontFamily: "'Mukta', sans-serif",
            fontSize: '14px',
            color: Colors.Muted,
          },
        },
      ],
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        fontFamily: "'Mukta', sans-serif",
        color: Colors.LinkColor,
      },
    },
    MuiTabs: {
      defaultProps: {
        color: Colors.Black,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '4px',
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '4px',
          marginLeft: '4px',
          marginRight: '4px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "'Mukta', sans-serif",
          fontSize: 13,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '4px 0px 4px 0px',
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
        },
        root: {
          padding: '0px !important',
        },
        focused: {},
        notchedOutline: {
          borderWidth: '0px !important',
          borderColor: '#D9D9D9 !important',
          borderRadius: '2px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderWidth: '1px',
          borderColor: Colors.Muted,
          borderStyle: 'solid',
          borderRadius: '2px',
          paddingTop: 0,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          maxHeight: '100%',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          borderWidth: '1px',
          borderColor: Colors.Muted,
          borderStyle: 'solid',
          borderRadius: '2px',
          paddingTop: 0,
          paddingBottom: 4,
          maxHeight: '42vh',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '>li': {
            '&.Mui-focused': {
              backgroundColor: '#1783D1 !important',
              h6: {
                color: '#fff  !important',
              },
              p: {
                color: '#fff  !important',
              },
            },
          },
        },
        inputRoot: {
          paddingLeft: '12px!important',
        },
        input: {
          padding: '0!important',
          '&::placeholder': {
            opacity: 1,
          },
        },
        noOptions: {
          borderWidth: '1px',
          borderColor: Colors.Muted,
          borderStyle: 'solid',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#777777',
        },
        body: {
          fontSize: 15,
          paddingLeft: 8,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: '#085768',
          backgroundColor: '#fbfbfb',
          '&.Mui-selected': {
            color: Colors.Primary,
            backgroundColor: '#fbfbfb',
          },
          '&.MuiPaginationItem-ellipsis': {
            backgroundColor: 'transparent',
            color: Colors.Muted,
            fontWeight: 700,
            marginTop: '-10px',
            fontSize: 25,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '3px !important',
        },
        root: {
          maxHeight: 300,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '12px !important',
          fontSize: '16px !important',
          backgroundColor: '#fff',
          ':hover': {
            backgroundColor: '#1783D1 !important',
          },
          '&.Mui-disabled': {
            color: `${Colors.Black} !important`,
            fontWeight: 700,
            opacity: 1,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tr: { height: 'fit-content !important' },
          td: {
            height: 'fit-content !important',
            verticalAlign: 'top',
            paddingRight: 16,
            '@media (max-width:600px)': {
              paddingLeft: '0px',
              paddingRight: '0px',
            },
          },
          p: {
            textAlign: 'left',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.White,
        },
      },
    },
    MuiList: { defaultProps: { disablePadding: true } },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 1500,
      },
      styleOverrides: {
        arrow: {
          color: '#EBEDEF',
        },
        tooltip: {
          maxWidth: '195px',
          fontsize: '12px',
          color: Colors.DefaultFontColor,
          backgroundColor: '#EBEDEF',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        color: Colors.Black,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: { boxShadow: 'none', height: '100%', overflow: 'visible' },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 40,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: { borderRadius: '0' },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          width: 90,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          padding: '8.5px 29px 8.5px 12px !important',
        },
        standard: {
          paddingRight: 0,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: '40px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          flexBasis: 'content',
          flexGrow: 1,
          textTransform: 'none',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            paddingRight: 10,
            paddingLeft: 10,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          maxWidth: '100vw',
          overflow: 'hidden',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: { fontWeight: 'bold !important' as any },
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media (min-width:600px)': {
            borderRadius: 10,
            boxShadow: '0px 2px 44px rgba(0, 0, 0, 0.25)',
            backgroundColor: Colors.Background.CmsPageLink,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '@media (min-width:600px)': {
            padding: 30,
            borderRadius: 10,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          '@media (min-width:600px)': {
            minHeight: '80px !important',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        textPrimary: { color: Colors.White },
        root: {
          height: 46,
          boxShadow: 'none',
          borderRadius: '3px',
          textTransform: 'none',
          fontSize: '15px',
        },
        sizeSmall: {
          height: 35,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '4px 0px 4px 0px',
          '&[type=number]': {
            MozAppearance: 'textfield',
          },
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
        root: {
          fontSize: 16,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          padding: '0px !important',

          margin: 2,
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: Colors.Secondary + ' !important',
          },
        },
        track: { backgroundColor: '#BFBFBF' + ' !important', opacity: 1 },
        sizeSmall: {
          width: 36,
          height: 20,
          borderRadius: 13,

          padding: 0,
        },
        thumb: {
          color: '#fff',
          width: 22,
          height: 22,
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: { color: '#FCEBEC' },
        valueLabel: { color: '#343434', backgroundColor: '#EBEDEF' },
        mark: { color: 'white' },
      },
    },
  },
});

export type TjekbilTheme = typeof theme;
