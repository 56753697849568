import { useEffect, useState } from 'react';
import { GeoIpResponse } from '../lib-api';
import { useApi } from '../util/api';
const useGeoLocation = (ip?: string) => {
  const {geoLocationApi} = useApi();
  const [geoLocation, setGeoLocation] = useState<GeoIpResponse>(null);
  const [loading, setloading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      setloading(true);
      let geoIpRequest = geoLocationApi.apiGeolocationGeoipGet({ ip });
      geoIpRequest
        .then(result => {
          setGeoLocation(result);
        })
        .catch(() => {
          setGeoLocation(null);
        })
        .finally(() => {
          setloading(false);
        });
    };
    fetch();
  }, [geoLocationApi, ip]);
  return { geoLocation, loading };
};
export default useGeoLocation;
