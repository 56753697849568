import { useClassificationLimit } from '@/shared/hooks/common/useClassificationLimit';
import { FC, ReactNode } from 'react';

type Props = {
  classification: number;
  children?: ReactNode;
};

const ClassificationLimitWrapper: FC<Props> = ({
  classification,
  children,
}) => {
  const [isLimited] = useClassificationLimit(classification);

  /*
  console.log(`classificationLimit: ${classificationLimit}`);
  console.log(`classification: ${classification}`);
  console.log(`doRender: ${doRender}`);
  */
  return isLimited ? <></> : <>{children}</>;
};

export default ClassificationLimitWrapper;
