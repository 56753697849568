/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RemoteConnectionType,
    RemoteConnectionTypeFromJSON,
    RemoteConnectionTypeFromJSONTyped,
    RemoteConnectionTypeToJSON,
} from './RemoteConnectionType';

/**
 * 
 * @export
 * @interface CreateUserFromSocialRequest
 */
export interface CreateUserFromSocialRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromSocialRequest
     */
    token: string;
    /**
     * 
     * @type {RemoteConnectionType}
     * @memberof CreateUserFromSocialRequest
     */
    remoteConnectionType?: RemoteConnectionType;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromSocialRequest
     */
    name?: string | null;
}

export function CreateUserFromSocialRequestFromJSON(json: any): CreateUserFromSocialRequest {
    return CreateUserFromSocialRequestFromJSONTyped(json, false);
}

export function CreateUserFromSocialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserFromSocialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'remoteConnectionType': !exists(json, 'remoteConnectionType') ? undefined : RemoteConnectionTypeFromJSON(json['remoteConnectionType']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CreateUserFromSocialRequestToJSON(value?: CreateUserFromSocialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'remoteConnectionType': RemoteConnectionTypeToJSON(value.remoteConnectionType),
        'name': value.name,
    };
}

