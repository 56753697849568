/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';
import {
    SearchPageMakeModelVariantOption,
    SearchPageMakeModelVariantOptionFromJSON,
    SearchPageMakeModelVariantOptionFromJSONTyped,
    SearchPageMakeModelVariantOptionToJSON,
} from './SearchPageMakeModelVariantOption';

/**
 * 
 * @export
 * @interface SearchPageOptions
 */
export interface SearchPageOptions {
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    profileType?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    doorCount?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    category?: SearchPageAttribute;
    /**
     * 
     * @type {Array<SearchPageMakeModelVariantOption>}
     * @memberof SearchPageOptions
     */
    makeModelVariantOptions?: Array<SearchPageMakeModelVariantOption> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    fuelTypes?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    carTypes?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    area?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    city?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    yearFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    yearTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    milageFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    milageTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    horsepowerTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    horsepowerFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    batteryCapacityTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    batteryCapacityFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    distanceTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    distanceFrom?: SearchPageAttribute;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    colors?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    gearBoxTypes?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    equipment?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    leasingType?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchPageOptions
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPageOptions
     */
    pageSize?: number;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    priceFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    priceTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    sort?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    radius?: SearchPageAttribute;
    /**
     * 
     * @type {string}
     * @memberof SearchPageOptions
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPageOptions
     */
    searchQuery?: string | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    dealerId?: SearchPageAttribute;
    /**
     * 
     * @type {string}
     * @memberof SearchPageOptions
     */
    canonicalUrl?: string | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    pricePerMonthFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    pricePerMonthTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    kmPerLiterFrom?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    kmPerLiterTo?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    seatNumber?: SearchPageAttribute;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    limitSearch?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageOptions
     */
    onlineSince?: SearchPageAttribute;
    /**
     * 
     * @type {Date}
     * @memberof SearchPageOptions
     */
    dateOfLastSearch?: Date | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof SearchPageOptions
     */
    limitSeatsAndDoors?: Array<SearchPageAttribute> | null;
}

export function SearchPageOptionsFromJSON(json: any): SearchPageOptions {
    return SearchPageOptionsFromJSONTyped(json, false);
}

export function SearchPageOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPageOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileType': !exists(json, 'profileType') ? undefined : SearchPageAttributeFromJSON(json['profileType']),
        'doorCount': !exists(json, 'doorCount') ? undefined : SearchPageAttributeFromJSON(json['doorCount']),
        'category': !exists(json, 'category') ? undefined : SearchPageAttributeFromJSON(json['category']),
        'makeModelVariantOptions': !exists(json, 'makeModelVariantOptions') ? undefined : (json['makeModelVariantOptions'] === null ? null : (json['makeModelVariantOptions'] as Array<any>).map(SearchPageMakeModelVariantOptionFromJSON)),
        'fuelTypes': !exists(json, 'fuelTypes') ? undefined : (json['fuelTypes'] === null ? null : (json['fuelTypes'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'carTypes': !exists(json, 'carTypes') ? undefined : (json['carTypes'] === null ? null : (json['carTypes'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'area': !exists(json, 'area') ? undefined : SearchPageAttributeFromJSON(json['area']),
        'city': !exists(json, 'city') ? undefined : SearchPageAttributeFromJSON(json['city']),
        'yearFrom': !exists(json, 'yearFrom') ? undefined : SearchPageAttributeFromJSON(json['yearFrom']),
        'yearTo': !exists(json, 'yearTo') ? undefined : SearchPageAttributeFromJSON(json['yearTo']),
        'milageFrom': !exists(json, 'milageFrom') ? undefined : SearchPageAttributeFromJSON(json['milageFrom']),
        'milageTo': !exists(json, 'milageTo') ? undefined : SearchPageAttributeFromJSON(json['milageTo']),
        'horsepowerTo': !exists(json, 'horsepowerTo') ? undefined : SearchPageAttributeFromJSON(json['horsepowerTo']),
        'horsepowerFrom': !exists(json, 'horsepowerFrom') ? undefined : SearchPageAttributeFromJSON(json['horsepowerFrom']),
        'batteryCapacityTo': !exists(json, 'batteryCapacityTo') ? undefined : SearchPageAttributeFromJSON(json['batteryCapacityTo']),
        'batteryCapacityFrom': !exists(json, 'batteryCapacityFrom') ? undefined : SearchPageAttributeFromJSON(json['batteryCapacityFrom']),
        'distanceTo': !exists(json, 'distanceTo') ? undefined : SearchPageAttributeFromJSON(json['distanceTo']),
        'distanceFrom': !exists(json, 'distanceFrom') ? undefined : SearchPageAttributeFromJSON(json['distanceFrom']),
        'colors': !exists(json, 'colors') ? undefined : (json['colors'] === null ? null : (json['colors'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'gearBoxTypes': !exists(json, 'gearBoxTypes') ? undefined : (json['gearBoxTypes'] === null ? null : (json['gearBoxTypes'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'equipment': !exists(json, 'equipment') ? undefined : (json['equipment'] === null ? null : (json['equipment'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'leasingType': !exists(json, 'leasingType') ? undefined : (json['leasingType'] === null ? null : (json['leasingType'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'priceFrom': !exists(json, 'priceFrom') ? undefined : SearchPageAttributeFromJSON(json['priceFrom']),
        'priceTo': !exists(json, 'priceTo') ? undefined : SearchPageAttributeFromJSON(json['priceTo']),
        'sort': !exists(json, 'sort') ? undefined : SearchPageAttributeFromJSON(json['sort']),
        'radius': !exists(json, 'radius') ? undefined : SearchPageAttributeFromJSON(json['radius']),
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'searchQuery': !exists(json, 'searchQuery') ? undefined : json['searchQuery'],
        'dealerId': !exists(json, 'dealerId') ? undefined : SearchPageAttributeFromJSON(json['dealerId']),
        'canonicalUrl': !exists(json, 'canonicalUrl') ? undefined : json['canonicalUrl'],
        'pricePerMonthFrom': !exists(json, 'pricePerMonthFrom') ? undefined : SearchPageAttributeFromJSON(json['pricePerMonthFrom']),
        'pricePerMonthTo': !exists(json, 'pricePerMonthTo') ? undefined : SearchPageAttributeFromJSON(json['pricePerMonthTo']),
        'kmPerLiterFrom': !exists(json, 'kmPerLiterFrom') ? undefined : SearchPageAttributeFromJSON(json['kmPerLiterFrom']),
        'kmPerLiterTo': !exists(json, 'kmPerLiterTo') ? undefined : SearchPageAttributeFromJSON(json['kmPerLiterTo']),
        'seatNumber': !exists(json, 'seatNumber') ? undefined : SearchPageAttributeFromJSON(json['seatNumber']),
        'limitSearch': !exists(json, 'limitSearch') ? undefined : (json['limitSearch'] === null ? null : (json['limitSearch'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'onlineSince': !exists(json, 'onlineSince') ? undefined : SearchPageAttributeFromJSON(json['onlineSince']),
        'dateOfLastSearch': !exists(json, 'dateOfLastSearch') ? undefined : (json['dateOfLastSearch'] === null ? null : new Date(json['dateOfLastSearch'])),
        'limitSeatsAndDoors': !exists(json, 'limitSeatsAndDoors') ? undefined : (json['limitSeatsAndDoors'] === null ? null : (json['limitSeatsAndDoors'] as Array<any>).map(SearchPageAttributeFromJSON)),
    };
}

export function SearchPageOptionsToJSON(value?: SearchPageOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profileType': SearchPageAttributeToJSON(value.profileType),
        'doorCount': SearchPageAttributeToJSON(value.doorCount),
        'category': SearchPageAttributeToJSON(value.category),
        'makeModelVariantOptions': value.makeModelVariantOptions === undefined ? undefined : (value.makeModelVariantOptions === null ? null : (value.makeModelVariantOptions as Array<any>).map(SearchPageMakeModelVariantOptionToJSON)),
        'fuelTypes': value.fuelTypes === undefined ? undefined : (value.fuelTypes === null ? null : (value.fuelTypes as Array<any>).map(SearchPageAttributeToJSON)),
        'carTypes': value.carTypes === undefined ? undefined : (value.carTypes === null ? null : (value.carTypes as Array<any>).map(SearchPageAttributeToJSON)),
        'area': SearchPageAttributeToJSON(value.area),
        'city': SearchPageAttributeToJSON(value.city),
        'yearFrom': SearchPageAttributeToJSON(value.yearFrom),
        'yearTo': SearchPageAttributeToJSON(value.yearTo),
        'milageFrom': SearchPageAttributeToJSON(value.milageFrom),
        'milageTo': SearchPageAttributeToJSON(value.milageTo),
        'horsepowerTo': SearchPageAttributeToJSON(value.horsepowerTo),
        'horsepowerFrom': SearchPageAttributeToJSON(value.horsepowerFrom),
        'batteryCapacityTo': SearchPageAttributeToJSON(value.batteryCapacityTo),
        'batteryCapacityFrom': SearchPageAttributeToJSON(value.batteryCapacityFrom),
        'distanceTo': SearchPageAttributeToJSON(value.distanceTo),
        'distanceFrom': SearchPageAttributeToJSON(value.distanceFrom),
        'colors': value.colors === undefined ? undefined : (value.colors === null ? null : (value.colors as Array<any>).map(SearchPageAttributeToJSON)),
        'gearBoxTypes': value.gearBoxTypes === undefined ? undefined : (value.gearBoxTypes === null ? null : (value.gearBoxTypes as Array<any>).map(SearchPageAttributeToJSON)),
        'equipment': value.equipment === undefined ? undefined : (value.equipment === null ? null : (value.equipment as Array<any>).map(SearchPageAttributeToJSON)),
        'leasingType': value.leasingType === undefined ? undefined : (value.leasingType === null ? null : (value.leasingType as Array<any>).map(SearchPageAttributeToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'priceFrom': SearchPageAttributeToJSON(value.priceFrom),
        'priceTo': SearchPageAttributeToJSON(value.priceTo),
        'sort': SearchPageAttributeToJSON(value.sort),
        'radius': SearchPageAttributeToJSON(value.radius),
        'zipCode': value.zipCode,
        'searchQuery': value.searchQuery,
        'dealerId': SearchPageAttributeToJSON(value.dealerId),
        'canonicalUrl': value.canonicalUrl,
        'pricePerMonthFrom': SearchPageAttributeToJSON(value.pricePerMonthFrom),
        'pricePerMonthTo': SearchPageAttributeToJSON(value.pricePerMonthTo),
        'kmPerLiterFrom': SearchPageAttributeToJSON(value.kmPerLiterFrom),
        'kmPerLiterTo': SearchPageAttributeToJSON(value.kmPerLiterTo),
        'seatNumber': SearchPageAttributeToJSON(value.seatNumber),
        'limitSearch': value.limitSearch === undefined ? undefined : (value.limitSearch === null ? null : (value.limitSearch as Array<any>).map(SearchPageAttributeToJSON)),
        'onlineSince': SearchPageAttributeToJSON(value.onlineSince),
        'dateOfLastSearch': value.dateOfLastSearch === undefined ? undefined : (value.dateOfLastSearch === null ? null : value.dateOfLastSearch.toISOString()),
        'limitSeatsAndDoors': value.limitSeatsAndDoors === undefined ? undefined : (value.limitSeatsAndDoors === null ? null : (value.limitSeatsAndDoors as Array<any>).map(SearchPageAttributeToJSON)),
    };
}

