/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YearsWithoutIncidents,
    YearsWithoutIncidentsFromJSON,
    YearsWithoutIncidentsFromJSONTyped,
    YearsWithoutIncidentsToJSON,
} from './YearsWithoutIncidents';

/**
 * 
 * @export
 * @interface DriverInformationData
 */
export interface DriverInformationData {
    /**
     * 
     * @type {YearsWithoutIncidents}
     * @memberof DriverInformationData
     */
    yearsWithoutIncidents?: YearsWithoutIncidents;
    /**
     * 
     * @type {number}
     * @memberof DriverInformationData
     */
    drivingPerYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DriverInformationData
     */
    licensePlate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DriverInformationData
     */
    mileage?: number | null;
}

export function DriverInformationDataFromJSON(json: any): DriverInformationData {
    return DriverInformationDataFromJSONTyped(json, false);
}

export function DriverInformationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverInformationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yearsWithoutIncidents': !exists(json, 'yearsWithoutIncidents') ? undefined : YearsWithoutIncidentsFromJSON(json['yearsWithoutIncidents']),
        'drivingPerYear': !exists(json, 'drivingPerYear') ? undefined : json['drivingPerYear'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
    };
}

export function DriverInformationDataToJSON(value?: DriverInformationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yearsWithoutIncidents': YearsWithoutIncidentsToJSON(value.yearsWithoutIncidents),
        'drivingPerYear': value.drivingPerYear,
        'licensePlate': value.licensePlate,
        'mileage': value.mileage,
    };
}

