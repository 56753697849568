/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeadProductStatus,
    LeadProductStatusFromJSON,
    LeadProductStatusFromJSONTyped,
    LeadProductStatusToJSON,
} from './LeadProductStatus';

/**
 * 
 * @export
 * @interface Dealer
 */
export interface Dealer {
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Dealer
     */
    longitude?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    isLead?: boolean;
    /**
     * 
     * @type {LeadProductStatus}
     * @memberof Dealer
     */
    leadProductStatus?: LeadProductStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    isDBFUMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    isAutoTjekMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dealer
     */
    hasActiveClassifieds?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dealer
     */
    countryArea?: string | null;
}

export function DealerFromJSON(json: any): Dealer {
    return DealerFromJSONTyped(json, false);
}

export function DealerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dealer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'isLead': !exists(json, 'isLead') ? undefined : json['isLead'],
        'leadProductStatus': !exists(json, 'leadProductStatus') ? undefined : LeadProductStatusFromJSON(json['leadProductStatus']),
        'isDBFUMember': !exists(json, 'isDBFUMember') ? undefined : json['isDBFUMember'],
        'isAutoTjekMember': !exists(json, 'isAutoTjekMember') ? undefined : json['isAutoTjekMember'],
        'hasActiveClassifieds': !exists(json, 'hasActiveClassifieds') ? undefined : json['hasActiveClassifieds'],
        'countryArea': !exists(json, 'countryArea') ? undefined : json['countryArea'],
    };
}

export function DealerToJSON(value?: Dealer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'zipCode': value.zipCode,
        'city': value.city,
        'phone': value.phone,
        'website': value.website,
        'imageUrl': value.imageUrl,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'isLead': value.isLead,
        'leadProductStatus': LeadProductStatusToJSON(value.leadProductStatus),
        'isDBFUMember': value.isDBFUMember,
        'isAutoTjekMember': value.isAutoTjekMember,
        'hasActiveClassifieds': value.hasActiveClassifieds,
        'countryArea': value.countryArea,
    };
}

