/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionRequest,
    ActionRequestFromJSON,
    ActionRequestToJSON,
    ActiveResponse,
    ActiveResponseFromJSON,
    ActiveResponseToJSON,
    MessageRequest,
    MessageRequestFromJSON,
    MessageRequestToJSON,
    MessageResponse,
    MessageResponseFromJSON,
    MessageResponseToJSON,
} from '../models';

export interface ApiChatbotExecuteActionPostRequest {
    actionRequest?: ActionRequest;
}

export interface ApiChatbotSendMessagePostRequest {
    messageRequest?: MessageRequest;
}

/**
 * 
 */
export class ChatbotApi extends runtime.BaseAPI {

    /**
     */
    async apiChatbotExecuteActionPostRaw(requestParameters: ApiChatbotExecuteActionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/chatbot/execute-action`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActionRequestToJSON(requestParameters.actionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiChatbotExecuteActionPost(requestParameters: ApiChatbotExecuteActionPostRequest = {}, initOverrides?: RequestInit): Promise<MessageResponse> {
        const response = await this.apiChatbotExecuteActionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChatbotIsactiveGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActiveResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/chatbot/isactive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiChatbotIsactiveGet(initOverrides?: RequestInit): Promise<ActiveResponse> {
        const response = await this.apiChatbotIsactiveGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChatbotSendMessagePostRaw(requestParameters: ApiChatbotSendMessagePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/chatbot/send-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageRequestToJSON(requestParameters.messageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiChatbotSendMessagePost(requestParameters: ApiChatbotSendMessagePostRequest = {}, initOverrides?: RequestInit): Promise<MessageResponse> {
        const response = await this.apiChatbotSendMessagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
