/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface ActionRequest
 */
export interface ActionRequest {
    /**
     * 
     * @type {Action}
     * @memberof ActionRequest
     */
    action?: Action;
    /**
     * 
     * @type {string}
     * @memberof ActionRequest
     */
    threadId?: string | null;
}

export function ActionRequestFromJSON(json: any): ActionRequest {
    return ActionRequestFromJSONTyped(json, false);
}

export function ActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : ActionFromJSON(json['action']),
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
    };
}

export function ActionRequestToJSON(value?: ActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
        'threadId': value.threadId,
    };
}

