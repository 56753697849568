import { SearchPageOptions, SeoUrl } from '@/shared/lib-api';
import React from 'react';

export const GetMetaDescription = (
  options: SearchPageOptions,
  seoOverride: SeoUrl,
) => {
  return (
    <meta
      key="meta-description"
      name="description"
      content={GetDescription(seoOverride)}
    />
  );
};

export const GetOgDescription = (
  options: SearchPageOptions,
  seoOverride: SeoUrl,
) => {
  return (
    <meta
      key="og:meta-description"
      property="og:description"
      content={GetDescription(seoOverride)}
    />
  );
};

const GetDescription = (seoOverride: SeoUrl) => {
  if (seoOverride && seoOverride.metaDescription) {
    return seoOverride.metaDescription;
  }
  return GetDescriptionBase();
};

export const GetDescriptionBase = () => {
  return 'Find et kæmpe udvalg af brugte biler til salg på Bilhandel ✓Vælg mellem billige biler fra både forhandlere og private ✓Gratis oprettelse af annoncer.';
};
