/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DealerAdditionalDataDto
 */
export interface DealerAdditionalDataDto {
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    inventoryLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    ownerName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    amountBought?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    typeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    mailHutSpot?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    dealerStatus?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    isCustomer?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    phoneNumberOverride?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    canAddPreBooking?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    cvr?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    leadProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    newPostMaster?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    clickProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    fixedPriceProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    searchAgentProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    searchAgentSetupError?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    clickProductSetupError?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    postmasterSetupError?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    searchAgentPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    clickPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    amountOfClicks?: number;
    /**
     * 
     * @type {number}
     * @memberof DealerAdditionalDataDto
     */
    postmasterPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    invoiceEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    isDBFUMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    isAutoTjekMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    isOpenNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    offersToyotaRelax?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    offersAutoConcept?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    useAutoconceptOnAllVehicles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    offersFragus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    useFragusOnAllVehicles?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerSantander?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerClever?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerOK?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerFragus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerCarGarantie?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    partnerCodan?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    testDriveEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    testDriveAsPrimaryCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    testDriveAsCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    writeEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    writeAsPrimaryCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    writeAsCTA?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealerAdditionalDataDto
     */
    writeCTAButtonText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    callEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    callAsPrimaryCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    callAsCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    leasingOfferEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    leasingOfferAsPrimaryCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    leasingOfferAsCTA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealerAdditionalDataDto
     */
    tradeEnabled?: boolean;
}

export function DealerAdditionalDataDtoFromJSON(json: any): DealerAdditionalDataDto {
    return DealerAdditionalDataDtoFromJSONTyped(json, false);
}

export function DealerAdditionalDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealerAdditionalDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryLink': !exists(json, 'inventoryLink') ? undefined : json['inventoryLink'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'amountBought': !exists(json, 'amountBought') ? undefined : json['amountBought'],
        'typeName': !exists(json, 'typeName') ? undefined : json['typeName'],
        'mailHutSpot': !exists(json, 'mailHutSpot') ? undefined : json['mailHutSpot'],
        'dealerStatus': !exists(json, 'dealerStatus') ? undefined : json['dealerStatus'],
        'isCustomer': !exists(json, 'isCustomer') ? undefined : json['isCustomer'],
        'phoneNumberOverride': !exists(json, 'phoneNumberOverride') ? undefined : json['phoneNumberOverride'],
        'canAddPreBooking': !exists(json, 'canAddPreBooking') ? undefined : json['canAddPreBooking'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'leadProduct': !exists(json, 'leadProduct') ? undefined : json['leadProduct'],
        'newPostMaster': !exists(json, 'newPostMaster') ? undefined : json['newPostMaster'],
        'clickProduct': !exists(json, 'clickProduct') ? undefined : json['clickProduct'],
        'fixedPriceProduct': !exists(json, 'fixedPriceProduct') ? undefined : json['fixedPriceProduct'],
        'searchAgentProduct': !exists(json, 'searchAgentProduct') ? undefined : json['searchAgentProduct'],
        'searchAgentSetupError': !exists(json, 'searchAgentSetupError') ? undefined : json['searchAgentSetupError'],
        'clickProductSetupError': !exists(json, 'clickProductSetupError') ? undefined : json['clickProductSetupError'],
        'postmasterSetupError': !exists(json, 'postmasterSetupError') ? undefined : json['postmasterSetupError'],
        'searchAgentPrice': !exists(json, 'searchAgentPrice') ? undefined : json['searchAgentPrice'],
        'clickPrice': !exists(json, 'clickPrice') ? undefined : json['clickPrice'],
        'amountOfClicks': !exists(json, 'amountOfClicks') ? undefined : json['amountOfClicks'],
        'postmasterPrice': !exists(json, 'postmasterPrice') ? undefined : json['postmasterPrice'],
        'invoiceEmail': !exists(json, 'invoiceEmail') ? undefined : json['invoiceEmail'],
        'isDBFUMember': !exists(json, 'isDBFUMember') ? undefined : json['isDBFUMember'],
        'isAutoTjekMember': !exists(json, 'isAutoTjekMember') ? undefined : json['isAutoTjekMember'],
        'isOpenNow': !exists(json, 'isOpenNow') ? undefined : json['isOpenNow'],
        'offersToyotaRelax': !exists(json, 'offersToyotaRelax') ? undefined : json['offersToyotaRelax'],
        'offersAutoConcept': !exists(json, 'offersAutoConcept') ? undefined : json['offersAutoConcept'],
        'useAutoconceptOnAllVehicles': !exists(json, 'useAutoconceptOnAllVehicles') ? undefined : json['useAutoconceptOnAllVehicles'],
        'offersFragus': !exists(json, 'offersFragus') ? undefined : json['offersFragus'],
        'useFragusOnAllVehicles': !exists(json, 'useFragusOnAllVehicles') ? undefined : json['useFragusOnAllVehicles'],
        'partnerSantander': !exists(json, 'partnerSantander') ? undefined : json['partnerSantander'],
        'partnerClever': !exists(json, 'partnerClever') ? undefined : json['partnerClever'],
        'partnerOK': !exists(json, 'partnerOK') ? undefined : json['partnerOK'],
        'partnerFragus': !exists(json, 'partnerFragus') ? undefined : json['partnerFragus'],
        'partnerCarGarantie': !exists(json, 'partnerCarGarantie') ? undefined : json['partnerCarGarantie'],
        'partnerCodan': !exists(json, 'partnerCodan') ? undefined : json['partnerCodan'],
        'testDriveEnabled': !exists(json, 'testDriveEnabled') ? undefined : json['testDriveEnabled'],
        'testDriveAsPrimaryCTA': !exists(json, 'testDriveAsPrimaryCTA') ? undefined : json['testDriveAsPrimaryCTA'],
        'testDriveAsCTA': !exists(json, 'testDriveAsCTA') ? undefined : json['testDriveAsCTA'],
        'writeEnabled': !exists(json, 'writeEnabled') ? undefined : json['writeEnabled'],
        'writeAsPrimaryCTA': !exists(json, 'writeAsPrimaryCTA') ? undefined : json['writeAsPrimaryCTA'],
        'writeAsCTA': !exists(json, 'writeAsCTA') ? undefined : json['writeAsCTA'],
        'writeCTAButtonText': !exists(json, 'writeCTAButtonText') ? undefined : json['writeCTAButtonText'],
        'callEnabled': !exists(json, 'callEnabled') ? undefined : json['callEnabled'],
        'callAsPrimaryCTA': !exists(json, 'callAsPrimaryCTA') ? undefined : json['callAsPrimaryCTA'],
        'callAsCTA': !exists(json, 'callAsCTA') ? undefined : json['callAsCTA'],
        'leasingOfferEnabled': !exists(json, 'leasingOfferEnabled') ? undefined : json['leasingOfferEnabled'],
        'leasingOfferAsPrimaryCTA': !exists(json, 'leasingOfferAsPrimaryCTA') ? undefined : json['leasingOfferAsPrimaryCTA'],
        'leasingOfferAsCTA': !exists(json, 'leasingOfferAsCTA') ? undefined : json['leasingOfferAsCTA'],
        'tradeEnabled': !exists(json, 'tradeEnabled') ? undefined : json['tradeEnabled'],
    };
}

export function DealerAdditionalDataDtoToJSON(value?: DealerAdditionalDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryLink': value.inventoryLink,
        'ownerName': value.ownerName,
        'amountBought': value.amountBought,
        'typeName': value.typeName,
        'mailHutSpot': value.mailHutSpot,
        'dealerStatus': value.dealerStatus,
        'isCustomer': value.isCustomer,
        'phoneNumberOverride': value.phoneNumberOverride,
        'canAddPreBooking': value.canAddPreBooking,
        'cvr': value.cvr,
        'leadProduct': value.leadProduct,
        'newPostMaster': value.newPostMaster,
        'clickProduct': value.clickProduct,
        'fixedPriceProduct': value.fixedPriceProduct,
        'searchAgentProduct': value.searchAgentProduct,
        'searchAgentSetupError': value.searchAgentSetupError,
        'clickProductSetupError': value.clickProductSetupError,
        'postmasterSetupError': value.postmasterSetupError,
        'searchAgentPrice': value.searchAgentPrice,
        'clickPrice': value.clickPrice,
        'amountOfClicks': value.amountOfClicks,
        'postmasterPrice': value.postmasterPrice,
        'invoiceEmail': value.invoiceEmail,
        'isDBFUMember': value.isDBFUMember,
        'isAutoTjekMember': value.isAutoTjekMember,
        'isOpenNow': value.isOpenNow,
        'offersToyotaRelax': value.offersToyotaRelax,
        'offersAutoConcept': value.offersAutoConcept,
        'useAutoconceptOnAllVehicles': value.useAutoconceptOnAllVehicles,
        'offersFragus': value.offersFragus,
        'useFragusOnAllVehicles': value.useFragusOnAllVehicles,
        'partnerSantander': value.partnerSantander,
        'partnerClever': value.partnerClever,
        'partnerOK': value.partnerOK,
        'partnerFragus': value.partnerFragus,
        'partnerCarGarantie': value.partnerCarGarantie,
        'partnerCodan': value.partnerCodan,
        'testDriveEnabled': value.testDriveEnabled,
        'testDriveAsPrimaryCTA': value.testDriveAsPrimaryCTA,
        'testDriveAsCTA': value.testDriveAsCTA,
        'writeEnabled': value.writeEnabled,
        'writeAsPrimaryCTA': value.writeAsPrimaryCTA,
        'writeAsCTA': value.writeAsCTA,
        'writeCTAButtonText': value.writeCTAButtonText,
        'callEnabled': value.callEnabled,
        'callAsPrimaryCTA': value.callAsPrimaryCTA,
        'callAsCTA': value.callAsCTA,
        'leasingOfferEnabled': value.leasingOfferEnabled,
        'leasingOfferAsPrimaryCTA': value.leasingOfferAsPrimaryCTA,
        'leasingOfferAsCTA': value.leasingOfferAsCTA,
        'tradeEnabled': value.tradeEnabled,
    };
}

