/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchResultExample
 */
export interface SearchResultExample {
    /**
     * 
     * @type {string}
     * @memberof SearchResultExample
     */
    formName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResultExample
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResultExample
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResultExample
     */
    count?: number;
}

export function SearchResultExampleFromJSON(json: any): SearchResultExample {
    return SearchResultExampleFromJSONTyped(json, false);
}

export function SearchResultExampleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultExample {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formName': !exists(json, 'formName') ? undefined : json['formName'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SearchResultExampleToJSON(value?: SearchResultExample | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formName': value.formName,
        'value': value.value,
        'label': value.label,
        'count': value.count,
    };
}

