import { IconButton, styled } from '@mui/material';
import Image from '@/web/views/components/image/image';
import React from 'react';

interface PropsFromParent {
  onClick: () => void;
  width: number;
  height: number;
  src: string;
}

export function TouchableOpacity(props: PropsFromParent) {
  return (
    <TouchableOpacityNoPadding onClick={props.onClick}>
      <Image
        loading="lazy"
        alt="image"
        src={props.src}
        width={props.width}
        height={props.height}
      />
    </TouchableOpacityNoPadding>
  );
}

const TouchableOpacityNoPadding = styled(IconButton)({
  padding: '0px !important',
  borderRadius: 0,
});
