/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClassifiedCreationAttachment,
    ClassifiedCreationAttachmentFromJSON,
    ClassifiedCreationAttachmentToJSON,
    ClassifiedCreationInitiation,
    ClassifiedCreationInitiationFromJSON,
    ClassifiedCreationInitiationToJSON,
    ClassifiedCreationOptions,
    ClassifiedCreationOptionsFromJSON,
    ClassifiedCreationOptionsToJSON,
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemToJSON,
} from '../models';

export interface ApiUploadAttachmentsPostRequest {
    files?: Array<Blob>;
}

export interface ApiUploadEditGetRequest {
    classifiedId?: number;
}

export interface ApiUploadEditPostRequest {
    classifiedCreationOptions?: ClassifiedCreationOptions;
}

export interface ApiUploadLicenceplatePostRequest {
    cat?: string;
    licencePlate?: string;
}

export interface ApiUploadLicenceplateextendedPostRequest {
    cat?: string;
    licencePlate?: string;
}

export interface ApiUploadRotateattachmentPostRequest {
    attachmentId?: number;
}

/**
 * 
 */
export class ClassifiedCreationApi extends runtime.BaseAPI {

    /**
     */
    async apiUploadAttachmentsPostRaw(requestParameters: ApiUploadAttachmentsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedCreationAttachment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/upload/attachments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedCreationAttachmentFromJSON));
    }

    /**
     */
    async apiUploadAttachmentsPost(requestParameters: ApiUploadAttachmentsPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassifiedCreationAttachment>> {
        const response = await this.apiUploadAttachmentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUploadEditGetRaw(requestParameters: ApiUploadEditGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedCreationInitiation>> {
        const queryParameters: any = {};

        if (requestParameters.classifiedId !== undefined) {
            queryParameters['classifiedId'] = requestParameters.classifiedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/upload/edit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedCreationInitiationFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadEditGet(requestParameters: ApiUploadEditGetRequest = {}, initOverrides?: RequestInit): Promise<ClassifiedCreationInitiation> {
        const response = await this.apiUploadEditGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUploadEditPostRaw(requestParameters: ApiUploadEditPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/upload/edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassifiedCreationOptionsToJSON(requestParameters.classifiedCreationOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedItemFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadEditPost(requestParameters: ApiUploadEditPostRequest = {}, initOverrides?: RequestInit): Promise<ClassifiedItem> {
        const response = await this.apiUploadEditPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUploadLicenceplatePostRaw(requestParameters: ApiUploadLicenceplatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedCreationOptions>> {
        const queryParameters: any = {};

        if (requestParameters.cat !== undefined) {
            queryParameters['cat'] = requestParameters.cat;
        }

        if (requestParameters.licencePlate !== undefined) {
            queryParameters['licencePlate'] = requestParameters.licencePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/upload/licenceplate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedCreationOptionsFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadLicenceplatePost(requestParameters: ApiUploadLicenceplatePostRequest = {}, initOverrides?: RequestInit): Promise<ClassifiedCreationOptions> {
        const response = await this.apiUploadLicenceplatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUploadLicenceplateextendedPostRaw(requestParameters: ApiUploadLicenceplateextendedPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedCreationOptions>> {
        const queryParameters: any = {};

        if (requestParameters.cat !== undefined) {
            queryParameters['cat'] = requestParameters.cat;
        }

        if (requestParameters.licencePlate !== undefined) {
            queryParameters['licencePlate'] = requestParameters.licencePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/upload/licenceplateextended`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedCreationOptionsFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadLicenceplateextendedPost(requestParameters: ApiUploadLicenceplateextendedPostRequest = {}, initOverrides?: RequestInit): Promise<ClassifiedCreationOptions> {
        const response = await this.apiUploadLicenceplateextendedPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUploadRotateattachmentPostRaw(requestParameters: ApiUploadRotateattachmentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedCreationAttachment>> {
        const queryParameters: any = {};

        if (requestParameters.attachmentId !== undefined) {
            queryParameters['attachmentId'] = requestParameters.attachmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/upload/rotateattachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedCreationAttachmentFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadRotateattachmentPost(requestParameters: ApiUploadRotateattachmentPostRequest = {}, initOverrides?: RequestInit): Promise<ClassifiedCreationAttachment> {
        const response = await this.apiUploadRotateattachmentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
