/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SeoBlockType {
    WithAppleCarPlay = 'WithAppleCarPlay',
    LargestSavings = 'LargestSavings',
    LowestMileage = 'LowestMileage',
    WithTrailerHitch = 'WithTrailerHitch',
    NewestForsale = 'NewestForsale',
    LowestFuelConsumption = 'LowestFuelConsumption',
    WithExtendedWaranty = 'WithExtendedWaranty',
    YoungestVehicles = 'YoungestVehicles',
    WithAndroidAuto = 'WithAndroidAuto',
    BestLeasingOffers = 'BestLeasingOffers',
    HybridVehicles = 'HybridVehicles',
    LargestBatterySize = 'LargestBatterySize',
    DefaultResult = 'DefaultResult',
    LessThan100 = 'LessThan100',
    LargestRange = 'LargestRange',
    StationCars = 'StationCars',
    ElectricCars = 'ElectricCars',
    LessThan50 = 'LessThan50'
}

export function SeoBlockTypeFromJSON(json: any): SeoBlockType {
    return SeoBlockTypeFromJSONTyped(json, false);
}

export function SeoBlockTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoBlockType {
    return json as SeoBlockType;
}

export function SeoBlockTypeToJSON(value?: SeoBlockType | null): any {
    return value as any;
}

