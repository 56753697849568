/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DealerWithClassifieds,
    DealerWithClassifiedsFromJSON,
    DealerWithClassifiedsFromJSONTyped,
    DealerWithClassifiedsToJSON,
} from './DealerWithClassifieds';

/**
 * 
 * @export
 * @interface CvrDealerResponse
 */
export interface CvrDealerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CvrDealerResponse
     */
    isDealer?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CvrDealerResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CvrDealerResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CvrDealerResponse
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CvrDealerResponse
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CvrDealerResponse
     */
    city?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CvrDealerResponse
     */
    aliases?: Array<string> | null;
    /**
     * 
     * @type {Array<DealerWithClassifieds>}
     * @memberof CvrDealerResponse
     */
    otherDealers?: Array<DealerWithClassifieds> | null;
}

export function CvrDealerResponseFromJSON(json: any): CvrDealerResponse {
    return CvrDealerResponseFromJSONTyped(json, false);
}

export function CvrDealerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CvrDealerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isDealer': !exists(json, 'isDealer') ? undefined : json['isDealer'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'otherDealers': !exists(json, 'otherDealers') ? undefined : (json['otherDealers'] === null ? null : (json['otherDealers'] as Array<any>).map(DealerWithClassifiedsFromJSON)),
    };
}

export function CvrDealerResponseToJSON(value?: CvrDealerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isDealer': value.isDealer,
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'zipCode': value.zipCode,
        'city': value.city,
        'aliases': value.aliases,
        'otherDealers': value.otherDealers === undefined ? undefined : (value.otherDealers === null ? null : (value.otherDealers as Array<any>).map(DealerWithClassifiedsToJSON)),
    };
}

