/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedItemAttachment,
    ClassifiedItemAttachmentFromJSON,
    ClassifiedItemAttachmentFromJSONTyped,
    ClassifiedItemAttachmentToJSON,
} from './ClassifiedItemAttachment';

/**
 * 
 * @export
 * @interface ClassifiedDisplayItem
 */
export interface ClassifiedDisplayItem {
    /**
     * 
     * @type {string}
     * @memberof ClassifiedDisplayItem
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedDisplayItem
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedDisplayItem
     */
    model?: string | null;
    /**
     * 
     * @type {ClassifiedItemAttachment}
     * @memberof ClassifiedDisplayItem
     */
    image?: ClassifiedItemAttachment;
}

export function ClassifiedDisplayItemFromJSON(json: any): ClassifiedDisplayItem {
    return ClassifiedDisplayItemFromJSONTyped(json, false);
}

export function ClassifiedDisplayItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedDisplayItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'image': !exists(json, 'image') ? undefined : ClassifiedItemAttachmentFromJSON(json['image']),
    };
}

export function ClassifiedDisplayItemToJSON(value?: ClassifiedDisplayItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'make': value.make,
        'model': value.model,
        'image': ClassifiedItemAttachmentToJSON(value.image),
    };
}

