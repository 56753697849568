import { SearchPageOptions, SearchResult, SeoUrl } from '@/shared/lib-api';
import { thousandNumberSeperator } from '@/shared/util/general-helpers';
import React from 'react';

export const GetH1 = (
  searchResult: SearchResult,
  options: SearchPageOptions,
) => {
  return `${GetH1WithAmount(searchResult, options)} ${GetAreaOrCityText(
    options,
  )}`;
};

export const GetMetaTitle = (
  options: SearchPageOptions,
  totalResults: number,
  seoOverride: SeoUrl,
) => {
  return (
    <title key="title">{GetTitle(options, totalResults, seoOverride)}</title>
  );
};

export const GetOgTitle = (
  options: SearchPageOptions,
  totalResults: number,
  seoOverride: SeoUrl,
) => {
  return (
    <meta
      key="og:title"
      property="og:title"
      content={GetTitle(options, totalResults, seoOverride)}
    />
  );
};

const GetTitle = (
  options: SearchPageOptions,
  totalResults: number,
  seoOverride: SeoUrl,
) => {
  if (seoOverride && seoOverride.title) {
    return seoOverride.title;
  }
  return GetTitleBase();
  return `⬦${GetTitleBase()} ${GetAreaOrCityText(
    options,
  )}- Se alle til salg [${GetImportantProp(options)}: ${thousandNumberSeperator(
    totalResults,
  )} ${options.category.label !== '' ? options.category.label : 'Alle biler'}]`;
};

const GetImportantProp = (options: SearchPageOptions) => {
  if (options.city !== undefined && options.city.value !== '') {
    return options.city.label;
  }
  if (options.area !== undefined && options.area.value !== '') {
    return options.area.label;
  }
  if (options.fuelTypes !== undefined && options.fuelTypes.length !== 0) {
    return options.fuelTypes[0].label;
  }
  if (options.carTypes !== undefined && options.carTypes.length !== 0) {
    return options.carTypes[0].label;
  }
  if (options.makeModelVariantOptions[0].brand.value !== '') {
    return options.makeModelVariantOptions[0].brand.label;
  }
  return '';
};

const GetAreaOrCityText = (options: SearchPageOptions) => {
  const onPrePos = ['fyn', 'sydsjaelland-og-oeer', 'bornholm'];
  if (options.city !== undefined && options.city.value !== '') {
    return `i ${options.city.label} `;
  }
  if (options.area !== undefined && options.area.value !== '') {
    return (
      (onPrePos.includes(options.area.value) ? 'på ' : 'i ') +
      `${options.area.label} `
    );
  }
  return '';
};

export const GetTitleBase = () => {
  return '⬦Køb og salg af biler [+30.000 biler til salg] | Bilhandel';
};

const GetH1WithAmount = (
  searchResult: SearchResult,
  options: SearchPageOptions,
) => {
  return GetH1Amount(searchResult) + GetH1Base(searchResult, options);
};

export const GetH1Amount = (searchResult: SearchResult) => {
  const totalResults = searchResult?.totalResults ?? 0;

  const amount = totalResults === 0 ? '0' : totalResults.toString();

  let result = `<b>${thousandNumberSeperator(amount)}</b> `;

  return result;
};

export const GetH1WithArea = (
  searchResult: SearchResult,
  options: SearchPageOptions,
) => {
  return GetH1Base(searchResult, options) + ' ' + GetAreaOrCityText(options);
};

const GetH1Base = (searchResult: SearchResult, options: SearchPageOptions) => {
  const totalResults = searchResult?.totalResults ?? 0;
  const isLeasing = options?.category?.value?.toLowerCase() === 'leasing-biler';
  const isWithOutTax =
    options.category.value.toLowerCase() === 'biler-uden-afgift';
  const isUsedOrAllCategory =
    options.category === undefined ||
    options.category.value === '' ||
    options.category.value === 'brugte-biler';
  const carOrCars = totalResults === 1 ? 'bil' : 'biler';

  let result = '';

  if (!isLeasing) {
    result += 'brugte ';
  }

  if (options.makeModelVariantOptions[0].brand.value !== '') {
    result += `${options.makeModelVariantOptions[0].brand.label} `;

    if (options.makeModelVariantOptions[0].model.value !== '') {
      result += `${options.makeModelVariantOptions[0].model.label} `;
    }
  } else {
    if (isUsedOrAllCategory) {
      result += `${carOrCars} `;
    }
  }

  if (isUsedOrAllCategory) {
  } else if (isLeasing) {
  } else if (isWithOutTax) {
    result += 'uden afgift ';
  } else {
    let category = options.category.label.toLocaleLowerCase();
    result += `${category} `;
  }

  result += isLeasing ? ' leasingtilbud' : ' til salg';

  if (totalResults === 1) {
    result = result.replace('biler', 'bil');
    result = result.replace('brugte', 'brugt');
    result = result.replace('busser', 'bus');
    result = result.replace('autocampere', 'autocamper');
  } else {
    result = result.replace('vogn', 'vogne');
  }

  result = result.replace(' inkl moms', '');
  result = result.replace(' ekskl moms', '');

  return result;
};
