import React from 'react';

export const StructuredDataOrganization = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Bilhandel.dk',
          '@id': '#bilhandeldkOrg',
          url: 'https://www.bilhandel.dk/',
          logo: {
            '@type': 'imageObject',
            url: 'https://www.bilhandel.dk/bilhandel-logo.png',
            width: '149',
            height: '45',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              email: 'support@bilhandel.dk',
              contactType: 'customer service',
            },
          ],
          sameAs: ['https://www.facebook.com/bilhandeldk/'],
        }),
      }}
    />
  );
};
