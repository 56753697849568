/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemFromJSONTyped,
    ClassifiedItemToJSON,
} from './ClassifiedItem';
import {
    SearchResultExample,
    SearchResultExampleFromJSON,
    SearchResultExampleFromJSONTyped,
    SearchResultExampleToJSON,
} from './SearchResultExample';

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {Array<ClassifiedItem>}
     * @memberof SearchResult
     */
    items?: Array<ClassifiedItem> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    totalResults?: number;
    /**
     * 
     * @type {Array<SearchResultExample>}
     * @memberof SearchResult
     */
    searchResultExamples?: Array<SearchResultExample> | null;
}

export function SearchResultFromJSON(json: any): SearchResult {
    return SearchResultFromJSONTyped(json, false);
}

export function SearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ClassifiedItemFromJSON)),
        'totalResults': !exists(json, 'totalResults') ? undefined : json['totalResults'],
        'searchResultExamples': !exists(json, 'searchResultExamples') ? undefined : (json['searchResultExamples'] === null ? null : (json['searchResultExamples'] as Array<any>).map(SearchResultExampleFromJSON)),
    };
}

export function SearchResultToJSON(value?: SearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ClassifiedItemToJSON)),
        'totalResults': value.totalResults,
        'searchResultExamples': value.searchResultExamples === undefined ? undefined : (value.searchResultExamples === null ? null : (value.searchResultExamples as Array<any>).map(SearchResultExampleToJSON)),
    };
}

