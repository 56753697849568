/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClassifiedItemFuelType {
    Diesel = 'Diesel',
    Benzin = 'Benzin',
    Electric = 'Electric',
    DieselElectric = 'DieselElectric',
    BenzinElectric = 'BenzinElectric',
    Other = 'Other'
}

export function ClassifiedItemFuelTypeFromJSON(json: any): ClassifiedItemFuelType {
    return ClassifiedItemFuelTypeFromJSONTyped(json, false);
}

export function ClassifiedItemFuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemFuelType {
    return json as ClassifiedItemFuelType;
}

export function ClassifiedItemFuelTypeToJSON(value?: ClassifiedItemFuelType | null): any {
    return value as any;
}

