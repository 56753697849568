/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedDisplayItem,
    ClassifiedDisplayItemFromJSON,
    ClassifiedDisplayItemFromJSONTyped,
    ClassifiedDisplayItemToJSON,
} from './ClassifiedDisplayItem';

/**
 * 
 * @export
 * @interface SearchDisplayResult
 */
export interface SearchDisplayResult {
    /**
     * 
     * @type {Array<ClassifiedDisplayItem>}
     * @memberof SearchDisplayResult
     */
    items?: Array<ClassifiedDisplayItem> | null;
}

export function SearchDisplayResultFromJSON(json: any): SearchDisplayResult {
    return SearchDisplayResultFromJSONTyped(json, false);
}

export function SearchDisplayResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDisplayResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ClassifiedDisplayItemFromJSON)),
    };
}

export function SearchDisplayResultToJSON(value?: SearchDisplayResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ClassifiedDisplayItemToJSON)),
    };
}

