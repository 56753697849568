/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadsAttachedToCompaniesDto
 */
export interface LeadsAttachedToCompaniesDto {
    /**
     * 
     * @type {number}
     * @memberof LeadsAttachedToCompaniesDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadsAttachedToCompaniesDto
     */
    externalLeadId?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadsAttachedToCompaniesDto
     */
    userProfileId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LeadsAttachedToCompaniesDto
     */
    createDate?: Date;
}

export function LeadsAttachedToCompaniesDtoFromJSON(json: any): LeadsAttachedToCompaniesDto {
    return LeadsAttachedToCompaniesDtoFromJSONTyped(json, false);
}

export function LeadsAttachedToCompaniesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadsAttachedToCompaniesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalLeadId': !exists(json, 'externalLeadId') ? undefined : json['externalLeadId'],
        'userProfileId': !exists(json, 'userProfileId') ? undefined : json['userProfileId'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
    };
}

export function LeadsAttachedToCompaniesDtoToJSON(value?: LeadsAttachedToCompaniesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalLeadId': value.externalLeadId,
        'userProfileId': value.userProfileId,
        'createDate': value.createDate === undefined ? undefined : (value.createDate.toISOString()),
    };
}

