/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassifiedItemStatData
 */
export interface ClassifiedItemStatData {
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemStatData
     */
    weeklyViewCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemStatData
     */
    dailyViewCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemStatData
     */
    totalViewCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemStatData
     */
    totalLikeCount?: number | null;
}

export function ClassifiedItemStatDataFromJSON(json: any): ClassifiedItemStatData {
    return ClassifiedItemStatDataFromJSONTyped(json, false);
}

export function ClassifiedItemStatDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemStatData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weeklyViewCount': !exists(json, 'weeklyViewCount') ? undefined : json['weeklyViewCount'],
        'dailyViewCount': !exists(json, 'dailyViewCount') ? undefined : json['dailyViewCount'],
        'totalViewCount': !exists(json, 'totalViewCount') ? undefined : json['totalViewCount'],
        'totalLikeCount': !exists(json, 'totalLikeCount') ? undefined : json['totalLikeCount'],
    };
}

export function ClassifiedItemStatDataToJSON(value?: ClassifiedItemStatData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weeklyViewCount': value.weeklyViewCount,
        'dailyViewCount': value.dailyViewCount,
        'totalViewCount': value.totalViewCount,
        'totalLikeCount': value.totalLikeCount,
    };
}

