import { SearchPageAttribute } from '@/shared/lib-api';

export enum LoginModalMode {
  initial,
  Create,
  Login,
  FinishProfile,
  ForgotPassword,
  ResetPassword,
  TermsAfterSocialLogin,
}

export interface LoginOrCreateValues {
  email: string;
}

export interface LoginValues {
  email: string;
  password: string;
}

export interface ForgotPasswordValues {
  email: string;
}

export interface ResetPasswordValues {
  email: string;
  token: string;
  passwordNew: string;
}

export type CreateValues = LoginValues & TermsValues;

export interface TermsValues {
  termsAccepted: boolean;
  newsAccepted?: boolean;
}

export interface FinishValues {
  name: string;
  zipCode: number;
  licensePlate: string;
  mileage: number;
  drivingPerYear: SearchPageAttribute;
  priceCheckOnInsurance?: boolean;
}
