/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YearsWithoutIncidents,
    YearsWithoutIncidentsFromJSON,
    YearsWithoutIncidentsFromJSONTyped,
    YearsWithoutIncidentsToJSON,
} from './YearsWithoutIncidents';

/**
 * 
 * @export
 * @interface UserItemDrivingData
 */
export interface UserItemDrivingData {
    /**
     * 
     * @type {number}
     * @memberof UserItemDrivingData
     */
    mileagePerYear?: number | null;
    /**
     * 
     * @type {YearsWithoutIncidents}
     * @memberof UserItemDrivingData
     */
    yearsWithoutIncidents?: YearsWithoutIncidents;
    /**
     * 
     * @type {number}
     * @memberof UserItemDrivingData
     */
    mileage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserItemDrivingData
     */
    regNr?: string | null;
}

export function UserItemDrivingDataFromJSON(json: any): UserItemDrivingData {
    return UserItemDrivingDataFromJSONTyped(json, false);
}

export function UserItemDrivingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItemDrivingData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mileagePerYear': !exists(json, 'mileagePerYear') ? undefined : json['mileagePerYear'],
        'yearsWithoutIncidents': !exists(json, 'yearsWithoutIncidents') ? undefined : YearsWithoutIncidentsFromJSON(json['yearsWithoutIncidents']),
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
    };
}

export function UserItemDrivingDataToJSON(value?: UserItemDrivingData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mileagePerYear': value.mileagePerYear,
        'yearsWithoutIncidents': YearsWithoutIncidentsToJSON(value.yearsWithoutIncidents),
        'mileage': value.mileage,
        'regNr': value.regNr,
    };
}

