/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchResult,
    SearchResultFromJSON,
    SearchResultFromJSONTyped,
    SearchResultToJSON,
} from './SearchResult';
import {
    SeoUrl,
    SeoUrlFromJSON,
    SeoUrlFromJSONTyped,
    SeoUrlToJSON,
} from './SeoUrl';

/**
 * 
 * @export
 * @interface PageOptions
 */
export interface PageOptions {
    /**
     * 
     * @type {SearchResult}
     * @memberof PageOptions
     */
    searchResult?: SearchResult;
    /**
     * 
     * @type {SeoUrl}
     * @memberof PageOptions
     */
    seoData?: SeoUrl;
}

export function PageOptionsFromJSON(json: any): PageOptions {
    return PageOptionsFromJSONTyped(json, false);
}

export function PageOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchResult': !exists(json, 'searchResult') ? undefined : SearchResultFromJSON(json['searchResult']),
        'seoData': !exists(json, 'seoData') ? undefined : SeoUrlFromJSON(json['seoData']),
    };
}

export function PageOptionsToJSON(value?: PageOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchResult': SearchResultToJSON(value.searchResult),
        'seoData': SeoUrlToJSON(value.seoData),
    };
}

