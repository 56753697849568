/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsFromJSONTyped,
    SearchPageOptionsToJSON,
} from './SearchPageOptions';

/**
 * 
 * @export
 * @interface SearchAgentItem
 */
export interface SearchAgentItem {
    /**
     * 
     * @type {number}
     * @memberof SearchAgentItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchAgentItem
     */
    name?: string | null;
    /**
     * 
     * @type {SearchPageOptions}
     * @memberof SearchAgentItem
     */
    options?: SearchPageOptions;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgentItem
     */
    createdDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAgentItem
     */
    recieveEmailsOnAgent?: boolean;
}

export function SearchAgentItemFromJSON(json: any): SearchAgentItem {
    return SearchAgentItemFromJSONTyped(json, false);
}

export function SearchAgentItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAgentItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'options': !exists(json, 'options') ? undefined : SearchPageOptionsFromJSON(json['options']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'recieveEmailsOnAgent': !exists(json, 'recieveEmailsOnAgent') ? undefined : json['recieveEmailsOnAgent'],
    };
}

export function SearchAgentItemToJSON(value?: SearchAgentItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'options': SearchPageOptionsToJSON(value.options),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'recieveEmailsOnAgent': value.recieveEmailsOnAgent,
    };
}

