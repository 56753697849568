import { Box } from '@mui/material';
import React from 'react';
import { Colors } from '../../../../shared/util/colors';
import BottomAvailableAsMobile from './bottom-available-as-mobile';
import BottomNavigationContentContainer from './bottom-navigation-content-container';

function BottomNaigationContainer() {
  return (
    <>
      <Box>
        <Box bgcolor={Colors.Background.Footer} width={'100%'}>
          <BottomNavigationContentContainer />
        </Box>
        <Box bgcolor={Colors.Background.FooterDark} width={'100%'}>
          <BottomAvailableAsMobile />
        </Box>
      </Box>
    </>
  );
}

export default BottomNaigationContainer;
