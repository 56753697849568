/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ToggleEmailSearchAgentRequest
 */
export interface ToggleEmailSearchAgentRequest {
    /**
     * 
     * @type {number}
     * @memberof ToggleEmailSearchAgentRequest
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ToggleEmailSearchAgentRequest
     */
    recieveEmailsOnAgent?: boolean;
}

export function ToggleEmailSearchAgentRequestFromJSON(json: any): ToggleEmailSearchAgentRequest {
    return ToggleEmailSearchAgentRequestFromJSONTyped(json, false);
}

export function ToggleEmailSearchAgentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ToggleEmailSearchAgentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recieveEmailsOnAgent': !exists(json, 'recieveEmailsOnAgent') ? undefined : json['recieveEmailsOnAgent'],
    };
}

export function ToggleEmailSearchAgentRequestToJSON(value?: ToggleEmailSearchAgentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recieveEmailsOnAgent': value.recieveEmailsOnAgent,
    };
}

