import { AuthApi, LoginToken, Middleware } from '../lib-api';
import { defaultApiConfiguration } from './api';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { login, logout } from '../store/reducers/AuthSlice';
import { useMemo } from 'react';

let mutex: Promise<LoginToken> | null = null;

function defer<T>() {
  var deferred: { promise: Promise<T>; resolve: (value: T) => void } = {
    promise: null!,
    resolve: null!,
  };
  deferred.promise = new Promise(resolve => {
    deferred.resolve = resolve;
  });
  return deferred;
}

const useMiddleware = () => {
  const { refreshToken, loginToken } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const middleware: Middleware = useMemo(() => {
    return {
      post: async context => {
        if (
          context.response.status === 401 &&
          context.response.headers.has('token-expired')
        ) {
          if (mutex == null) {
            let deferred = defer<LoginToken>();
            mutex = deferred.promise;

            const authApi = new AuthApi(defaultApiConfiguration);
            try {
              const refreshResponse = await authApi.apiAuthRefreshtokenPost({
                refreshTokenRequest: {
                  token: loginToken,
                  refreshToken: refreshToken,
                },
              });
              dispatch(login(refreshResponse));
              deferred.resolve(refreshResponse);
              mutex = null;
              context.init.headers = {
                ...context.init.headers,
                Authorization: `Bearer ${refreshResponse.token!}`,
              };
            } catch (e) {
              dispatch(logout());
            }
          } else {
            try {
              let refreshResponse = await mutex;
              context.init.headers = {
                ...context.init.headers,
                Authorization: `Bearer ${refreshResponse.token!}`,
              };
            } catch (e) {
              dispatch({ type: 'logout' });
            }
          }
          return await context.fetch(context.url, context.init);
        } else if (context.response.status === 403) {
          var text = await context.response.text();
        } else if (
          context.response.status === 404 ||
          context.response.status === 400
        ) {
          var text = await context.response.text();
        }
      },
    };
  }, [loginToken, refreshToken, dispatch]);

  return {
    loginToken,
    dispatch,
    middleware,
  };
};

export default useMiddleware;
