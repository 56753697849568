import React from 'react';
import linkedin from '../../../../shared/icons/facebook.svg';
import { TouchableOpacity } from '../default-components/touchableOpacity';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';

export const openFacebookBilHandel = () => {
  OpenExternalLink('https://www.facebook.com/bilhandeldk/');
};

export function ClickAbleFacebookIcon() {
  return (
    <TouchableOpacity
      onClick={openFacebookBilHandel}
      height={21}
      width={22}
      src={linkedin.src}
    />
  );
}
