/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DriverInformationData,
    DriverInformationDataFromJSON,
    DriverInformationDataFromJSONTyped,
    DriverInformationDataToJSON,
} from './DriverInformationData';

/**
 * 
 * @export
 * @interface FinishUserRequest
 */
export interface FinishUserRequest {
    /**
     * 
     * @type {string}
     * @memberof FinishUserRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FinishUserRequest
     */
    zipCode: number;
    /**
     * 
     * @type {DriverInformationData}
     * @memberof FinishUserRequest
     */
    driverInformation?: DriverInformationData;
    /**
     * 
     * @type {boolean}
     * @memberof FinishUserRequest
     */
    priceCheckOnInsurance?: boolean;
}

export function FinishUserRequestFromJSON(json: any): FinishUserRequest {
    return FinishUserRequestFromJSONTyped(json, false);
}

export function FinishUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinishUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'zipCode': json['zipCode'],
        'driverInformation': !exists(json, 'driverInformation') ? undefined : DriverInformationDataFromJSON(json['driverInformation']),
        'priceCheckOnInsurance': !exists(json, 'priceCheckOnInsurance') ? undefined : json['priceCheckOnInsurance'],
    };
}

export function FinishUserRequestToJSON(value?: FinishUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'zipCode': value.zipCode,
        'driverInformation': DriverInformationDataToJSON(value.driverInformation),
        'priceCheckOnInsurance': value.priceCheckOnInsurance,
    };
}

