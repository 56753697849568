/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldUsage {
    Search = 'Search',
    Create = 'Create'
}

export function FieldUsageFromJSON(json: any): FieldUsage {
    return FieldUsageFromJSONTyped(json, false);
}

export function FieldUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldUsage {
    return json as FieldUsage;
}

export function FieldUsageToJSON(value?: FieldUsage | null): any {
    return value as any;
}

