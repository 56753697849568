/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TodaysOpeningHoursResponse
 */
export interface TodaysOpeningHoursResponse {
    /**
     * 
     * @type {string}
     * @memberof TodaysOpeningHoursResponse
     */
    opens?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TodaysOpeningHoursResponse
     */
    closes?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TodaysOpeningHoursResponse
     */
    openWithAppointment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TodaysOpeningHoursResponse
     */
    isOpen?: boolean;
}

export function TodaysOpeningHoursResponseFromJSON(json: any): TodaysOpeningHoursResponse {
    return TodaysOpeningHoursResponseFromJSONTyped(json, false);
}

export function TodaysOpeningHoursResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TodaysOpeningHoursResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'opens': !exists(json, 'opens') ? undefined : json['opens'],
        'closes': !exists(json, 'closes') ? undefined : json['closes'],
        'openWithAppointment': !exists(json, 'openWithAppointment') ? undefined : json['openWithAppointment'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
    };
}

export function TodaysOpeningHoursResponseToJSON(value?: TodaysOpeningHoursResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'opens': value.opens,
        'closes': value.closes,
        'openWithAppointment': value.openWithAppointment,
        'isOpen': value.isOpen,
    };
}

