/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddSearchAgentRequest,
    AddSearchAgentRequestFromJSON,
    AddSearchAgentRequestToJSON,
    RemoveSearchAgentRequest,
    RemoveSearchAgentRequestFromJSON,
    RemoveSearchAgentRequestToJSON,
    SearchAgentItem,
    SearchAgentItemFromJSON,
    SearchAgentItemToJSON,
    ToggleEmailSearchAgentRequest,
    ToggleEmailSearchAgentRequestFromJSON,
    ToggleEmailSearchAgentRequestToJSON,
    UpdateSearchAgentRequest,
    UpdateSearchAgentRequestFromJSON,
    UpdateSearchAgentRequestToJSON,
} from '../models';

export interface ApiSearchagentAddsearchagentPostRequest {
    addSearchAgentRequest?: AddSearchAgentRequest;
}

export interface ApiSearchagentRemovesearchagentPostRequest {
    removeSearchAgentRequest?: RemoveSearchAgentRequest;
}

export interface ApiSearchagentToggleemailsearchagentPostRequest {
    toggleEmailSearchAgentRequest?: ToggleEmailSearchAgentRequest;
}

export interface ApiSearchagentUpdatesearchagentPostRequest {
    updateSearchAgentRequest?: UpdateSearchAgentRequest;
}

/**
 * 
 */
export class SearchAgentApi extends runtime.BaseAPI {

    /**
     */
    async apiSearchagentAddsearchagentPostRaw(requestParameters: ApiSearchagentAddsearchagentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchAgentItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/searchagent/addsearchagent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSearchAgentRequestToJSON(requestParameters.addSearchAgentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentItemFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchagentAddsearchagentPost(requestParameters: ApiSearchagentAddsearchagentPostRequest = {}, initOverrides?: RequestInit): Promise<SearchAgentItem> {
        const response = await this.apiSearchagentAddsearchagentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchagentGetsearchagentsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchAgentItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/searchagent/getsearchagents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchAgentItemFromJSON));
    }

    /**
     */
    async apiSearchagentGetsearchagentsPost(initOverrides?: RequestInit): Promise<Array<SearchAgentItem>> {
        const response = await this.apiSearchagentGetsearchagentsPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchagentRemovesearchagentPostRaw(requestParameters: ApiSearchagentRemovesearchagentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/searchagent/removesearchagent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveSearchAgentRequestToJSON(requestParameters.removeSearchAgentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSearchagentRemovesearchagentPost(requestParameters: ApiSearchagentRemovesearchagentPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiSearchagentRemovesearchagentPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSearchagentToggleemailsearchagentPostRaw(requestParameters: ApiSearchagentToggleemailsearchagentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchAgentItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/searchagent/toggleemailsearchagent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ToggleEmailSearchAgentRequestToJSON(requestParameters.toggleEmailSearchAgentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentItemFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchagentToggleemailsearchagentPost(requestParameters: ApiSearchagentToggleemailsearchagentPostRequest = {}, initOverrides?: RequestInit): Promise<SearchAgentItem> {
        const response = await this.apiSearchagentToggleemailsearchagentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSearchagentUpdatesearchagentPostRaw(requestParameters: ApiSearchagentUpdatesearchagentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchAgentItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/searchagent/updatesearchagent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSearchAgentRequestToJSON(requestParameters.updateSearchAgentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchAgentItemFromJSON(jsonValue));
    }

    /**
     */
    async apiSearchagentUpdatesearchagentPost(requestParameters: ApiSearchagentUpdatesearchagentPostRequest = {}, initOverrides?: RequestInit): Promise<SearchAgentItem> {
        const response = await this.apiSearchagentUpdatesearchagentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
