/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsFromJSONTyped,
    SearchPageOptionsToJSON,
} from './SearchPageOptions';

/**
 * 
 * @export
 * @interface AddSearchAgentRequest
 */
export interface AddSearchAgentRequest {
    /**
     * 
     * @type {SearchPageOptions}
     * @memberof AddSearchAgentRequest
     */
    options?: SearchPageOptions;
}

export function AddSearchAgentRequestFromJSON(json: any): AddSearchAgentRequest {
    return AddSearchAgentRequestFromJSONTyped(json, false);
}

export function AddSearchAgentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSearchAgentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': !exists(json, 'options') ? undefined : SearchPageOptionsFromJSON(json['options']),
    };
}

export function AddSearchAgentRequestToJSON(value?: AddSearchAgentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': SearchPageOptionsToJSON(value.options),
    };
}

