/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserItem,
    UserItemFromJSON,
    UserItemFromJSONTyped,
    UserItemToJSON,
} from './UserItem';

/**
 * 
 * @export
 * @interface LoginToken
 */
export interface LoginToken {
    /**
     * 
     * @type {UserItem}
     * @memberof LoginToken
     */
    user?: UserItem;
    /**
     * 
     * @type {string}
     * @memberof LoginToken
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginToken
     */
    refreshToken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LoginToken
     */
    expirationDate?: Date;
}

export function LoginTokenFromJSON(json: any): LoginToken {
    return LoginTokenFromJSONTyped(json, false);
}

export function LoginTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserItemFromJSON(json['user']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
    };
}

export function LoginTokenToJSON(value?: LoginToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserItemToJSON(value.user),
        'token': value.token,
        'refreshToken': value.refreshToken,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
    };
}

