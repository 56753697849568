/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FavoriteItem,
    FavoriteItemFromJSON,
    FavoriteItemFromJSONTyped,
    FavoriteItemToJSON,
} from './FavoriteItem';
import {
    SearchAgentItem,
    SearchAgentItemFromJSON,
    SearchAgentItemFromJSONTyped,
    SearchAgentItemToJSON,
} from './SearchAgentItem';
import {
    UserItemDrivingData,
    UserItemDrivingDataFromJSON,
    UserItemDrivingDataFromJSONTyped,
    UserItemDrivingDataToJSON,
} from './UserItemDrivingData';
import {
    UserItemPermissions,
    UserItemPermissionsFromJSON,
    UserItemPermissionsFromJSONTyped,
    UserItemPermissionsToJSON,
} from './UserItemPermissions';
import {
    UserItemPersonalInfo,
    UserItemPersonalInfoFromJSON,
    UserItemPersonalInfoFromJSONTyped,
    UserItemPersonalInfoToJSON,
} from './UserItemPersonalInfo';
import {
    UserProfileType,
    UserProfileTypeFromJSON,
    UserProfileTypeFromJSONTyped,
    UserProfileTypeToJSON,
} from './UserProfileType';

/**
 * 
 * @export
 * @interface UserItem
 */
export interface UserItem {
    /**
     * 
     * @type {number}
     * @memberof UserItem
     */
    id?: number;
    /**
     * 
     * @type {UserItemPersonalInfo}
     * @memberof UserItem
     */
    personalInfo?: UserItemPersonalInfo;
    /**
     * 
     * @type {Array<FavoriteItem>}
     * @memberof UserItem
     */
    favourites?: Array<FavoriteItem> | null;
    /**
     * 
     * @type {Array<SearchAgentItem>}
     * @memberof UserItem
     */
    searchAgents?: Array<SearchAgentItem> | null;
    /**
     * 
     * @type {UserItemPermissions}
     * @memberof UserItem
     */
    permissions?: UserItemPermissions;
    /**
     * 
     * @type {UserItemDrivingData}
     * @memberof UserItem
     */
    drivingData?: UserItemDrivingData;
    /**
     * 
     * @type {UserProfileType}
     * @memberof UserItem
     */
    profileType?: UserProfileType;
}

export function UserItemFromJSON(json: any): UserItem {
    return UserItemFromJSONTyped(json, false);
}

export function UserItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserItemPersonalInfoFromJSON(json['personalInfo']),
        'favourites': !exists(json, 'favourites') ? undefined : (json['favourites'] === null ? null : (json['favourites'] as Array<any>).map(FavoriteItemFromJSON)),
        'searchAgents': !exists(json, 'searchAgents') ? undefined : (json['searchAgents'] === null ? null : (json['searchAgents'] as Array<any>).map(SearchAgentItemFromJSON)),
        'permissions': !exists(json, 'permissions') ? undefined : UserItemPermissionsFromJSON(json['permissions']),
        'drivingData': !exists(json, 'drivingData') ? undefined : UserItemDrivingDataFromJSON(json['drivingData']),
        'profileType': !exists(json, 'profileType') ? undefined : UserProfileTypeFromJSON(json['profileType']),
    };
}

export function UserItemToJSON(value?: UserItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personalInfo': UserItemPersonalInfoToJSON(value.personalInfo),
        'favourites': value.favourites === undefined ? undefined : (value.favourites === null ? null : (value.favourites as Array<any>).map(FavoriteItemToJSON)),
        'searchAgents': value.searchAgents === undefined ? undefined : (value.searchAgents === null ? null : (value.searchAgents as Array<any>).map(SearchAgentItemToJSON)),
        'permissions': UserItemPermissionsToJSON(value.permissions),
        'drivingData': UserItemDrivingDataToJSON(value.drivingData),
        'profileType': UserProfileTypeToJSON(value.profileType),
    };
}

