/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModelDate,
    ModelDateFromJSON,
    ModelDateFromJSONTyped,
    ModelDateToJSON,
} from './ModelDate';

/**
 * 
 * @export
 * @interface TestDriveSlotsResponse
 */
export interface TestDriveSlotsResponse {
    /**
     * 
     * @type {Array<ModelDate>}
     * @memberof TestDriveSlotsResponse
     */
    dates?: Array<ModelDate> | null;
}

export function TestDriveSlotsResponseFromJSON(json: any): TestDriveSlotsResponse {
    return TestDriveSlotsResponseFromJSONTyped(json, false);
}

export function TestDriveSlotsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestDriveSlotsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dates': !exists(json, 'dates') ? undefined : (json['dates'] === null ? null : (json['dates'] as Array<any>).map(ModelDateFromJSON)),
    };
}

export function TestDriveSlotsResponseToJSON(value?: TestDriveSlotsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dates': value.dates === undefined ? undefined : (value.dates === null ? null : (value.dates as Array<any>).map(ModelDateToJSON)),
    };
}

