/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeoIpResponse
 */
export interface GeoIpResponse {
    /**
     * 
     * @type {string}
     * @memberof GeoIpResponse
     */
    countryIsoCode?: string | null;
}

export function GeoIpResponseFromJSON(json: any): GeoIpResponse {
    return GeoIpResponseFromJSONTyped(json, false);
}

export function GeoIpResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeoIpResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryIsoCode': !exists(json, 'countryIsoCode') ? undefined : json['countryIsoCode'],
    };
}

export function GeoIpResponseToJSON(value?: GeoIpResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryIsoCode': value.countryIsoCode,
    };
}

