import React, { FC, useEffect } from 'react';
import { useAppSelector } from '@/shared/store/hooks';
import { useAuth } from '@/shared/hooks/auth-hook';
import TagManager from 'react-gtm-module';
import useGeoLocation from '@/shared/hooks/useGeoLocation';

export const PageLoginTracking: FC<{
  children?: React.ReactNode;
  ip?: string;
}> = props => {
  const { isLoggedIn } = useAuth();
  const personalInfo = useAppSelector(
    state => state.auth.cachedUser?.personalInfo,
  );

  const { geoLocation, loading } = useGeoLocation(props.ip);

  useEffect(() => {
    if (loading) {
      return;
    }
    // refresh data layer variables
    TagManager.dataLayer({
      dataLayer: {
        dlv_logged_in_state: isLoggedIn,
        dlv_user_email: personalInfo?.email,
        dlv_user_screenname: personalInfo?.screenName,
        dlv_user_zip: personalInfo?.zipCode,
        dlv_user_city: personalInfo?.city,
        dlv_user_phone: personalInfo?.phoneNumber,
        dlv_geo_countryisocode: geoLocation?.countryIsoCode ?? '',
      },
    });
  }, [personalInfo, isLoggedIn, geoLocation, loading]);

  if (loading) {
    return <>{props.children}</>;
  }

  // temporary way of getting logged in user,
  // until login is persisted server-side and initialized 'earlier'
  if (typeof window !== 'undefined') {
    const persist_auth_json = window.localStorage['persist:auth'];
    if (persist_auth_json) {
      const cachedUser_json_json = JSON.parse(persist_auth_json).cachedUser;
      if (cachedUser_json_json) {
        const auth = JSON.parse(JSON.parse(cachedUser_json_json));
        const auth_personalInfo = auth.personalInfo;
        TagManager.dataLayer({
          dataLayer: {
            dlv_logged_in_state: true,
            dlv_user_email: auth_personalInfo?.email,
            dlv_user_screenname: auth_personalInfo?.screenName,
            dlv_user_zip: auth_personalInfo?.zipCode,
            dlv_user_city: auth_personalInfo?.city,
            dlv_user_phone: auth_personalInfo?.phoneNumber,
            dlv_geo_countryisocode: geoLocation?.countryIsoCode ?? '',
          },
        });
      }
    }
  }

  return <>{props.children}</>;
};
