/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeoIpResponse,
    GeoIpResponseFromJSON,
    GeoIpResponseToJSON,
    LatLonRequest,
    LatLonRequestFromJSON,
    LatLonRequestToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ApiGeolocationGeoipGetRequest {
    ip?: string;
}

export interface ApiGeolocationGetzipcodefromlatlongPostRequest {
    latLonRequest?: LatLonRequest;
}

/**
 * 
 */
export class GeoLocationApi extends runtime.BaseAPI {

    /**
     */
    async apiGeolocationGeoipGetRaw(requestParameters: ApiGeolocationGeoipGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GeoIpResponse>> {
        const queryParameters: any = {};

        if (requestParameters.ip !== undefined) {
            queryParameters['ip'] = requestParameters.ip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/geolocation/geoip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GeoIpResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiGeolocationGeoipGet(requestParameters: ApiGeolocationGeoipGetRequest = {}, initOverrides?: RequestInit): Promise<GeoIpResponse> {
        const response = await this.apiGeolocationGeoipGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGeolocationGetzipcodefromlatlongPostRaw(requestParameters: ApiGeolocationGetzipcodefromlatlongPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/geolocation/getzipcodefromlatlong`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LatLonRequestToJSON(requestParameters.latLonRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiGeolocationGetzipcodefromlatlongPost(requestParameters: ApiGeolocationGetzipcodefromlatlongPostRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiGeolocationGetzipcodefromlatlongPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
