import { FC } from 'react';

declare const VALID_LAYOUT_VALUES: readonly [
  'fill',
  'fixed',
  'intrinsic',
  'responsive',
  undefined,
];

declare const VALID_LOADING_VALUES: readonly ['lazy', 'eager', undefined];
declare type LoadingValue = (typeof VALID_LOADING_VALUES)[number];

declare type LayoutValue = (typeof VALID_LAYOUT_VALUES)[number];
export declare type ImageProps = Omit<
  JSX.IntrinsicElements['img'],
  'src' | 'srcSet' | 'ref' | 'width' | 'height' | 'loading'
> & {
  src: string;
  width?: number | string;
  height?: number | string;
  layout?: LayoutValue;
  // loader?: ImageLoader;
  quality?: number | string;
  priority?: boolean;
  loading?: LoadingValue;
  lazyRoot?: React.RefObject<HTMLElement> | null;
  lazyBoundary?: string;
  //placeholder?: PlaceholderValue;
  blurDataURL?: string;
  unoptimized?: boolean;
  // objectFit?: ImgElementStyle['objectFit'];
  // objectPosition?: ImgElementStyle['objectPosition'];
  // onLoadingComplete?: OnLoadingComplete;
};

const Image: FC<ImageProps> = props => {
  const { src } = props;
  // const src = `https://dummyimage.com/${props.width ?? 600}x${
  //   props.height ?? 400
  // }/b32eb1/fff`;
  return (
    <>
      <img
        src={src}
        height={props.height}
        width={props.width}
        alt={props.alt}
        title={props.title}
        className={props.className}
        id={props.id}
        style={props.style}
        onClick={props.onClick}
        loading={props.loading}
      />
    </>
  );
};

export default Image;
