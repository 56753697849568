/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetInsurancePriceResponse
 */
export interface GetInsurancePriceResponse {
    /**
     * 
     * @type {number}
     * @memberof GetInsurancePriceResponse
     */
    lowestMonhtlyPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof GetInsurancePriceResponse
     */
    lowestPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof GetInsurancePriceResponse
     */
    precision?: string | null;
}

export function GetInsurancePriceResponseFromJSON(json: any): GetInsurancePriceResponse {
    return GetInsurancePriceResponseFromJSONTyped(json, false);
}

export function GetInsurancePriceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInsurancePriceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowestMonhtlyPrice': !exists(json, 'lowest_monhtly_price') ? undefined : json['lowest_monhtly_price'],
        'lowestPrice': !exists(json, 'lowest_price') ? undefined : json['lowest_price'],
        'precision': !exists(json, 'precision') ? undefined : json['precision'],
    };
}

export function GetInsurancePriceResponseToJSON(value?: GetInsurancePriceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lowest_monhtly_price': value.lowestMonhtlyPrice,
        'lowest_price': value.lowestPrice,
        'precision': value.precision,
    };
}

