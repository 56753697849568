import { useCallback, useState } from 'react';
import { LoadingState } from '../util/general-helpers';

export type ErrorAndState = {
  error: string | undefined;
  state: LoadingState;
};

export function useRequestHelper() {
  const [requestState, setRequestState] = useState<LoadingState>(
    LoadingState.None,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const handleRequest = useCallback(async function requestHelper<K>(
    request: Promise<K>,
  ): Promise<[undefined | K, ErrorAndState]> {
    try {
      setErrorMessage(undefined);
      setRequestState(LoadingState.Loading);
      var result = await request;
      setRequestState(LoadingState.Success);
      return [result, { error: undefined, state: LoadingState.Success }];
    } catch (e) {
      var state = LoadingState.Error;
      if (e.status >= 500) {
        state = LoadingState.BadRequest;
      }
      if (e.status === 401) {
        state = LoadingState.BadRequest;
        return [undefined, { error: 'Ikke logget ind', state }];
      }
      let localErrorMessage = 'Ukendt fejl.. Prøv igen';
      try {
        var errorObject = e.json && (await e.json());
        localErrorMessage =
          errorObject?._localizedMessage ?? 'Ukendt fejl.. Prøv igen';
      } catch {}
      setRequestState(state);
      setErrorMessage(localErrorMessage);
      return [undefined, { error: localErrorMessage, state }];
    }
  },
  []);

  return {
    handleRequest,
    requestState,
    errorMessage,
  };
}
