/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FavoriteItem
 */
export interface FavoriteItem {
    /**
     * 
     * @type {number}
     * @memberof FavoriteItem
     */
    classifiedId?: number;
    /**
     * 
     * @type {Date}
     * @memberof FavoriteItem
     */
    createdDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteItem
     */
    isActive?: boolean;
}

export function FavoriteItemFromJSON(json: any): FavoriteItem {
    return FavoriteItemFromJSONTyped(json, false);
}

export function FavoriteItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classifiedId': !exists(json, 'classifiedId') ? undefined : json['classifiedId'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function FavoriteItemToJSON(value?: FavoriteItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classifiedId': value.classifiedId,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'isActive': value.isActive,
    };
}

