/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';

/**
 * 
 * @export
 * @interface SearchPageMakeModelVariantOption
 */
export interface SearchPageMakeModelVariantOption {
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageMakeModelVariantOption
     */
    brand?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof SearchPageMakeModelVariantOption
     */
    model?: SearchPageAttribute;
    /**
     * 
     * @type {string}
     * @memberof SearchPageMakeModelVariantOption
     */
    variant?: string | null;
}

export function SearchPageMakeModelVariantOptionFromJSON(json: any): SearchPageMakeModelVariantOption {
    return SearchPageMakeModelVariantOptionFromJSONTyped(json, false);
}

export function SearchPageMakeModelVariantOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPageMakeModelVariantOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': !exists(json, 'brand') ? undefined : SearchPageAttributeFromJSON(json['brand']),
        'model': !exists(json, 'model') ? undefined : SearchPageAttributeFromJSON(json['model']),
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
    };
}

export function SearchPageMakeModelVariantOptionToJSON(value?: SearchPageMakeModelVariantOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': SearchPageAttributeToJSON(value.brand),
        'model': SearchPageAttributeToJSON(value.model),
        'variant': value.variant,
    };
}

