import {
  ClassifiedItem,
  FavoriteItem,
  LoginToken,
  SearchAgentItem,
  SearchPageOptions,
  UserItem,
  UserItemPersonalInfo,
} from '@/shared/lib-api';
import { LoadingState } from '@/shared/util/general-helpers';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { decode, encode } from '../date-transform';

export interface LocationCoords {
  lat?: number;
  long?: number;
}

export interface AuthState {
  shouldLogout: boolean;
  loginToken?: string;
  refreshToken?: string;
  expirationDate?: Date;
  cachedUser?: UserItem;
  favouriteCars?: FavoriteItem[];
  favouriteCarsState: LoadingState;
  ownCars?: ClassifiedItem[];
  ownCarsState: LoadingState;
  searchAgents?: SearchAgentItem[];
  searchAgentsState: LoadingState;
  notLoggedInUserData: UserItemPersonalInfo;
  lastSearchRequest?: SearchPageOptions;
  locationCoordsFromBrowser?: LocationCoords;
}

export const initialState: AuthState = {
  shouldLogout: false,
  notLoggedInUserData: {},
  favouriteCarsState: LoadingState.None,
  favouriteCars: [],
  searchAgentsState: LoadingState.None,
  searchAgents: [],
  ownCarsState: LoadingState.None,
  ownCars: [],
};

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: state => {
      state.loginToken = undefined;
      state.shouldLogout = false;
      state.refreshToken = undefined;
      state.expirationDate = undefined;
      state.cachedUser = undefined;
      state.favouriteCars = [];
      state.favouriteCarsState = LoadingState.None;
      state.searchAgents = [];
      state.searchAgentsState = LoadingState.None;
      state.ownCars = [];
      state.ownCarsState = LoadingState.None;
    },
    setShouldLogout: state => {
      state.shouldLogout = true;
    },
    login: (state, action: PayloadAction<LoginToken>) => {
      state.loginToken = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.expirationDate = action.payload.expirationDate;
      state.cachedUser = action.payload.user;
      state.notLoggedInUserData = action.payload.user.personalInfo;
      state.favouriteCars = action.payload.user.favourites;
      state.searchAgents = action.payload.user.searchAgents;
      state.searchAgentsState = LoadingState.Success;
      state.favouriteCarsState = LoadingState.Success;
    },
    setUser: (state, action: PayloadAction<UserItem>) => {
      state.cachedUser = action.payload;
      state.notLoggedInUserData = action.payload.personalInfo;
    },
    setLastSearchRequest: (state, action: PayloadAction<SearchPageOptions>) => {
      state.lastSearchRequest = {
        ...action.payload,
        dateOfLastSearch: new Date(),
      };
    },
    setSavedCars: (
      state,
      action: PayloadAction<FavoriteItem[] | undefined>,
    ) => {
      state.favouriteCars = action.payload;
      state.favouriteCarsState = LoadingState.Success;
    },
    addSavedCar: (state, action: PayloadAction<number>) => {
      if (
        !state.favouriteCars.some(
          favorite => favorite.classifiedId === action.payload,
        )
      ) {
        const newItem: FavoriteItem = {
          classifiedId: action.payload,
          createdDate: new Date(),
        };
        var newList = [newItem].concat(state.favouriteCars);
        state.favouriteCars = newList;
      }
    },
    removeSavedCar: (state, action: PayloadAction<number>) => {
      state.favouriteCars = state.favouriteCars.filter(
        favorite => favorite.classifiedId !== action.payload,
      );
    },
    setSearchAgents: (
      state,
      action: PayloadAction<SearchAgentItem[] | undefined>,
    ) => {
      state.searchAgents = action.payload;
      state.searchAgentsState = LoadingState.Success;
    },
    addSearchAgent: (
      state,
      action: PayloadAction<SearchAgentItem | undefined>,
    ) => {
      state.searchAgents = [action.payload].concat(state.searchAgents);
    },
    updateSearchAgent: (
      state,
      action: PayloadAction<SearchAgentItem | undefined>,
    ) => {
      let index = state.searchAgents.findIndex(p => p.id === action.payload.id);
      state.searchAgents.splice(index, 1, action.payload);
    },
    removeSearchAgent: (state, action: PayloadAction<number | undefined>) => {
      state.searchAgents = state.searchAgents.filter(
        p => p.id !== action.payload,
      );
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.notLoggedInUserData.email = action.payload;
    },
    setZipCode: (state, action: PayloadAction<number>) => {
      state.notLoggedInUserData.zipCode = action.payload;
    },
    setCoords: (state, action: PayloadAction<LocationCoords>) => {
      state.locationCoordsFromBrowser = action.payload;
    },
    setOwnCars: (
      state,
      action: PayloadAction<ClassifiedItem[] | undefined>,
    ) => {
      state.ownCars = action.payload;
      state.ownCarsState = LoadingState.Success;
    },
    addOwnCar: (state, action: PayloadAction<ClassifiedItem | undefined>) => {
      state.ownCars = [action.payload].concat(state.ownCars);
    },
    updateOwnCar: (
      state,
      action: PayloadAction<ClassifiedItem | undefined>,
    ) => {
      let index = state.ownCars.findIndex(p => p.id === action.payload.id);
      state.ownCars.splice(index, 1, action.payload);
    },
    updateOwnCarActivity: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      let item = state.ownCars.find(p => p.id === action.payload);
      item.systemData.isActive = !item.systemData.isActive;
      let index = state.ownCars.findIndex(p => p.id === action.payload);
      state.ownCars.splice(index, 1, item);
    },
    removeOwnCar: (state, action: PayloadAction<number | undefined>) => {
      state.ownCars = state.ownCars.filter(p => p.id !== action.payload);
    },
  },
});

export const {
  logout,
  login,
  setEmail,
  setSavedCars,
  setZipCode,
  setCoords,
  addSavedCar,
  setLastSearchRequest,
  removeSavedCar,
  setSearchAgents,
  addSearchAgent,
  updateSearchAgent,
  removeSearchAgent,
  setUser,
  setOwnCars,
  addOwnCar,
  removeOwnCar,
  updateOwnCar,
  updateOwnCarActivity,
  setShouldLogout,
} = AuthSlice.actions;

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['searchAgentsState', 'favouriteCarsState', 'ownCarsState'],
  transforms: [createTransform(encode, decode)],
};

export default persistReducer<AuthState, AnyAction>(
  authPersistConfig,
  AuthSlice.reducer,
);
