/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';

/**
 * 
 * @export
 * @interface ProfileInformationData
 */
export interface ProfileInformationData {
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileInformationData
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    city?: string | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ProfileInformationData
     */
    countryCode: SearchPageAttribute;
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileInformationData
     */
    streetNumber?: string | null;
}

export function ProfileInformationDataFromJSON(json: any): ProfileInformationData {
    return ProfileInformationDataFromJSONTyped(json, false);
}

export function ProfileInformationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileInformationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'countryCode': SearchPageAttributeFromJSON(json['countryCode']),
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
    };
}

export function ProfileInformationDataToJSON(value?: ProfileInformationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phoneNumber': value.phoneNumber,
        'zipCode': value.zipCode,
        'email': value.email,
        'city': value.city,
        'countryCode': SearchPageAttributeToJSON(value.countryCode),
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
    };
}

