import { Box, Button, Container, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import { Colors } from '../../../../shared/util/colors';
import { useSiteSettingContext } from '@/pages/_app';

interface bottomNavigationBarLink {
  url?: string;
  externalLink?: boolean;
  text: string;
  textStyle?: React.CSSProperties;
  onClick?: () => void;
}

interface bottomNavigationBarItem {
  header: string;
  links: bottomNavigationBarLink[];
}

function BottomNavigationContentContainer() {
  const onClickCookieSettings = () => {
    if ('Cookiebot' in window) {
      window.Cookiebot.show();
    }
  };

  const siteSettings = useSiteSettingContext();

  const bottomNavigationBarItems: bottomNavigationBarItem[] = [
    {
      header: 'Hurtige genveje',
      links: [
        { url: '/alle-biler', text: 'Alle biler til salg lige nu' },
        { url: '/brugte-biler', text: 'Brugte biler' },
        { url: '/nyheder', text: 'Nyheder' },
        { url: '/guide', text: 'Guide til bilkøb' },
      ],
    },
    {
      header: 'Værktøjer',
      links: [
        {
          url: 'https://www.tjekbil.dk/',
          text: 'TjekBil',
          externalLink: true,
        },
        { url: 'https://www.tjekbil.dk/smartsalg', text: 'SmartSalg' },
        { url: '/bilviden', text: 'Bilviden' },
      ],
    },
    {
      header: 'Bilhandel.dk',
      links: [
        { url: '/faq', text: 'FAQ' },
        {
          url: 'https://pace.dk',
          text: 'For bilforhandlere',
        },
        {
          onClick: onClickCookieSettings,
          text: 'Cookieindstillinger',
          textStyle: { cursor: 'pointer' },
        },
        {
          url: 'https://heymate.dk/vilkaar-og-privatlivspolitik/',
          text: 'Brugervilkår',
        },
      ],
    },
    {
      header: 'Brug for hjælp?',
      links: [
        {
          text: 'Vi sidder klar til at hjælpe dig på chat og email hvis du har spørgsmål eller brug for eksperthjælp i forhold til valg af bil.',
        },
        {
          url: 'mailto:support@bilhandel.dk',
          text: 'support@bilhandel.dk',
          externalLink: true,
          textStyle: { textDecoration: 'underline' },
        },
      ],
    },
  ];

  return (
    <Container>
      <Grid container>
        {bottomNavigationBarItems.map((item, itemIndex) => (
          <Grid key={itemIndex} item xs={12} md={6} lg={3} marginY={5}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} marginBottom={1.5}>
                <Typography
                  fontSize={20}
                  color={'rgba(255, 255, 255, 0.63)'}
                  fontWeight={700}>
                  {item.header}
                </Typography>
              </Grid>
              {item.links
                .filter(
                  p => p.url != '/guide' || siteSettings.name == 'Bilhandel',
                )
                .filter(
                  p => p.url != '/nyheder' || siteSettings.name == 'Bilhandel',
                )
                .filter(
                  p => p.url != '/bilviden' || siteSettings.name == 'Bilhandel',
                )
                .map((li, index) => {
                  if (li.url) {
                    return (
                      <Grid key={index} item xs={12}>
                        {li.externalLink ? (
                          <a href={li.url} target={'_blank'} rel="noreferrer">
                            <Typography
                              style={li.textStyle}
                              color={Colors.White}>
                              {li.text}
                            </Typography>
                          </a>
                        ) : (
                          <Link href={li.url} as={li.url} prefetch={false}>
                            <Typography
                              style={li.textStyle}
                              color={Colors.White}>
                              {li.text}
                            </Typography>
                          </Link>
                        )}
                      </Grid>
                    );
                  } else if (li.onClick) {
                    return (
                      <Grid key={index} item xs={12}>
                        <Typography
                          color={Colors.White}
                          style={li.textStyle}
                          onClick={li.onClick}>
                          {li.text}
                        </Typography>
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default React.memo(BottomNavigationContentContainer);
