/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassifiedItemAttachment
 */
export interface ClassifiedItemAttachment {
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemAttachment
     */
    folderId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemAttachment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemAttachment
     */
    videoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemAttachment
     */
    videoPosterUrl?: string | null;
}

export function ClassifiedItemAttachmentFromJSON(json: any): ClassifiedItemAttachment {
    return ClassifiedItemAttachmentFromJSONTyped(json, false);
}

export function ClassifiedItemAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemAttachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'folderId': !exists(json, 'folderId') ? undefined : json['folderId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'videoPosterUrl': !exists(json, 'videoPosterUrl') ? undefined : json['videoPosterUrl'],
    };
}

export function ClassifiedItemAttachmentToJSON(value?: ClassifiedItemAttachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'folderId': value.folderId,
        'id': value.id,
        'videoUrl': value.videoUrl,
        'videoPosterUrl': value.videoPosterUrl,
    };
}

