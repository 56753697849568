/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    vipUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    threadId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    classifiedItemJson?: string | null;
}

export function MessageRequestFromJSON(json: any): MessageRequest {
    return MessageRequestFromJSONTyped(json, false);
}

export function MessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'vipUrl': !exists(json, 'vipUrl') ? undefined : json['vipUrl'],
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'classifiedItemJson': !exists(json, 'classifiedItemJson') ? undefined : json['classifiedItemJson'],
    };
}

export function MessageRequestToJSON(value?: MessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'vipUrl': value.vipUrl,
        'threadId': value.threadId,
        'email': value.email,
        'phone': value.phone,
        'name': value.name,
        'classifiedItemJson': value.classifiedItemJson,
    };
}

