/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppId,
    AppIdFromJSON,
    AppIdFromJSONTyped,
    AppIdToJSON,
} from './AppId';

/**
 * 
 * @export
 * @interface SeoUrl
 */
export interface SeoUrl {
    /**
     * 
     * @type {number}
     * @memberof SeoUrl
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SeoUrl
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeoUrl
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeoUrl
     */
    metaDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeoUrl
     */
    heading?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SeoUrl
     */
    description?: string | null;
    /**
     * 
     * @type {AppId}
     * @memberof SeoUrl
     */
    appId?: AppId;
}

export function SeoUrlFromJSON(json: any): SeoUrl {
    return SeoUrlFromJSONTyped(json, false);
}

export function SeoUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'metaDescription': !exists(json, 'metaDescription') ? undefined : json['metaDescription'],
        'heading': !exists(json, 'heading') ? undefined : json['heading'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'appId': !exists(json, 'appId') ? undefined : AppIdFromJSON(json['appId']),
    };
}

export function SeoUrlToJSON(value?: SeoUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'title': value.title,
        'metaDescription': value.metaDescription,
        'heading': value.heading,
        'description': value.description,
        'appId': AppIdToJSON(value.appId),
    };
}

