/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsInformationData
 */
export interface TermsInformationData {
    /**
     * 
     * @type {boolean}
     * @memberof TermsInformationData
     */
    getNewsLetter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsInformationData
     */
    receivePromotion?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsInformationData
     */
    getOffers?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsInformationData
     */
    terms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsInformationData
     */
    notifyOnChangesToLikes?: boolean | null;
}

export function TermsInformationDataFromJSON(json: any): TermsInformationData {
    return TermsInformationDataFromJSONTyped(json, false);
}

export function TermsInformationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsInformationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'getNewsLetter': !exists(json, 'getNewsLetter') ? undefined : json['getNewsLetter'],
        'receivePromotion': !exists(json, 'receivePromotion') ? undefined : json['receivePromotion'],
        'getOffers': !exists(json, 'getOffers') ? undefined : json['getOffers'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'notifyOnChangesToLikes': !exists(json, 'notifyOnChangesToLikes') ? undefined : json['notifyOnChangesToLikes'],
    };
}

export function TermsInformationDataToJSON(value?: TermsInformationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'getNewsLetter': value.getNewsLetter,
        'receivePromotion': value.receivePromotion,
        'getOffers': value.getOffers,
        'terms': value.terms,
        'notifyOnChangesToLikes': value.notifyOnChangesToLikes,
    };
}

