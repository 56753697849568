/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedCreationFields,
    ClassifiedCreationFieldsFromJSON,
    ClassifiedCreationFieldsFromJSONTyped,
    ClassifiedCreationFieldsToJSON,
} from './ClassifiedCreationFields';
import {
    ClassifiedCreationOptions,
    ClassifiedCreationOptionsFromJSON,
    ClassifiedCreationOptionsFromJSONTyped,
    ClassifiedCreationOptionsToJSON,
} from './ClassifiedCreationOptions';

/**
 * 
 * @export
 * @interface ClassifiedCreationInitiation
 */
export interface ClassifiedCreationInitiation {
    /**
     * 
     * @type {ClassifiedCreationFields}
     * @memberof ClassifiedCreationInitiation
     */
    classifiedCreationFields?: ClassifiedCreationFields;
    /**
     * 
     * @type {ClassifiedCreationOptions}
     * @memberof ClassifiedCreationInitiation
     */
    classifiedCreationOptions?: ClassifiedCreationOptions;
}

export function ClassifiedCreationInitiationFromJSON(json: any): ClassifiedCreationInitiation {
    return ClassifiedCreationInitiationFromJSONTyped(json, false);
}

export function ClassifiedCreationInitiationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedCreationInitiation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classifiedCreationFields': !exists(json, 'classifiedCreationFields') ? undefined : ClassifiedCreationFieldsFromJSON(json['classifiedCreationFields']),
        'classifiedCreationOptions': !exists(json, 'classifiedCreationOptions') ? undefined : ClassifiedCreationOptionsFromJSON(json['classifiedCreationOptions']),
    };
}

export function ClassifiedCreationInitiationToJSON(value?: ClassifiedCreationInitiation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classifiedCreationFields': ClassifiedCreationFieldsToJSON(value.classifiedCreationFields),
        'classifiedCreationOptions': ClassifiedCreationOptionsToJSON(value.classifiedCreationOptions),
    };
}

