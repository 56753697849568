/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedItemFuelType,
    ClassifiedItemFuelTypeFromJSON,
    ClassifiedItemFuelTypeFromJSONTyped,
    ClassifiedItemFuelTypeToJSON,
} from './ClassifiedItemFuelType';
import {
    ClassifiedStatus,
    ClassifiedStatusFromJSON,
    ClassifiedStatusFromJSONTyped,
    ClassifiedStatusToJSON,
} from './ClassifiedStatus';
import {
    RemoteConnectionType,
    RemoteConnectionTypeFromJSON,
    RemoteConnectionTypeFromJSONTyped,
    RemoteConnectionTypeToJSON,
} from './RemoteConnectionType';
import {
    SalesType,
    SalesTypeFromJSON,
    SalesTypeFromJSONTyped,
    SalesTypeToJSON,
} from './SalesType';

/**
 * 
 * @export
 * @interface ClassifiedItemSystemData
 */
export interface ClassifiedItemSystemData {
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemSystemData
     */
    isSold?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemSystemData
     */
    isActive?: boolean;
    /**
     * 
     * @type {ClassifiedItemFuelType}
     * @memberof ClassifiedItemSystemData
     */
    fuelType?: ClassifiedItemFuelType;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemSystemData
     */
    price?: number;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedItemSystemData
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedItemSystemData
     */
    expirationDate?: Date | null;
    /**
     * 
     * @type {SalesType}
     * @memberof ClassifiedItemSystemData
     */
    salesType?: SalesType;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemSystemData
     */
    category?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedItemSystemData
     */
    likedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemSystemData
     */
    isEditable?: boolean | null;
    /**
     * 
     * @type {RemoteConnectionType}
     * @memberof ClassifiedItemSystemData
     */
    remoteType?: RemoteConnectionType;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedItemSystemData
     */
    lastEditedDate?: Date | null;
    /**
     * 
     * @type {ClassifiedStatus}
     * @memberof ClassifiedItemSystemData
     */
    status?: ClassifiedStatus;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemSystemData
     */
    relatedClassifiedId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemSystemData
     */
    relatedClassifiedPrice?: number | null;
}

export function ClassifiedItemSystemDataFromJSON(json: any): ClassifiedItemSystemData {
    return ClassifiedItemSystemDataFromJSONTyped(json, false);
}

export function ClassifiedItemSystemDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemSystemData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSold': !exists(json, 'isSold') ? undefined : json['isSold'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'fuelType': !exists(json, 'fuelType') ? undefined : ClassifiedItemFuelTypeFromJSON(json['fuelType']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (json['expirationDate'] === null ? null : new Date(json['expirationDate'])),
        'salesType': !exists(json, 'salesType') ? undefined : SalesTypeFromJSON(json['salesType']),
        'category': !exists(json, 'category') ? undefined : json['category'],
        'likedDate': !exists(json, 'likedDate') ? undefined : (json['likedDate'] === null ? null : new Date(json['likedDate'])),
        'isEditable': !exists(json, 'isEditable') ? undefined : json['isEditable'],
        'remoteType': !exists(json, 'remoteType') ? undefined : RemoteConnectionTypeFromJSON(json['remoteType']),
        'lastEditedDate': !exists(json, 'lastEditedDate') ? undefined : (json['lastEditedDate'] === null ? null : new Date(json['lastEditedDate'])),
        'status': !exists(json, 'status') ? undefined : ClassifiedStatusFromJSON(json['status']),
        'relatedClassifiedId': !exists(json, 'relatedClassifiedId') ? undefined : json['relatedClassifiedId'],
        'relatedClassifiedPrice': !exists(json, 'relatedClassifiedPrice') ? undefined : json['relatedClassifiedPrice'],
    };
}

export function ClassifiedItemSystemDataToJSON(value?: ClassifiedItemSystemData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSold': value.isSold,
        'isActive': value.isActive,
        'fuelType': ClassifiedItemFuelTypeToJSON(value.fuelType),
        'price': value.price,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate === null ? null : value.expirationDate.toISOString()),
        'salesType': SalesTypeToJSON(value.salesType),
        'category': value.category,
        'likedDate': value.likedDate === undefined ? undefined : (value.likedDate === null ? null : value.likedDate.toISOString()),
        'isEditable': value.isEditable,
        'remoteType': RemoteConnectionTypeToJSON(value.remoteType),
        'lastEditedDate': value.lastEditedDate === undefined ? undefined : (value.lastEditedDate === null ? null : value.lastEditedDate.toISOString()),
        'status': ClassifiedStatusToJSON(value.status),
        'relatedClassifiedId': value.relatedClassifiedId,
        'relatedClassifiedPrice': value.relatedClassifiedPrice,
    };
}

