/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchPageAttribute
 */
export interface SearchPageAttribute {
    /**
     * 
     * @type {boolean}
     * @memberof SearchPageAttribute
     */
    isGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchPageAttribute
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPageAttribute
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPageAttribute
     */
    idValue?: string | null;
}

export function SearchPageAttributeFromJSON(json: any): SearchPageAttribute {
    return SearchPageAttributeFromJSONTyped(json, false);
}

export function SearchPageAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPageAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isGroup': !exists(json, 'isGroup') ? undefined : json['isGroup'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'idValue': !exists(json, 'idValue') ? undefined : json['idValue'],
    };
}

export function SearchPageAttributeToJSON(value?: SearchPageAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isGroup': value.isGroup,
        'value': value.value,
        'label': value.label,
        'idValue': value.idValue,
    };
}

