/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CmsSrpBottomBlockDto,
    CmsSrpBottomBlockDtoFromJSON,
    CmsSrpBottomBlockDtoToJSON,
} from '../models';

export interface ApiCmsBottomsrpboxGetRequest {
    make?: string;
}

/**
 * 
 */
export class CMSApi extends runtime.BaseAPI {

    /**
     */
    async apiCmsBottomsrpboxGetRaw(requestParameters: ApiCmsBottomsrpboxGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CmsSrpBottomBlockDto>> {
        const queryParameters: any = {};

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/bottomsrpbox`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CmsSrpBottomBlockDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCmsBottomsrpboxGet(requestParameters: ApiCmsBottomsrpboxGetRequest = {}, initOverrides?: RequestInit): Promise<CmsSrpBottomBlockDto> {
        const response = await this.apiCmsBottomsrpboxGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
