/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DreiversLeadRequest,
    DreiversLeadRequestFromJSON,
    DreiversLeadRequestToJSON,
} from '../models';

export interface ApiServicePostdreiversleadPostRequest {
    dreiversLeadRequest: DreiversLeadRequest;
}

/**
 * 
 */
export class ServiceApi extends runtime.BaseAPI {

    /**
     */
    async apiServicePostdreiversleadPostRaw(requestParameters: ApiServicePostdreiversleadPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dreiversLeadRequest === null || requestParameters.dreiversLeadRequest === undefined) {
            throw new runtime.RequiredError('dreiversLeadRequest','Required parameter requestParameters.dreiversLeadRequest was null or undefined when calling apiServicePostdreiversleadPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/service/postdreiverslead`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DreiversLeadRequestToJSON(requestParameters.dreiversLeadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiServicePostdreiversleadPost(requestParameters: ApiServicePostdreiversleadPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiServicePostdreiversleadPostRaw(requestParameters, initOverrides);
    }

}
