/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageFields,
    SearchPageFieldsFromJSON,
    SearchPageFieldsFromJSONTyped,
    SearchPageFieldsToJSON,
} from './SearchPageFields';
import {
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsFromJSONTyped,
    SearchPageOptionsToJSON,
} from './SearchPageOptions';
import {
    SearchResult,
    SearchResultFromJSON,
    SearchResultFromJSONTyped,
    SearchResultToJSON,
} from './SearchResult';
import {
    SeoUrl,
    SeoUrlFromJSON,
    SeoUrlFromJSONTyped,
    SeoUrlToJSON,
} from './SeoUrl';

/**
 * 
 * @export
 * @interface SearchInitResponse
 */
export interface SearchInitResponse {
    /**
     * 
     * @type {SearchPageFields}
     * @memberof SearchInitResponse
     */
    fields?: SearchPageFields;
    /**
     * 
     * @type {SearchPageOptions}
     * @memberof SearchInitResponse
     */
    searchPageOptions?: SearchPageOptions;
    /**
     * 
     * @type {SearchResult}
     * @memberof SearchInitResponse
     */
    searchResult?: SearchResult;
    /**
     * 
     * @type {SearchResult}
     * @memberof SearchInitResponse
     */
    emptySearchResult?: SearchResult;
    /**
     * 
     * @type {SeoUrl}
     * @memberof SearchInitResponse
     */
    seoData?: SeoUrl;
}

export function SearchInitResponseFromJSON(json: any): SearchInitResponse {
    return SearchInitResponseFromJSONTyped(json, false);
}

export function SearchInitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchInitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : SearchPageFieldsFromJSON(json['fields']),
        'searchPageOptions': !exists(json, 'searchPageOptions') ? undefined : SearchPageOptionsFromJSON(json['searchPageOptions']),
        'searchResult': !exists(json, 'searchResult') ? undefined : SearchResultFromJSON(json['searchResult']),
        'emptySearchResult': !exists(json, 'emptySearchResult') ? undefined : SearchResultFromJSON(json['emptySearchResult']),
        'seoData': !exists(json, 'seoData') ? undefined : SeoUrlFromJSON(json['seoData']),
    };
}

export function SearchInitResponseToJSON(value?: SearchInitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': SearchPageFieldsToJSON(value.fields),
        'searchPageOptions': SearchPageOptionsToJSON(value.searchPageOptions),
        'searchResult': SearchResultToJSON(value.searchResult),
        'emptySearchResult': SearchResultToJSON(value.emptySearchResult),
        'seoData': SeoUrlToJSON(value.seoData),
    };
}

