/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';

/**
 * 
 * @export
 * @interface UserItemPersonalInfo
 */
export interface UserItemPersonalInfo {
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    screenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserItemPersonalInfo
     */
    zipCode?: number | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof UserItemPersonalInfo
     */
    countryCode?: SearchPageAttribute;
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserItemPersonalInfo
     */
    streetNumber?: string | null;
}

export function UserItemPersonalInfoFromJSON(json: any): UserItemPersonalInfo {
    return UserItemPersonalInfoFromJSONTyped(json, false);
}

export function UserItemPersonalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItemPersonalInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'screenName': !exists(json, 'screenName') ? undefined : json['screenName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : SearchPageAttributeFromJSON(json['countryCode']),
        'city': !exists(json, 'city') ? undefined : json['city'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
    };
}

export function UserItemPersonalInfoToJSON(value?: UserItemPersonalInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'screenName': value.screenName,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'zipCode': value.zipCode,
        'countryCode': SearchPageAttributeToJSON(value.countryCode),
        'city': value.city,
        'streetName': value.streetName,
        'streetNumber': value.streetNumber,
    };
}

