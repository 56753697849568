/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttachmentFileType,
    AttachmentFileTypeFromJSON,
    AttachmentFileTypeFromJSONTyped,
    AttachmentFileTypeToJSON,
} from './AttachmentFileType';
import {
    AttachmentStatus,
    AttachmentStatusFromJSON,
    AttachmentStatusFromJSONTyped,
    AttachmentStatusToJSON,
} from './AttachmentStatus';

/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof AttachmentDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    videoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    videoPreviewUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    pictureUrlCropped?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    pictureUrlCroppedRect?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    bigPictureUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    pictureGalleryFooterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    pictureGallerySidebarUrl?: string | null;
    /**
     * 
     * @type {AttachmentFileType}
     * @memberof AttachmentDto
     */
    attachmentType?: AttachmentFileType;
    /**
     * 
     * @type {AttachmentStatus}
     * @memberof AttachmentDto
     */
    status?: AttachmentStatus;
}

export function AttachmentDtoFromJSON(json: any): AttachmentDto {
    return AttachmentDtoFromJSONTyped(json, false);
}

export function AttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'videoPreviewUrl': !exists(json, 'videoPreviewUrl') ? undefined : json['videoPreviewUrl'],
        'pictureUrl': !exists(json, 'pictureUrl') ? undefined : json['pictureUrl'],
        'pictureUrlCropped': !exists(json, 'pictureUrlCropped') ? undefined : json['pictureUrlCropped'],
        'pictureUrlCroppedRect': !exists(json, 'pictureUrlCroppedRect') ? undefined : json['pictureUrlCroppedRect'],
        'bigPictureUrl': !exists(json, 'bigPictureUrl') ? undefined : json['bigPictureUrl'],
        'pictureGalleryFooterUrl': !exists(json, 'pictureGalleryFooterUrl') ? undefined : json['pictureGalleryFooterUrl'],
        'pictureGallerySidebarUrl': !exists(json, 'pictureGallerySidebarUrl') ? undefined : json['pictureGallerySidebarUrl'],
        'attachmentType': !exists(json, 'attachmentType') ? undefined : AttachmentFileTypeFromJSON(json['attachmentType']),
        'status': !exists(json, 'status') ? undefined : AttachmentStatusFromJSON(json['status']),
    };
}

export function AttachmentDtoToJSON(value?: AttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'videoUrl': value.videoUrl,
        'videoPreviewUrl': value.videoPreviewUrl,
        'pictureUrl': value.pictureUrl,
        'pictureUrlCropped': value.pictureUrlCropped,
        'pictureUrlCroppedRect': value.pictureUrlCroppedRect,
        'bigPictureUrl': value.bigPictureUrl,
        'pictureGalleryFooterUrl': value.pictureGalleryFooterUrl,
        'pictureGallerySidebarUrl': value.pictureGallerySidebarUrl,
        'attachmentType': AttachmentFileTypeToJSON(value.attachmentType),
        'status': AttachmentStatusToJSON(value.status),
    };
}

