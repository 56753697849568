/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserProfileType {
    Private = 'Private',
    Company = 'Company'
}

export function UserProfileTypeFromJSON(json: any): UserProfileType {
    return UserProfileTypeFromJSONTyped(json, false);
}

export function UserProfileTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileType {
    return json as UserProfileType;
}

export function UserProfileTypeToJSON(value?: UserProfileType | null): any {
    return value as any;
}

