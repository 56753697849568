/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassifiedItemSpecification
 */
export interface ClassifiedItemSpecification {
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemSpecification
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemSpecification
     */
    value?: string | null;
}

export function ClassifiedItemSpecificationFromJSON(json: any): ClassifiedItemSpecification {
    return ClassifiedItemSpecificationFromJSONTyped(json, false);
}

export function ClassifiedItemSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function ClassifiedItemSpecificationToJSON(value?: ClassifiedItemSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
    };
}

