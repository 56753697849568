/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppId,
    AppIdFromJSON,
    AppIdFromJSONTyped,
    AppIdToJSON,
} from './AppId';

/**
 * 
 * @export
 * @interface ShortLink
 */
export interface ShortLink {
    /**
     * 
     * @type {number}
     * @memberof ShortLink
     */
    id?: number;
    /**
     * 
     * @type {AppId}
     * @memberof ShortLink
     */
    appId?: AppId;
    /**
     * 
     * @type {boolean}
     * @memberof ShortLink
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortLink
     */
    redirectUrl?: string | null;
}

export function ShortLinkFromJSON(json: any): ShortLink {
    return ShortLinkFromJSONTyped(json, false);
}

export function ShortLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'appId': !exists(json, 'appId') ? undefined : AppIdFromJSON(json['appId']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
    };
}

export function ShortLinkToJSON(value?: ShortLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'appId': AppIdToJSON(value.appId),
        'active': value.active,
        'redirectUrl': value.redirectUrl,
    };
}

