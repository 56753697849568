/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    threadId?: string | null;
    /**
     * 
     * @type {Array<Action>}
     * @memberof MessageResponse
     */
    actions?: Array<Action> | null;
}

export function MessageResponseFromJSON(json: any): MessageResponse {
    return MessageResponseFromJSONTyped(json, false);
}

export function MessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'threadId': !exists(json, 'threadId') ? undefined : json['threadId'],
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] === null ? null : (json['actions'] as Array<any>).map(ActionFromJSON)),
    };
}

export function MessageResponseToJSON(value?: MessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'threadId': value.threadId,
        'actions': value.actions === undefined ? undefined : (value.actions === null ? null : (value.actions as Array<any>).map(ActionToJSON)),
    };
}

