/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeClassifiedStateRequest,
    ChangeClassifiedStateRequestFromJSON,
    ChangeClassifiedStateRequestToJSON,
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemToJSON,
    Financing,
    FinancingFromJSON,
    FinancingToJSON,
    GetInsurancePriceResponse,
    GetInsurancePriceResponseFromJSON,
    GetInsurancePriceResponseToJSON,
    MakeDto,
    MakeDtoFromJSON,
    MakeDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RecommendationsRequest,
    RecommendationsRequestFromJSON,
    RecommendationsRequestToJSON,
    ShortLink,
    ShortLinkFromJSON,
    ShortLinkToJSON,
} from '../models';

export interface ApiClassifiedChangestatePostRequest {
    changeClassifiedStateRequest?: ChangeClassifiedStateRequest;
}

export interface ApiClassifiedFactsIdGetRequest {
    id: number;
}

export interface ApiClassifiedIdGetRequest {
    id: number;
}

export interface ApiClassifiedRecommendationsPostRequest {
    recommendationsRequest?: RecommendationsRequest;
}

export interface ApiClassifiedSavegreenbowleadPostRequest {
    classifiedId?: number;
    screenName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface ApiClassifiedSeezbuynowPostRequest {
    classifiedId?: number;
}

export interface ApiClassifiedShortlinkIdPostRequest {
    id: number;
}

export interface ApiClassifiedTrackviewIdPostRequest {
    id: number;
}

export interface FinancingRequest {
    classifiedId: number;
    amount: number;
}

export interface InsuranceRequest {
    classifiedId: number;
}

/**
 * 
 */
export class ClassifiedApi extends runtime.BaseAPI {

    /**
     */
    async apiClassifiedChangestatePostRaw(requestParameters: ApiClassifiedChangestatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/classified/changestate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeClassifiedStateRequestToJSON(requestParameters.changeClassifiedStateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiClassifiedChangestatePost(requestParameters: ApiClassifiedChangestatePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiClassifiedChangestatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiClassifiedFactsIdGetRaw(requestParameters: ApiClassifiedFactsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiClassifiedFactsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/facts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiClassifiedFactsIdGet(requestParameters: ApiClassifiedFactsIdGetRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiClassifiedFactsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedGetownclassifiedsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/getownclassifieds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemFromJSON));
    }

    /**
     */
    async apiClassifiedGetownclassifiedsGet(initOverrides?: RequestInit): Promise<Array<ClassifiedItem>> {
        const response = await this.apiClassifiedGetownclassifiedsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedIdGetRaw(requestParameters: ApiClassifiedIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassifiedItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiClassifiedIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassifiedItemFromJSON(jsonValue));
    }

    /**
     */
    async apiClassifiedIdGet(requestParameters: ApiClassifiedIdGetRequest, initOverrides?: RequestInit): Promise<ClassifiedItem> {
        const response = await this.apiClassifiedIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedListClassifiedsFromDbGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/list-classifieds-from-db`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemFromJSON));
    }

    /**
     */
    async apiClassifiedListClassifiedsFromDbGet(initOverrides?: RequestInit): Promise<Array<ClassifiedItem>> {
        const response = await this.apiClassifiedListClassifiedsFromDbGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedRecommendationsPostRaw(requestParameters: ApiClassifiedRecommendationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/classified/recommendations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecommendationsRequestToJSON(requestParameters.recommendationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemFromJSON));
    }

    /**
     */
    async apiClassifiedRecommendationsPost(requestParameters: ApiClassifiedRecommendationsPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassifiedItem>> {
        const response = await this.apiClassifiedRecommendationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedSavegreenbowleadPostRaw(requestParameters: ApiClassifiedSavegreenbowleadPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.classifiedId !== undefined) {
            queryParameters['classifiedId'] = requestParameters.classifiedId;
        }

        if (requestParameters.screenName !== undefined) {
            queryParameters['screenName'] = requestParameters.screenName;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['phoneNumber'] = requestParameters.phoneNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/savegreenbowlead`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiClassifiedSavegreenbowleadPost(requestParameters: ApiClassifiedSavegreenbowleadPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiClassifiedSavegreenbowleadPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiClassifiedSeezbuynowPostRaw(requestParameters: ApiClassifiedSeezbuynowPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.classifiedId !== undefined) {
            queryParameters['classifiedId'] = requestParameters.classifiedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/seezbuynow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiClassifiedSeezbuynowPost(requestParameters: ApiClassifiedSeezbuynowPostRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiClassifiedSeezbuynowPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedShortlinkIdPostRaw(requestParameters: ApiClassifiedShortlinkIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShortLink>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiClassifiedShortlinkIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/shortlink/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShortLinkFromJSON(jsonValue));
    }

    /**
     */
    async apiClassifiedShortlinkIdPost(requestParameters: ApiClassifiedShortlinkIdPostRequest, initOverrides?: RequestInit): Promise<ShortLink> {
        const response = await this.apiClassifiedShortlinkIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassifiedTrackviewIdPostRaw(requestParameters: ApiClassifiedTrackviewIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiClassifiedTrackviewIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/trackview/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiClassifiedTrackviewIdPost(requestParameters: ApiClassifiedTrackviewIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiClassifiedTrackviewIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiClassifiedUserMakesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MakeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/user/makes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MakeDtoFromJSON));
    }

    /**
     */
    async apiClassifiedUserMakesGet(initOverrides?: RequestInit): Promise<Array<MakeDto>> {
        const response = await this.apiClassifiedUserMakesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async financingRaw(requestParameters: FinancingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Financing>> {
        if (requestParameters.classifiedId === null || requestParameters.classifiedId === undefined) {
            throw new runtime.RequiredError('classifiedId','Required parameter requestParameters.classifiedId was null or undefined when calling financing.');
        }

        if (requestParameters.amount === null || requestParameters.amount === undefined) {
            throw new runtime.RequiredError('amount','Required parameter requestParameters.amount was null or undefined when calling financing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/financing/{classifiedId}/{amount}`.replace(`{${"classifiedId"}}`, encodeURIComponent(String(requestParameters.classifiedId))).replace(`{${"amount"}}`, encodeURIComponent(String(requestParameters.amount))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancingFromJSON(jsonValue));
    }

    /**
     */
    async financing(requestParameters: FinancingRequest, initOverrides?: RequestInit): Promise<Financing> {
        const response = await this.financingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async insuranceRaw(requestParameters: InsuranceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetInsurancePriceResponse>> {
        if (requestParameters.classifiedId === null || requestParameters.classifiedId === undefined) {
            throw new runtime.RequiredError('classifiedId','Required parameter requestParameters.classifiedId was null or undefined when calling insurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/classified/insurance/{classifiedId}`.replace(`{${"classifiedId"}}`, encodeURIComponent(String(requestParameters.classifiedId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInsurancePriceResponseFromJSON(jsonValue));
    }

    /**
     */
    async insurance(requestParameters: InsuranceRequest, initOverrides?: RequestInit): Promise<GetInsurancePriceResponse> {
        const response = await this.insuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
