import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, AppState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const getExtraSmallPhoneSize = '(max-width:329px)';
export const getSmallPhoneSize = '(max-width:450px)';
export const getMDSize = '(max-width:900px)';
export const getLgSize = '(max-width:700px)';
