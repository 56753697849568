/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FinancingSource,
    FinancingSourceFromJSON,
    FinancingSourceFromJSONTyped,
    FinancingSourceToJSON,
} from './FinancingSource';

/**
 * 
 * @export
 * @interface Financing
 */
export interface Financing {
    /**
     * 
     * @type {number}
     * @memberof Financing
     */
    pricePerMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof Financing
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Financing
     */
    legalText?: string | null;
    /**
     * 
     * @type {FinancingSource}
     * @memberof Financing
     */
    financingSource?: FinancingSource;
}

export function FinancingFromJSON(json: any): Financing {
    return FinancingFromJSONTyped(json, false);
}

export function FinancingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Financing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pricePerMonth': !exists(json, 'pricePerMonth') ? undefined : json['pricePerMonth'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'legalText': !exists(json, 'legalText') ? undefined : json['legalText'],
        'financingSource': !exists(json, 'financingSource') ? undefined : FinancingSourceFromJSON(json['financingSource']),
    };
}

export function FinancingToJSON(value?: Financing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pricePerMonth': value.pricePerMonth,
        'url': value.url,
        'legalText': value.legalText,
        'financingSource': FinancingSourceToJSON(value.financingSource),
    };
}

