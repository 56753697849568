import { useSiteSettingContext } from '@/pages/_app';
import { Box, Container } from '@mui/material';
import Image from '@/web/views/components/image/image';
import React from 'react';
import { Colors } from '../../../../shared/util/colors';
import { ClickAbleFacebookIcon } from '../external-icons/facebook-icon';
import { ClickAbleLinkedinIcon } from '../external-icons/linkedin-icon';

function BottomAvailableAsMobile() {
  const { logo, name } = useSiteSettingContext();
  return (
    <Container>
      <Box
        bgcolor={Colors.Background.FooterDark}
        height={60}
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
          <Image src={logo.src} alt={name} width={116.8} height={18.37} />
        </Box>
        <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
          <Box marginRight={1}>{<ClickAbleLinkedinIcon />}</Box>
          <Box>
            <ClickAbleFacebookIcon />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default BottomAvailableAsMobile;
