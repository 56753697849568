export const Colors = {
  Primary: '#085768',
  Secondary: '#1066B6',
  Yellow: '#FFE08F',
  Error: '#D94445',
  ErrorBackGround: '#FFF1F1',
  Warning: '#F5A913',
  LightBlue: '#4CA0BB',
  DarkBlue: '#2C8BA9',
  DarkerBlue: '#277892',
  Info: '#4CA0BB',
  Success: '#63B03E',
  Muted: '#D9D9D9',
  DarkMuted: '#6F6F6F',
  Background: {
    Default: '#EBEDEF',
    SubBackgroundColor: '#ECF4F6',
    TableHead: '#fff',
    Footer: '#277892',
    FooterDark: '#085768',
    Cms: '#EBEDEF',
    CmsPageLink: '#FBFBFB',
    VipHeaderCard: '#ECF4F6',
    VipWriteToSeller: '#F6F6F8',
    VipWriteToSellerCheckBoxModal: '#E1EAED',
  },
  White: '#FFFFFF',
  Black: '#000000',
  Gray: '#7B9CA7',
  DefaultFontColor: '#343434',
  AlertFontColor: 'rgb(98, 74, 26)',
  TabFontColor: '#302626',
  BackDropColor: 'rgba(0,0,0,0.7)',

  DarkBlueBackgroundColor: '#1F3352',
  FetchInformationColor: '#1783D1',
  LinkColor: '#136EB0',
  HesselBlueColor: '#2cabe3',
  PlaceHolderColor: '#AFAFAF',
  BlurredTextColor: '#A9A9A9',
  BorderColor: '#CECECE',
  Input: {
    InputBorderColor: '#A9B2B9',
    InputPlaceholderColor: '#BBBBBB',
  },
};
