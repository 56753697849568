/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassifiedCreationAttachment,
    ClassifiedCreationAttachmentFromJSON,
    ClassifiedCreationAttachmentFromJSONTyped,
    ClassifiedCreationAttachmentToJSON,
} from './ClassifiedCreationAttachment';
import {
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeFromJSONTyped,
    SearchPageAttributeToJSON,
} from './SearchPageAttribute';
import {
    SearchPageMakeModelVariantOption,
    SearchPageMakeModelVariantOptionFromJSON,
    SearchPageMakeModelVariantOptionFromJSONTyped,
    SearchPageMakeModelVariantOptionToJSON,
} from './SearchPageMakeModelVariantOption';

/**
 * 
 * @export
 * @interface ClassifiedCreationOptions
 */
export interface ClassifiedCreationOptions {
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    classifiedId: number;
    /**
     * 
     * @type {Array<ClassifiedCreationAttachment>}
     * @memberof ClassifiedCreationOptions
     */
    images?: Array<ClassifiedCreationAttachment> | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    category: SearchPageAttribute;
    /**
     * 
     * @type {Array<SearchPageMakeModelVariantOption>}
     * @memberof ClassifiedCreationOptions
     */
    makeModelVariantOptions: Array<SearchPageMakeModelVariantOption>;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    fuelTypes?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    carTypes?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    engineSize?: SearchPageAttribute;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    milage: number;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    horsepower?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    batteryCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    distance?: number | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    colors?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    gearBoxTypes?: SearchPageAttribute;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof ClassifiedCreationOptions
     */
    equipment?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    kmPerLiter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    energyConsumption?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedCreationOptions
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedCreationOptions
     */
    lastInspection?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedCreationOptions
     */
    regDate: Date;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof ClassifiedCreationOptions
     */
    hideAddress?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof ClassifiedCreationOptions
     */
    displayBud?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    maxTrailerWeight?: number | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof ClassifiedCreationOptions
     */
    priceIncludesRegTax?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {Array<SearchPageAttribute>}
     * @memberof ClassifiedCreationOptions
     */
    priceDoesntIncludeRegTax?: Array<SearchPageAttribute> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    co?: number | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    trailerHitch?: SearchPageAttribute;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    leasingType?: SearchPageAttribute;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    firstPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    term?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    kmIncluded?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    fullPriceInTerm?: number | null;
    /**
     * 
     * @type {SearchPageAttribute}
     * @memberof ClassifiedCreationOptions
     */
    service?: SearchPageAttribute;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    residualValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedCreationOptions
     */
    pricePerMonth?: number | null;
}

export function ClassifiedCreationOptionsFromJSON(json: any): ClassifiedCreationOptions {
    return ClassifiedCreationOptionsFromJSONTyped(json, false);
}

export function ClassifiedCreationOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedCreationOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classifiedId': json['classifiedId'],
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(ClassifiedCreationAttachmentFromJSON)),
        'category': SearchPageAttributeFromJSON(json['category']),
        'makeModelVariantOptions': ((json['makeModelVariantOptions'] as Array<any>).map(SearchPageMakeModelVariantOptionFromJSON)),
        'fuelTypes': !exists(json, 'fuelTypes') ? undefined : SearchPageAttributeFromJSON(json['fuelTypes']),
        'carTypes': !exists(json, 'carTypes') ? undefined : SearchPageAttributeFromJSON(json['carTypes']),
        'engineSize': !exists(json, 'engineSize') ? undefined : SearchPageAttributeFromJSON(json['engineSize']),
        'milage': json['milage'],
        'horsepower': !exists(json, 'horsepower') ? undefined : json['horsepower'],
        'batteryCapacity': !exists(json, 'batteryCapacity') ? undefined : json['batteryCapacity'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'colors': !exists(json, 'colors') ? undefined : SearchPageAttributeFromJSON(json['colors']),
        'gearBoxTypes': !exists(json, 'gearBoxTypes') ? undefined : SearchPageAttributeFromJSON(json['gearBoxTypes']),
        'equipment': !exists(json, 'equipment') ? undefined : (json['equipment'] === null ? null : (json['equipment'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'kmPerLiter': !exists(json, 'kmPerLiter') ? undefined : json['kmPerLiter'],
        'energyConsumption': !exists(json, 'energyConsumption') ? undefined : json['energyConsumption'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'lastInspection': !exists(json, 'lastInspection') ? undefined : (json['lastInspection'] === null ? null : new Date(json['lastInspection'])),
        'regDate': (new Date(json['regDate'])),
        'hideAddress': !exists(json, 'hideAddress') ? undefined : (json['hideAddress'] === null ? null : (json['hideAddress'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'displayBud': !exists(json, 'displayBud') ? undefined : (json['displayBud'] === null ? null : (json['displayBud'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'maxTrailerWeight': !exists(json, 'maxTrailerWeight') ? undefined : json['maxTrailerWeight'],
        'priceIncludesRegTax': !exists(json, 'priceIncludesRegTax') ? undefined : (json['priceIncludesRegTax'] === null ? null : (json['priceIncludesRegTax'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'priceDoesntIncludeRegTax': !exists(json, 'priceDoesntIncludeRegTax') ? undefined : (json['priceDoesntIncludeRegTax'] === null ? null : (json['priceDoesntIncludeRegTax'] as Array<any>).map(SearchPageAttributeFromJSON)),
        'co': !exists(json, 'co') ? undefined : json['co'],
        'trailerHitch': !exists(json, 'trailerHitch') ? undefined : SearchPageAttributeFromJSON(json['trailerHitch']),
        'leasingType': !exists(json, 'leasingType') ? undefined : SearchPageAttributeFromJSON(json['leasingType']),
        'firstPayment': !exists(json, 'firstPayment') ? undefined : json['firstPayment'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'kmIncluded': !exists(json, 'kmIncluded') ? undefined : json['kmIncluded'],
        'fullPriceInTerm': !exists(json, 'fullPriceInTerm') ? undefined : json['fullPriceInTerm'],
        'service': !exists(json, 'service') ? undefined : SearchPageAttributeFromJSON(json['service']),
        'residualValue': !exists(json, 'residualValue') ? undefined : json['residualValue'],
        'pricePerMonth': !exists(json, 'pricePerMonth') ? undefined : json['pricePerMonth'],
    };
}

export function ClassifiedCreationOptionsToJSON(value?: ClassifiedCreationOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classifiedId': value.classifiedId,
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(ClassifiedCreationAttachmentToJSON)),
        'category': SearchPageAttributeToJSON(value.category),
        'makeModelVariantOptions': ((value.makeModelVariantOptions as Array<any>).map(SearchPageMakeModelVariantOptionToJSON)),
        'fuelTypes': SearchPageAttributeToJSON(value.fuelTypes),
        'carTypes': SearchPageAttributeToJSON(value.carTypes),
        'engineSize': SearchPageAttributeToJSON(value.engineSize),
        'milage': value.milage,
        'horsepower': value.horsepower,
        'batteryCapacity': value.batteryCapacity,
        'distance': value.distance,
        'colors': SearchPageAttributeToJSON(value.colors),
        'gearBoxTypes': SearchPageAttributeToJSON(value.gearBoxTypes),
        'equipment': value.equipment === undefined ? undefined : (value.equipment === null ? null : (value.equipment as Array<any>).map(SearchPageAttributeToJSON)),
        'price': value.price,
        'kmPerLiter': value.kmPerLiter,
        'energyConsumption': value.energyConsumption,
        'description': value.description,
        'lastInspection': value.lastInspection === undefined ? undefined : (value.lastInspection === null ? null : value.lastInspection.toISOString()),
        'regDate': (value.regDate.toISOString()),
        'hideAddress': value.hideAddress === undefined ? undefined : (value.hideAddress === null ? null : (value.hideAddress as Array<any>).map(SearchPageAttributeToJSON)),
        'displayBud': value.displayBud === undefined ? undefined : (value.displayBud === null ? null : (value.displayBud as Array<any>).map(SearchPageAttributeToJSON)),
        'maxTrailerWeight': value.maxTrailerWeight,
        'priceIncludesRegTax': value.priceIncludesRegTax === undefined ? undefined : (value.priceIncludesRegTax === null ? null : (value.priceIncludesRegTax as Array<any>).map(SearchPageAttributeToJSON)),
        'priceDoesntIncludeRegTax': value.priceDoesntIncludeRegTax === undefined ? undefined : (value.priceDoesntIncludeRegTax === null ? null : (value.priceDoesntIncludeRegTax as Array<any>).map(SearchPageAttributeToJSON)),
        'co': value.co,
        'trailerHitch': SearchPageAttributeToJSON(value.trailerHitch),
        'leasingType': SearchPageAttributeToJSON(value.leasingType),
        'firstPayment': value.firstPayment,
        'term': value.term,
        'kmIncluded': value.kmIncluded,
        'fullPriceInTerm': value.fullPriceInTerm,
        'service': SearchPageAttributeToJSON(value.service),
        'residualValue': value.residualValue,
        'pricePerMonth': value.pricePerMonth,
    };
}

