import { useCallback, useMemo } from 'react';
import { SetTermsFromSocialRequest } from '../lib-api';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  addSavedCar as addToFavoritesAction,
  removeSavedCar as removeFromFavoritesAction,
  login as loginAction,
  logout as LogoutAction,
  setUser,
  setShouldLogout,
} from '../store/reducers/AuthSlice';
import { useApi } from '../util/api';
import { LoadingState } from '../util/general-helpers';
import { CreateValues, LoginValues } from '../util/helpers/auth-helper';
import { useRequestHelper } from './request-helper';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';

export function useAuth() {
  const auth = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const { handleRequest } = useRequestHelper();
  const { authApi } = useApi();

  const isLoggedIn = useMemo(() => {
    return auth.loginToken !== undefined;
  }, [auth.loginToken]);

  const favorites = useMemo(() => {
    return auth.favouriteCars;
  }, [auth.favouriteCars]);

  const login = useCallback(
    async (values: LoginValues) => {
      const loginRequest = authApi.apiAuthSigninemailPost({
        signInRequest: { email: values.email, password: values.password },
      });
      let [loginToken, errorAndState] = await handleRequest(loginRequest);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(loginAction(loginToken));
        const personalInfo = loginToken?.user?.personalInfo;
        TrackEvent(BuildInTrackEvents.OnUserLogin, {
          dlv_user_loginmethod: 'email',
          dlv_logged_in_state: true,
          dlv_user_email: personalInfo?.email,
          dlv_user_screenname: personalInfo?.screenName,
          dlv_user_zip: personalInfo?.zipCode,
          dlv_user_city: personalInfo?.city,
          dlv_user_phone: personalInfo?.phoneNumber,
        });
      }
      return errorAndState;
    },
    [authApi, dispatch, handleRequest],
  );

  const createUser = useCallback(
    async (values: CreateValues) => {
      const createUserRequest = authApi.apiAuthCreateuserPost({
        createUserFromEmailRequest: {
          email: values.email,
          password: values.password,
          newsAccepted: values.newsAccepted,
          termsAccepted: values.termsAccepted,
        },
      });
      let [loginToken, errorAndState] = await handleRequest(createUserRequest);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(loginAction(loginToken));
        const personalInfo = loginToken?.user?.personalInfo;
        TrackEvent(BuildInTrackEvents.OnUserCreated, {
          dlv_user_createmethod: 'email',
          dlv_logged_in_state: true,
          dlv_user_email: personalInfo?.email,
          dlv_user_screenname: personalInfo?.screenName,
          dlv_user_zip: personalInfo?.zipCode,
          dlv_user_city: personalInfo?.city,
          dlv_user_phone: personalInfo?.phoneNumber,
        });
      }
      return errorAndState;
    },
    [authApi, dispatch, handleRequest],
  );

  const setTermsFromSocialLogin = useCallback(
    async (values: SetTermsFromSocialRequest) => {
      const createUserRequest = authApi.apiAuthSetpermissionsfromsocialPost({
        setTermsFromSocialRequest: values,
      });
      let [userItem, errorAndState] = await handleRequest(createUserRequest);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(setUser(userItem));
      }
      return errorAndState;
    },
    [authApi, handleRequest, dispatch],
  );

  const checkEmail = useCallback(
    async (email: string) => {
      const loginRequest = authApi.apiAuthCheckemailPost({
        checkEmailRequest: { email: email },
      });
      return await handleRequest(loginRequest);
    },
    [authApi, handleRequest],
  );

  const logout = useCallback(() => {
    dispatch(LogoutAction());
  }, [dispatch]);

  const setLogoutAfterRedirect = useCallback(() => {
    dispatch(setShouldLogout());
  }, [dispatch]);

  const addToFavorites = useCallback(
    (id: number) => {
      dispatch(addToFavoritesAction(id));
    },
    [dispatch],
  );

  const removeFromFavorites = useCallback(
    (id: number) => {
      dispatch(removeFromFavoritesAction(id));
    },
    [dispatch],
  );

  return {
    isLoggedIn: isLoggedIn,
    favorites,
    addToFavorites,
    removeFromFavorites,
    login,
    logout,
    checkEmail,
    createUser,
    setTermsFromSocialLogin,
    setLogoutAfterRedirect,
  };
}
