import { useRouter } from 'next/router';

export function useClassificationLimit(
  classification: number,
): [isLimited: boolean] {
  const router = useRouter();
  const clmtsRaw = router.query.clmt;
  let isLimited = false;
  if (clmtsRaw) {
    const clmt = Array.isArray(clmtsRaw)
      ? clmtsRaw.map(Number)
      : parseInt(clmtsRaw);

    if (Array.isArray(clmt)) {
      isLimited = clmt.includes(classification);
    } else {
      isLimited = clmt <= classification;
    }
  }

  return [isLimited];
}
