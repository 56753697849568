/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelDate
 */
export interface ModelDate {
    /**
     * 
     * @type {string}
     * @memberof ModelDate
     */
    dateLabel?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelDate
     */
    timeSlots?: Array<string> | null;
}

export function ModelDateFromJSON(json: any): ModelDate {
    return ModelDateFromJSONTyped(json, false);
}

export function ModelDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateLabel': !exists(json, 'dateLabel') ? undefined : json['dateLabel'],
        'timeSlots': !exists(json, 'timeSlots') ? undefined : json['timeSlots'],
    };
}

export function ModelDateToJSON(value?: ModelDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateLabel': value.dateLabel,
        'timeSlots': value.timeSlots,
    };
}

