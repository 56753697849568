import React from 'react';
import linkedin from '../../../../shared/icons/linkedin.svg';
import { TouchableOpacity } from '../default-components/touchableOpacity';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';

export const openLinkedinBilHandel = () => {
  OpenExternalLink('https://dk.linkedin.com/company/bilhandel');
};

export function ClickAbleLinkedinIcon() {
  return (
    <TouchableOpacity
      onClick={openLinkedinBilHandel}
      height={21}
      width={22}
      src={linkedin.src}
    />
  );
}
