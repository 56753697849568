import slugify from 'slugify';
import { DealerSearchFilters as SearchFilters } from '@/pages/forhandler/[dealer]/[city]/[id]/[[...slug]]';

export const slugifyOptions = {
  lower: true,
  remove: RegExp(/[*+~.(),'"!:@✅/]/g),
  locale: 'da',
};

export const updateFindDealerRoute = (options: {
  searchQuery?: string;
  area?: string;
  page?: number;
  zipCode?: number;
}) => {
  let url = '/find-forhandler';
  let useAnd = false;
  if (options.area) {
    url += `/omraade-${options.area}`;
  }

  if (options.page) {
    url += `/side-${options.page}`;
  }

  if (options.searchQuery) {
    url += `${useAnd ? '&' : '?'}forhandlernavn=${options.searchQuery}`;
    useAnd = true;
  }

  if (options.zipCode) {
    url += `${useAnd ? '&' : '?'}zip=${options.zipCode}`;
    useAnd = true;
  }

  return url;
};

export const getCvrDealerVipUrl = (name: string, city: string, cvr: number) => {
  return getCvrDealerVipUrlPreSluged(
    slugify(name, slugifyOptions),
    slugify(city, slugifyOptions),
    cvr,
  );
};

export const getCvrDealerVipUrlPreSluged = (
  name_slug: string,
  city_slug: string,
  cvr: number,
) => {
  return `/forhandler-oversigt/${name_slug}/${city_slug}/cvr-${cvr}`;
};

export const getDealerVipUrl = (
  name: string,
  city: string,
  zipcode: number,
  id: number,
) => {
  return getDealerVipUrlPreSluged(
    slugify(name, slugifyOptions),
    slugify(city, slugifyOptions),
    zipcode,
    id,
  );
};

export const getDealerVipUrlPreSluged = (
  name_slug: string,
  city_slug: string,
  zipcode: number,
  id: number,
) => {
  return `/forhandler/${name_slug}/${city_slug}-${zipcode}/id-${id}`;
};

export const getDbfuFilteredUrl = (
  filters: SearchFilters,
  page?: number,
  sort?: string,
) => {
  let dealerUrl = '/dbfu';

  const queryParams = [];
  if (filters) {
    if (filters.make) {
      queryParams.push(`brand=${slugify(filters.make, slugifyOptions)}`);
    }
    if (filters.priceFrom || filters.priceFrom === 0) {
      queryParams.push(`pris=${filters.priceFrom},${filters.priceTo ?? ''}`);
    }
    if (filters.year) {
      queryParams.push(`aargang=${filters.year}`);
    }
  }

  if (sort) {
    queryParams.push(`sort=${sort}`);
  }

  if (page) {
    dealerUrl += `/side-${page}`;
  }

  if (queryParams.length > 0) {
    dealerUrl += `?${queryParams.join('&')}`;
  }

  return dealerUrl;
};

export const getAutotjekFilteredUrl = (
  filters: SearchFilters,
  page?: number,
  sort?: string,
) => {
  let dealerUrl = '/100pct-autotjek';

  const queryParams = [];
  if (filters) {
    if (filters.make) {
      queryParams.push(`brand=${slugify(filters.make, slugifyOptions)}`);
    }
    if (filters.priceFrom || filters.priceFrom === 0) {
      queryParams.push(`pris=${filters.priceFrom},${filters.priceTo ?? ''}`);
    }
    if (filters.year) {
      queryParams.push(`aargang=${filters.year}`);
    }
  }

  if (sort) {
    queryParams.push(`sort=${sort}`);
  }

  if (page) {
    dealerUrl += `/side-${page}`;
  }

  if (queryParams.length > 0) {
    dealerUrl += `?${queryParams.join('&')}`;
  }

  return dealerUrl;
};

export const getDealerVipFilteredUrl = (
  name: string,
  city: string,
  zipcode: number,
  id: number,
  filters: SearchFilters,
  page?: number,
  sort?: string,
) => {
  let dealerUrl = getDealerVipUrl(name, city, zipcode, id);

  const queryParams = [];
  if (filters) {
    if (filters.make) {
      dealerUrl += `/${slugify(filters.make, slugifyOptions)}`;
    }
    if (filters.priceFrom || filters.priceFrom === 0) {
      queryParams.push(`pris=${filters.priceFrom},${filters.priceTo ?? ''}`);
    }
    if (filters.year) {
      queryParams.push(`aargang=${filters.year}`);
    }
  }

  if (sort) {
    queryParams.push(`sort=${sort}`);
  }

  if (page) {
    dealerUrl += `/side-${page}`;
  }

  if (queryParams.length > 0) {
    dealerUrl += `?${queryParams.join('&')}`;
  }

  return dealerUrl;
};

export const getClassifiedVipUrl = (id: number, headline: string) => {
  return getClassifiedVipUrlPreSluged(id, slugify(headline, slugifyOptions));
};

export const getClassifiedVipUrlPreSluged = (
  id: number,
  headlineSlug: string,
) => {
  return `/${headlineSlug}/id-${id}`;
};

export const getClassifiedVipGalleryUrl = (id: number, headline: string) => {
  return `/${slugify(headline, slugifyOptions)}/galleri/id-${id}`;
};
