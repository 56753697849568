/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FinancingSource {
    Lendo = 'Lendo',
    Resurs = 'Resurs'
}

export function FinancingSourceFromJSON(json: any): FinancingSource {
    return FinancingSourceFromJSONTyped(json, false);
}

export function FinancingSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancingSource {
    return json as FinancingSource;
}

export function FinancingSourceToJSON(value?: FinancingSource | null): any {
    return value as any;
}

