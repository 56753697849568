/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiCarpalSendtocarpalGetRequest {
    id?: number;
}

/**
 * 
 */
export class CarpalApi extends runtime.BaseAPI {

    /**
     */
    async apiCarpalSendtocarpalGetRaw(requestParameters: ApiCarpalSendtocarpalGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/carpal/sendtocarpal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCarpalSendtocarpalGet(requestParameters: ApiCarpalSendtocarpalGetRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiCarpalSendtocarpalGetRaw(requestParameters, initOverrides);
    }

}
