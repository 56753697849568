/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BilHandelNotMemberDealerDto
 */
export interface BilHandelNotMemberDealerDto {
    /**
     * 
     * @type {number}
     * @memberof BilHandelNotMemberDealerDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BilHandelNotMemberDealerDto
     */
    cvr?: number;
    /**
     * 
     * @type {string}
     * @memberof BilHandelNotMemberDealerDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BilHandelNotMemberDealerDto
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BilHandelNotMemberDealerDto
     */
    zipCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BilHandelNotMemberDealerDto
     */
    city?: string | null;
}

export function BilHandelNotMemberDealerDtoFromJSON(json: any): BilHandelNotMemberDealerDto {
    return BilHandelNotMemberDealerDtoFromJSONTyped(json, false);
}

export function BilHandelNotMemberDealerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BilHandelNotMemberDealerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
    };
}

export function BilHandelNotMemberDealerDtoToJSON(value?: BilHandelNotMemberDealerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cvr': value.cvr,
        'name': value.name,
        'address': value.address,
        'zipCode': value.zipCode,
        'city': value.city,
    };
}

