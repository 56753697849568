/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum YearsWithoutIncidents {
    ZeroToTwoYears = 'ZeroToTwoYears',
    TwoToFourYear = 'TwoToFourYear',
    FourToSixYear = 'FourToSixYear',
    Elite = 'Elite'
}

export function YearsWithoutIncidentsFromJSON(json: any): YearsWithoutIncidents {
    return YearsWithoutIncidentsFromJSONTyped(json, false);
}

export function YearsWithoutIncidentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): YearsWithoutIncidents {
    return json as YearsWithoutIncidents;
}

export function YearsWithoutIncidentsToJSON(value?: YearsWithoutIncidents | null): any {
    return value as any;
}

