/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchPageField,
    SearchPageFieldFromJSON,
    SearchPageFieldFromJSONTyped,
    SearchPageFieldToJSON,
} from './SearchPageField';

/**
 * 
 * @export
 * @interface SearchPageFields
 */
export interface SearchPageFields {
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    categorySection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    defaultSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    limitSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    equipmentSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    colorSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    locationSection?: Array<SearchPageField> | null;
    /**
     * 
     * @type {Array<SearchPageField>}
     * @memberof SearchPageFields
     */
    seatSection?: Array<SearchPageField> | null;
}

export function SearchPageFieldsFromJSON(json: any): SearchPageFields {
    return SearchPageFieldsFromJSONTyped(json, false);
}

export function SearchPageFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPageFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categorySection': !exists(json, 'categorySection') ? undefined : (json['categorySection'] === null ? null : (json['categorySection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'defaultSection': !exists(json, 'defaultSection') ? undefined : (json['defaultSection'] === null ? null : (json['defaultSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'limitSection': !exists(json, 'limitSection') ? undefined : (json['limitSection'] === null ? null : (json['limitSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'equipmentSection': !exists(json, 'equipmentSection') ? undefined : (json['equipmentSection'] === null ? null : (json['equipmentSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'colorSection': !exists(json, 'colorSection') ? undefined : (json['colorSection'] === null ? null : (json['colorSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'locationSection': !exists(json, 'locationSection') ? undefined : (json['locationSection'] === null ? null : (json['locationSection'] as Array<any>).map(SearchPageFieldFromJSON)),
        'seatSection': !exists(json, 'seatSection') ? undefined : (json['seatSection'] === null ? null : (json['seatSection'] as Array<any>).map(SearchPageFieldFromJSON)),
    };
}

export function SearchPageFieldsToJSON(value?: SearchPageFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categorySection': value.categorySection === undefined ? undefined : (value.categorySection === null ? null : (value.categorySection as Array<any>).map(SearchPageFieldToJSON)),
        'defaultSection': value.defaultSection === undefined ? undefined : (value.defaultSection === null ? null : (value.defaultSection as Array<any>).map(SearchPageFieldToJSON)),
        'limitSection': value.limitSection === undefined ? undefined : (value.limitSection === null ? null : (value.limitSection as Array<any>).map(SearchPageFieldToJSON)),
        'equipmentSection': value.equipmentSection === undefined ? undefined : (value.equipmentSection === null ? null : (value.equipmentSection as Array<any>).map(SearchPageFieldToJSON)),
        'colorSection': value.colorSection === undefined ? undefined : (value.colorSection === null ? null : (value.colorSection as Array<any>).map(SearchPageFieldToJSON)),
        'locationSection': value.locationSection === undefined ? undefined : (value.locationSection === null ? null : (value.locationSection as Array<any>).map(SearchPageFieldToJSON)),
        'seatSection': value.seatSection === undefined ? undefined : (value.seatSection === null ? null : (value.seatSection as Array<any>).map(SearchPageFieldToJSON)),
    };
}

