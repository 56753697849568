/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WriteToDealerRequest
 */
export interface WriteToDealerRequest {
    /**
     * 
     * @type {number}
     * @memberof WriteToDealerRequest
     */
    classifiedId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WriteToDealerRequest
     */
    dealerUserId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    message?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    acceptService?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    signUpToNewsletter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    call?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    testDrive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    trade?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WriteToDealerRequest
     */
    leasingOffer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    testDriveTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    licensePlate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    mileage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WriteToDealerRequest
     */
    zipCode?: string | null;
}

export function WriteToDealerRequestFromJSON(json: any): WriteToDealerRequest {
    return WriteToDealerRequestFromJSONTyped(json, false);
}

export function WriteToDealerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WriteToDealerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classifiedId': !exists(json, 'classifiedId') ? undefined : json['classifiedId'],
        'dealerUserId': !exists(json, 'dealerUserId') ? undefined : json['dealerUserId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'acceptService': !exists(json, 'acceptService') ? undefined : json['acceptService'],
        'signUpToNewsletter': !exists(json, 'signUpToNewsletter') ? undefined : json['signUpToNewsletter'],
        'call': !exists(json, 'call') ? undefined : json['call'],
        'testDrive': !exists(json, 'testDrive') ? undefined : json['testDrive'],
        'trade': !exists(json, 'trade') ? undefined : json['trade'],
        'leasingOffer': !exists(json, 'leasingOffer') ? undefined : json['leasingOffer'],
        'testDriveTime': !exists(json, 'testDriveTime') ? undefined : json['testDriveTime'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function WriteToDealerRequestToJSON(value?: WriteToDealerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classifiedId': value.classifiedId,
        'dealerUserId': value.dealerUserId,
        'name': value.name,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'message': value.message,
        'acceptService': value.acceptService,
        'signUpToNewsletter': value.signUpToNewsletter,
        'call': value.call,
        'testDrive': value.testDrive,
        'trade': value.trade,
        'leasingOffer': value.leasingOffer,
        'testDriveTime': value.testDriveTime,
        'licensePlate': value.licensePlate,
        'mileage': value.mileage,
        'zipCode': value.zipCode,
    };
}

