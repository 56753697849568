/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MileagePriceData
 */
export interface MileagePriceData {
    /**
     * 
     * @type {number}
     * @memberof MileagePriceData
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MileagePriceData
     */
    mileage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MileagePriceData
     */
    price?: number;
}

export function MileagePriceDataFromJSON(json: any): MileagePriceData {
    return MileagePriceDataFromJSONTyped(json, false);
}

export function MileagePriceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MileagePriceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function MileagePriceDataToJSON(value?: MileagePriceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mileage': value.mileage,
        'price': value.price,
    };
}

