/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FenerumSubStatus,
    FenerumSubStatusFromJSON,
    FenerumSubStatusFromJSONTyped,
    FenerumSubStatusToJSON,
} from './FenerumSubStatus';

/**
 * 
 * @export
 * @interface FenerumSubscriptionDto
 */
export interface FenerumSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof FenerumSubscriptionDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionDto
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FenerumSubscriptionDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FenerumSubscriptionDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionDto
     */
    referencePerson?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FenerumSubscriptionDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionDto
     */
    supplementText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FenerumSubscriptionDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionDto
     */
    fenerumSubscriptionId?: string | null;
    /**
     * 
     * @type {FenerumSubStatus}
     * @memberof FenerumSubscriptionDto
     */
    status?: FenerumSubStatus;
}

export function FenerumSubscriptionDtoFromJSON(json: any): FenerumSubscriptionDto {
    return FenerumSubscriptionDtoFromJSONTyped(json, false);
}

export function FenerumSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FenerumSubscriptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'referencePerson': !exists(json, 'referencePerson') ? undefined : json['referencePerson'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'supplementText': !exists(json, 'supplementText') ? undefined : json['supplementText'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'fenerumSubscriptionId': !exists(json, 'fenerumSubscriptionId') ? undefined : json['fenerumSubscriptionId'],
        'status': !exists(json, 'status') ? undefined : FenerumSubStatusFromJSON(json['status']),
    };
}

export function FenerumSubscriptionDtoToJSON(value?: FenerumSubscriptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'name': value.name,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'referencePerson': value.referencePerson,
        'price': value.price,
        'supplementText': value.supplementText,
        'quantity': value.quantity,
        'fenerumSubscriptionId': value.fenerumSubscriptionId,
        'status': FenerumSubStatusToJSON(value.status),
    };
}

