/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetTermsFromSocialRequest
 */
export interface SetTermsFromSocialRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetTermsFromSocialRequest
     */
    termsAccepted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetTermsFromSocialRequest
     */
    newsAccepted: boolean;
}

export function SetTermsFromSocialRequestFromJSON(json: any): SetTermsFromSocialRequest {
    return SetTermsFromSocialRequestFromJSONTyped(json, false);
}

export function SetTermsFromSocialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetTermsFromSocialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'termsAccepted': json['termsAccepted'],
        'newsAccepted': json['newsAccepted'],
    };
}

export function SetTermsFromSocialRequestToJSON(value?: SetTermsFromSocialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'termsAccepted': value.termsAccepted,
        'newsAccepted': value.newsAccepted,
    };
}

