/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserItemVehicleNotificationPermissions
 */
export interface UserItemVehicleNotificationPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof UserItemVehicleNotificationPermissions
     */
    priceChanges?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemVehicleNotificationPermissions
     */
    newImageOrDescriptionChange?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemVehicleNotificationPermissions
     */
    favoriteSold?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemVehicleNotificationPermissions
     */
    similarCarsForSale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserItemVehicleNotificationPermissions
     */
    otherChanges?: boolean;
}

export function UserItemVehicleNotificationPermissionsFromJSON(json: any): UserItemVehicleNotificationPermissions {
    return UserItemVehicleNotificationPermissionsFromJSONTyped(json, false);
}

export function UserItemVehicleNotificationPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItemVehicleNotificationPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceChanges': !exists(json, 'priceChanges') ? undefined : json['priceChanges'],
        'newImageOrDescriptionChange': !exists(json, 'newImageOrDescriptionChange') ? undefined : json['newImageOrDescriptionChange'],
        'favoriteSold': !exists(json, 'favoriteSold') ? undefined : json['favoriteSold'],
        'similarCarsForSale': !exists(json, 'similarCarsForSale') ? undefined : json['similarCarsForSale'],
        'otherChanges': !exists(json, 'otherChanges') ? undefined : json['otherChanges'],
    };
}

export function UserItemVehicleNotificationPermissionsToJSON(value?: UserItemVehicleNotificationPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceChanges': value.priceChanges,
        'newImageOrDescriptionChange': value.newImageOrDescriptionChange,
        'favoriteSold': value.favoriteSold,
        'similarCarsForSale': value.similarCarsForSale,
        'otherChanges': value.otherChanges,
    };
}

