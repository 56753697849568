/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FacetType {
    None = 'None',
    Select = 'Select',
    Interval = 'Interval',
    InputNumericToFrom = 'InputNumericToFrom',
    Location = 'Location',
    MultiSelectIntersection = 'MultiSelectIntersection',
    MultiSelectUnion = 'MultiSelectUnion',
    Search = 'Search'
}

export function FacetTypeFromJSON(json: any): FacetType {
    return FacetTypeFromJSONTyped(json, false);
}

export function FacetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacetType {
    return json as FacetType;
}

export function FacetTypeToJSON(value?: FacetType | null): any {
    return value as any;
}

