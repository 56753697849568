/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FavoriteRequest,
    FavoriteRequestFromJSON,
    FavoriteRequestToJSON,
    FavoritesResponse,
    FavoritesResponseFromJSON,
    FavoritesResponseToJSON,
    InactiveFavoriteRequest,
    InactiveFavoriteRequestFromJSON,
    InactiveFavoriteRequestToJSON,
} from '../models';

export interface ApiLikeFavoritePostRequest {
    favoriteRequest?: FavoriteRequest;
}

export interface ApiLikeRemoveinactivePostRequest {
    inactiveFavoriteRequest?: InactiveFavoriteRequest;
}

/**
 * 
 */
export class LikeApi extends runtime.BaseAPI {

    /**
     */
    async apiLikeFavoritePostRaw(requestParameters: ApiLikeFavoritePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/like/favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteRequestToJSON(requestParameters.favoriteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLikeFavoritePost(requestParameters: ApiLikeFavoritePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiLikeFavoritePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLikeGetfavoritesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FavoritesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/like/getfavorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoritesResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLikeGetfavoritesGet(initOverrides?: RequestInit): Promise<FavoritesResponse> {
        const response = await this.apiLikeGetfavoritesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLikeRemoveinactivePostRaw(requestParameters: ApiLikeRemoveinactivePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/like/removeinactive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InactiveFavoriteRequestToJSON(requestParameters.inactiveFavoriteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLikeRemoveinactivePost(requestParameters: ApiLikeRemoveinactivePostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiLikeRemoveinactivePostRaw(requestParameters, initOverrides);
    }

}
