/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClassifiedItem,
    ClassifiedItemFromJSON,
    ClassifiedItemToJSON,
    CvrDealerResponse,
    CvrDealerResponseFromJSON,
    CvrDealerResponseToJSON,
    CvrDealersSearchResponse,
    CvrDealersSearchResponseFromJSON,
    CvrDealersSearchResponseToJSON,
    Dealer,
    DealerFromJSON,
    DealerToJSON,
    DealerAdditionalDataResponse,
    DealerAdditionalDataResponseFromJSON,
    DealerAdditionalDataResponseToJSON,
    ErrorMessageResponse,
    ErrorMessageResponseFromJSON,
    ErrorMessageResponseToJSON,
    PageOptions,
    PageOptionsFromJSON,
    PageOptionsToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProfileSearchResultResponse,
    ProfileSearchResultResponseFromJSON,
    ProfileSearchResultResponseToJSON,
    SearchPageAttribute,
    SearchPageAttributeFromJSON,
    SearchPageAttributeToJSON,
    SearchPageOptions,
    SearchPageOptionsFromJSON,
    SearchPageOptionsToJSON,
    TestDriveSlotsResponse,
    TestDriveSlotsResponseFromJSON,
    TestDriveSlotsResponseToJSON,
    TodaysOpeningHoursResponse,
    TodaysOpeningHoursResponseFromJSON,
    TodaysOpeningHoursResponseToJSON,
    WriteToDealerRequest,
    WriteToDealerRequestFromJSON,
    WriteToDealerRequestToJSON,
} from '../models';

export interface ApiDealerAlternativecarsGetRequest {
    id?: number;
}

export interface ApiDealerGetautotjekclassifiedsPostRequest {
    searchPageOptions?: SearchPageOptions;
}

export interface ApiDealerGetcompanyclassifiedsPostRequest {
    searchPageOptions?: SearchPageOptions;
}

export interface ApiDealerGetcompanydealerGetRequest {
    id?: number;
}

export interface ApiDealerGetcvrdealerGetRequest {
    cvr?: number;
}

export interface ApiDealerGetdbfuclassifiedsPostRequest {
    searchPageOptions?: SearchPageOptions;
}

export interface ApiDealerIdDealeradditionaldataGetRequest {
    id: number;
}

export interface ApiDealerIdOpeningHoursGetRequest {
    id: number;
}

export interface ApiDealerIdPhonenumberGetRequest {
    id: number;
}

export interface ApiDealerIdShowFinancingGetRequest {
    id: number;
}

export interface ApiDealerIdTestDriveSlotsGetRequest {
    id: number;
}

export interface ApiDealerMakesGetRequest {
    id?: number;
}

export interface ApiDealerRemoveLeadLimitUseridProfileidPostRequest {
    userid: number;
    profileid: number;
}

export interface ApiDealerSearchcvrdealersPostRequest {
    searchQuery?: string;
    page?: number;
    startsWith?: string;
}

export interface ApiDealerSearchdealersPostRequest {
    searchQuery?: string;
    area?: string;
    zip?: string;
    page?: number;
}

export interface ApiDealerUnsubscribeAnsweredCallsUuidPostRequest {
    uuid: string;
}

export interface ApiDealerUnsubscribeUnansweredCallsUuidPostRequest {
    uuid: string;
}

export interface ApiDealerWriteToDealerPostRequest {
    writeToDealerRequest?: WriteToDealerRequest;
}

/**
 * 
 */
export class DealerApi extends runtime.BaseAPI {

    /**
     */
    async apiDealerAlternativecarsGetRaw(requestParameters: ApiDealerAlternativecarsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassifiedItem>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/alternativecars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassifiedItemFromJSON));
    }

    /**
     */
    async apiDealerAlternativecarsGet(requestParameters: ApiDealerAlternativecarsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassifiedItem>> {
        const response = await this.apiDealerAlternativecarsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerGetautotjekclassifiedsPostRaw(requestParameters: ApiDealerGetautotjekclassifiedsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer/getautotjekclassifieds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPageOptionsToJSON(requestParameters.searchPageOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOptionsFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerGetautotjekclassifiedsPost(requestParameters: ApiDealerGetautotjekclassifiedsPostRequest = {}, initOverrides?: RequestInit): Promise<PageOptions> {
        const response = await this.apiDealerGetautotjekclassifiedsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerGetcompanyclassifiedsPostRaw(requestParameters: ApiDealerGetcompanyclassifiedsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer/getcompanyclassifieds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPageOptionsToJSON(requestParameters.searchPageOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOptionsFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerGetcompanyclassifiedsPost(requestParameters: ApiDealerGetcompanyclassifiedsPostRequest = {}, initOverrides?: RequestInit): Promise<PageOptions> {
        const response = await this.apiDealerGetcompanyclassifiedsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerGetcompanydealerGetRaw(requestParameters: ApiDealerGetcompanydealerGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Dealer>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/getcompanydealer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealerFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerGetcompanydealerGet(requestParameters: ApiDealerGetcompanydealerGetRequest = {}, initOverrides?: RequestInit): Promise<Dealer> {
        const response = await this.apiDealerGetcompanydealerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerGetcvrdealerGetRaw(requestParameters: ApiDealerGetcvrdealerGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CvrDealerResponse>> {
        const queryParameters: any = {};

        if (requestParameters.cvr !== undefined) {
            queryParameters['cvr'] = requestParameters.cvr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/getcvrdealer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CvrDealerResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerGetcvrdealerGet(requestParameters: ApiDealerGetcvrdealerGetRequest = {}, initOverrides?: RequestInit): Promise<CvrDealerResponse> {
        const response = await this.apiDealerGetcvrdealerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerGetdbfuclassifiedsPostRaw(requestParameters: ApiDealerGetdbfuclassifiedsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer/getdbfuclassifieds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPageOptionsToJSON(requestParameters.searchPageOptions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOptionsFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerGetdbfuclassifiedsPost(requestParameters: ApiDealerGetdbfuclassifiedsPostRequest = {}, initOverrides?: RequestInit): Promise<PageOptions> {
        const response = await this.apiDealerGetdbfuclassifiedsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerIdDealeradditionaldataGetRaw(requestParameters: ApiDealerIdDealeradditionaldataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DealerAdditionalDataResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDealerIdDealeradditionaldataGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/{id}/dealeradditionaldata`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DealerAdditionalDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerIdDealeradditionaldataGet(requestParameters: ApiDealerIdDealeradditionaldataGetRequest, initOverrides?: RequestInit): Promise<DealerAdditionalDataResponse> {
        const response = await this.apiDealerIdDealeradditionaldataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerIdOpeningHoursGetRaw(requestParameters: ApiDealerIdOpeningHoursGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TodaysOpeningHoursResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDealerIdOpeningHoursGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/{id}/opening-hours`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodaysOpeningHoursResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerIdOpeningHoursGet(requestParameters: ApiDealerIdOpeningHoursGetRequest, initOverrides?: RequestInit): Promise<TodaysOpeningHoursResponse> {
        const response = await this.apiDealerIdOpeningHoursGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerIdPhonenumberGetRaw(requestParameters: ApiDealerIdPhonenumberGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDealerIdPhonenumberGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/{id}/phonenumber`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDealerIdPhonenumberGet(requestParameters: ApiDealerIdPhonenumberGetRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiDealerIdPhonenumberGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerIdShowFinancingGetRaw(requestParameters: ApiDealerIdShowFinancingGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDealerIdShowFinancingGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/{id}/show-financing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDealerIdShowFinancingGet(requestParameters: ApiDealerIdShowFinancingGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiDealerIdShowFinancingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerIdTestDriveSlotsGetRaw(requestParameters: ApiDealerIdTestDriveSlotsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TestDriveSlotsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDealerIdTestDriveSlotsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/{id}/test-drive-slots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestDriveSlotsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerIdTestDriveSlotsGet(requestParameters: ApiDealerIdTestDriveSlotsGetRequest, initOverrides?: RequestInit): Promise<TestDriveSlotsResponse> {
        const response = await this.apiDealerIdTestDriveSlotsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerMakesGetRaw(requestParameters: ApiDealerMakesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/makes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiDealerMakesGet(requestParameters: ApiDealerMakesGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiDealerMakesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerMakesautotjekGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/makesautotjek`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiDealerMakesautotjekGet(initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiDealerMakesautotjekGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerMakesdbfuGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SearchPageAttribute>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/makesdbfu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SearchPageAttributeFromJSON));
    }

    /**
     */
    async apiDealerMakesdbfuGet(initOverrides?: RequestInit): Promise<Array<SearchPageAttribute>> {
        const response = await this.apiDealerMakesdbfuGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerRemoveLeadLimitUseridProfileidPostRaw(requestParameters: ApiDealerRemoveLeadLimitUseridProfileidPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userid === null || requestParameters.userid === undefined) {
            throw new runtime.RequiredError('userid','Required parameter requestParameters.userid was null or undefined when calling apiDealerRemoveLeadLimitUseridProfileidPost.');
        }

        if (requestParameters.profileid === null || requestParameters.profileid === undefined) {
            throw new runtime.RequiredError('profileid','Required parameter requestParameters.profileid was null or undefined when calling apiDealerRemoveLeadLimitUseridProfileidPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/remove-lead-limit/{userid}/{profileid}`.replace(`{${"userid"}}`, encodeURIComponent(String(requestParameters.userid))).replace(`{${"profileid"}}`, encodeURIComponent(String(requestParameters.profileid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDealerRemoveLeadLimitUseridProfileidPost(requestParameters: ApiDealerRemoveLeadLimitUseridProfileidPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDealerRemoveLeadLimitUseridProfileidPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDealerSearchcvrdealersPostRaw(requestParameters: ApiDealerSearchcvrdealersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CvrDealersSearchResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.startsWith !== undefined) {
            queryParameters['startsWith'] = requestParameters.startsWith;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/searchcvrdealers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CvrDealersSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerSearchcvrdealersPost(requestParameters: ApiDealerSearchcvrdealersPostRequest = {}, initOverrides?: RequestInit): Promise<CvrDealersSearchResponse> {
        const response = await this.apiDealerSearchcvrdealersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerSearchdealersPostRaw(requestParameters: ApiDealerSearchdealersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileSearchResultResponse>> {
        const queryParameters: any = {};

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.area !== undefined) {
            queryParameters['area'] = requestParameters.area;
        }

        if (requestParameters.zip !== undefined) {
            queryParameters['zip'] = requestParameters.zip;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/searchdealers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileSearchResultResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDealerSearchdealersPost(requestParameters: ApiDealerSearchdealersPostRequest = {}, initOverrides?: RequestInit): Promise<ProfileSearchResultResponse> {
        const response = await this.apiDealerSearchdealersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDealerUnsubscribeAnsweredCallsUuidPostRaw(requestParameters: ApiDealerUnsubscribeAnsweredCallsUuidPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiDealerUnsubscribeAnsweredCallsUuidPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/unsubscribe-answered-calls/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDealerUnsubscribeAnsweredCallsUuidPost(requestParameters: ApiDealerUnsubscribeAnsweredCallsUuidPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDealerUnsubscribeAnsweredCallsUuidPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDealerUnsubscribeUnansweredCallsUuidPostRaw(requestParameters: ApiDealerUnsubscribeUnansweredCallsUuidPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling apiDealerUnsubscribeUnansweredCallsUuidPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer/unsubscribe-unanswered-calls/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDealerUnsubscribeUnansweredCallsUuidPost(requestParameters: ApiDealerUnsubscribeUnansweredCallsUuidPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDealerUnsubscribeUnansweredCallsUuidPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDealerWriteToDealerPostRaw(requestParameters: ApiDealerWriteToDealerPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer/write-to-dealer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WriteToDealerRequestToJSON(requestParameters.writeToDealerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDealerWriteToDealerPost(requestParameters: ApiDealerWriteToDealerPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiDealerWriteToDealerPostRaw(requestParameters, initOverrides);
    }

}
