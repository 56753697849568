/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckEmailResponse
 */
export interface CheckEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckEmailResponse
     */
    _exists: boolean;
}

export function CheckEmailResponseFromJSON(json: any): CheckEmailResponse {
    return CheckEmailResponseFromJSONTyped(json, false);
}

export function CheckEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckEmailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': json['exists'],
    };
}

export function CheckEmailResponseToJSON(value?: CheckEmailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
    };
}

