import {
  SearchPageField,
  SearchPageMakeModelVariantOption,
  SearchPageOptions,
} from '@/shared/lib-api';

export function setHtml(value?: string | null): { __html: string } | undefined {
  return {
    __html: value ?? '',
  };
}

export const cursorPointer = { cursor: 'pointer' };

export function chunk(array, limit) {
  const numChunks = Math.ceil(array.length / limit);
  return Array.from({ length: numChunks }, (_, i) =>
    array.slice(
      (i * array.length) / numChunks,
      ((i + 1) * array.length) / numChunks,
    ),
  );
}

export function formatDate(date?: Date | null) {
  var formatter = new Intl.DateTimeFormat('da', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return date && date.toString() !== 'Invalid Date'
    ? formatter.format(date).replace(/\//g, '.')
    : '';
}

export const GetSearchPageField = (
  searchPageFields: SearchPageField[],
  selectedField:
    | keyof SearchPageOptions
    | keyof SearchPageMakeModelVariantOption,
): SearchPageField => {
  if (searchPageFields === undefined) {
    return;
  }
  let result = searchPageFields.find(p => p.formName === selectedField);
  if (result === undefined) {
    result = searchPageFields
      .find(p => p.formName === 'makeModelVariantOptions')
      ?.groupedFields.find(p => p.formName === selectedField);
  }
  return result;
};

export interface BilHandelOption {
  value: any;
  label: string;
  category?: string;
}
