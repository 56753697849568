/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchInitResponse,
    SearchInitResponseFromJSON,
    SearchInitResponseFromJSONTyped,
    SearchInitResponseToJSON,
} from './SearchInitResponse';
import {
    SeoSearchInitResponse,
    SeoSearchInitResponseFromJSON,
    SeoSearchInitResponseFromJSONTyped,
    SeoSearchInitResponseToJSON,
} from './SeoSearchInitResponse';

/**
 * 
 * @export
 * @interface InitResponse
 */
export interface InitResponse {
    /**
     * 
     * @type {SearchInitResponse}
     * @memberof InitResponse
     */
    searchInitResponse?: SearchInitResponse;
    /**
     * 
     * @type {SeoSearchInitResponse}
     * @memberof InitResponse
     */
    seoSearchInitResponse?: SeoSearchInitResponse;
}

export function InitResponseFromJSON(json: any): InitResponse {
    return InitResponseFromJSONTyped(json, false);
}

export function InitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchInitResponse': !exists(json, 'searchInitResponse') ? undefined : SearchInitResponseFromJSON(json['searchInitResponse']),
        'seoSearchInitResponse': !exists(json, 'seoSearchInitResponse') ? undefined : SeoSearchInitResponseFromJSON(json['seoSearchInitResponse']),
    };
}

export function InitResponseToJSON(value?: InitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchInitResponse': SearchInitResponseToJSON(value.searchInitResponse),
        'seoSearchInitResponse': SeoSearchInitResponseToJSON(value.seoSearchInitResponse),
    };
}

