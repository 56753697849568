/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserFromEmailRequest
 */
export interface CreateUserFromEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromEmailRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromEmailRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserFromEmailRequest
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromEmailRequest
     */
    termsAccepted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserFromEmailRequest
     */
    newsAccepted: boolean;
}

export function CreateUserFromEmailRequestFromJSON(json: any): CreateUserFromEmailRequest {
    return CreateUserFromEmailRequestFromJSONTyped(json, false);
}

export function CreateUserFromEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserFromEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'termsAccepted': json['termsAccepted'],
        'newsAccepted': json['newsAccepted'],
    };
}

export function CreateUserFromEmailRequestToJSON(value?: CreateUserFromEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'name': value.name,
        'termsAccepted': value.termsAccepted,
        'newsAccepted': value.newsAccepted,
    };
}

