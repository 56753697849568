/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesType {
    Sell = 'Sell',
    Buy = 'Buy',
    Barter = 'Barter',
    Leasing = 'Leasing',
    Engros = 'Engros',
    WithoutTax = 'WithoutTax',
    Prebooking = 'Prebooking'
}

export function SalesTypeFromJSON(json: any): SalesType {
    return SalesTypeFromJSONTyped(json, false);
}

export function SalesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesType {
    return json as SalesType;
}

export function SalesTypeToJSON(value?: SalesType | null): any {
    return value as any;
}

