//https://nextjs.org/docs/basic-features/data-fetching/overview
//Er fixet med denne: https://flaviocopes.com/nextjs-serialize-date-json/
export function RemoveUndefinedsFromObject<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

export const getMobileDetect = (
  userAgent: string,
  clientSideIsMobile: boolean,
) => {
  if (userAgent === '' || userAgent === undefined) {
    //client-side only
    return { isMobile: () => clientSideIsMobile, isAndroid: () => false, isIos: () => false };
  }
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isIos,
    isAndroid,
  };
};
