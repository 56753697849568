import BilhandelLogo from '@/shared/images/bilhandel-logo-v1.svg';
import BilekspressenLogo from '@/shared/images/bilekspressen-logo.png';
import JubiiLogo from '@/shared/images/juubi-logo.svg';

export interface SiteSettings {
  name: 'BilEkspressen' | 'Bilhandel' | 'Jubii' | 'Localhost';
  hostname: string;
  logo: any;
  gaTrack?: string;
}

export const localhostSettings: SiteSettings = {
  name: 'Localhost',
  hostname: 'http://localhost:3000',
  logo: BilhandelLogo,
};

export const bilhandelSettings: SiteSettings = {
  name: 'Bilhandel',
  hostname: 'https://bilhandel.dk',
  logo: BilhandelLogo,
};

export const bilekspressenSettings: SiteSettings = {
  name: 'BilEkspressen',
  hostname: 'https://bilekspressen.dk',
  logo: BilekspressenLogo,
};

export const jubiiSettings: SiteSettings = {
  name: 'Jubii',
  hostname: 'https://jubii.biler.dk',
  logo: JubiiLogo,
};

export const getSiteSettings = (basePath: string) => {
  switch (basePath) {
    case 'biler.jubii.dk':
    case 'pre.biler.jubii.dk':
      return jubiiSettings;
    case 'bilhandel.dk':
    case 'pre.bilhandel.dk':
      return bilhandelSettings;
    case 'bilekspressen.dk':
    case 'pre.bilekspressen.dk':
      return bilekspressenSettings;
    case 'localhost':
    case 'localhost:3000':
      return localhostSettings;
    default: {
      return bilhandelSettings;
    }
  }
};
