/* tslint:disable */
/* eslint-disable */
/**
 * BilhandelV2.dk
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FenerumSubscriptionDto,
    FenerumSubscriptionDtoFromJSON,
    FenerumSubscriptionDtoFromJSONTyped,
    FenerumSubscriptionDtoToJSON,
} from './FenerumSubscriptionDto';

/**
 * 
 * @export
 * @interface FenerumSubscriptionUserProfileDto
 */
export interface FenerumSubscriptionUserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionUserProfileDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FenerumSubscriptionUserProfileDto
     */
    fenerumSubscriptionId?: string;
    /**
     * 
     * @type {FenerumSubscriptionDto}
     * @memberof FenerumSubscriptionUserProfileDto
     */
    fenerumSubscription?: FenerumSubscriptionDto;
    /**
     * 
     * @type {Date}
     * @memberof FenerumSubscriptionUserProfileDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FenerumSubscriptionUserProfileDto
     */
    endDate?: Date | null;
}

export function FenerumSubscriptionUserProfileDtoFromJSON(json: any): FenerumSubscriptionUserProfileDto {
    return FenerumSubscriptionUserProfileDtoFromJSONTyped(json, false);
}

export function FenerumSubscriptionUserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FenerumSubscriptionUserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fenerumSubscriptionId': !exists(json, 'fenerumSubscriptionId') ? undefined : json['fenerumSubscriptionId'],
        'fenerumSubscription': !exists(json, 'fenerumSubscription') ? undefined : FenerumSubscriptionDtoFromJSON(json['fenerumSubscription']),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
    };
}

export function FenerumSubscriptionUserProfileDtoToJSON(value?: FenerumSubscriptionUserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fenerumSubscriptionId': value.fenerumSubscriptionId,
        'fenerumSubscription': FenerumSubscriptionDtoToJSON(value.fenerumSubscription),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
    };
}

